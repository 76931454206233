import styled from 'styled-components'
import { white, grayDarker, grayDark, blue } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background: ${white};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`

export const ListItem = styled.li<{
  isDisabled: boolean
}>`
  display: flex;
  flex-direction: row;
  margin: 14px 0;
  width: 100%;

  list-style: none;

  opacity: ${({ isDisabled }): number => (isDisabled ? 0.5 : 1)};
  cursor: ${({ isDisabled }): string => (isDisabled ? 'default' : 'pointer')};

  label,
  input {
    cursor: ${({ isDisabled }): string => (isDisabled ? 'default' : 'pointer')};
  }
`
export const Label = styled.label`
  display: flex;
  width: 100%;
`

export const Selection = styled.span`
  width: 100%;
`

export const TextGroup = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 100%;

  span {
    font-size: 12px;
    text-transform: capitalize;
    color: ${grayDarker};
  }
`

export const ConnectionAvailability = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  padding: 0 15px;
  width: 100%;

  font-size: 14px;
  text-align: right;
  color: ${grayDark};
`

export const TooltipWrapper = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 100%;

  span {
    font-size: 12px;
    text-transform: capitalize;
    color: ${grayDarker};
  }
`

export const CheckBox = styled.input`
  display: flex;
  align-self: center;

  accent-color: ${blue};
`
