import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { BufferTracker, Client } from '@bufferapp/buffer-tracking-browser-ts'
import {
  getCurrentPlanNameFromAccount,
  isAtPlanLimit,
} from '../../../../../shared-utils'

import ShopifyFlow from './Shopify'
import { trackConnectionAttempted } from '../../../tracking'

// @ts-expect-error TS(7031) FIXME: Binding element 'serviceName' implicitly has an 'a... Remove this comment to see the full error message
const AuthFlows = ({ serviceName, onClose, channelToReconnect }) => {
  const organizationId = useSelector(
    (state) =>
      state.account.currentOrganization && state.account.currentOrganization.id,
  )

  const account = useSelector((state) => state.account)

  useEffect(() => {
    if (!channelToReconnect) {
      trackConnectionAttempted({
        organizationId,
        serviceName,
        cta: 'channel-connect-page',
        planNameAtTimeOfAttempt: getCurrentPlanNameFromAccount(account),
        isAtPlanLimit: isAtPlanLimit(account),
      })
    } else {
      BufferTracker.channelRefreshAttempted({
        channelId: channelToReconnect.id,
        channelServiceId: channelToReconnect.serviceId,
        organizationId: organizationId || null,
        channel: channelToReconnect.service,
        channelType: channelToReconnect.type,
        product: 'account',
        clientName: Client.PublishWeb,
      })
    }
  }, [])

  switch (serviceName) {
    case 'shopify':
      return (
        <ShopifyFlow
          onClose={onClose}
          channelToReconnect={channelToReconnect}
        />
      )
    default:
      break
  }
}

export default AuthFlows
