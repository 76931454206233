import React from 'react'
import { Modal } from '@bufferapp/ui'
import { ModalTitle } from '../../../../../../../../account-components'
import { Cross } from '@bufferapp/ui/Icon'

import {
  Body,
  CloseIconWrapper,
  IllustrationCloseWrapper,
  LargeIllustrationWrapper,
  Link,
  ModalHeader,
  StyledText,
} from './styles'

export const ChannelTeaserModal = ({
  onClose,
  message,
}: {
  onClose?: () => void
  message: string
}): JSX.Element => {
  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; action: null; seconda... Remove this comment to see the full error message
    <Modal action={null} secondaryAction={null} dismissible={false}>
      <IllustrationCloseWrapper>
        <LargeIllustrationWrapper src="https://s3.amazonaws.com/static.buffer.com/images/account/threads_teaser_image.png" />
        <CloseIconWrapper>
          <Cross onClick={onClose} />
        </CloseIconWrapper>
      </IllustrationCloseWrapper>
      <ModalHeader>
        <ModalTitle text="Great, You're In!" />
      </ModalHeader>
      <Body>
        <StyledText type="p">
          {message} Feel free to request and upvote more features in our
          <Link target="_blank" href="https://suggestions.buffer.com/">
            suggestions hub
          </Link>
          .
        </StyledText>
      </Body>
    </Modal>
  )
}
