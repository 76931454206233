import React from 'react'

export default function ConnectionSVG(): JSX.Element {
  return (
    <svg
      data-testid="ConnectionSVG"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17.6622L4.33803 14.3241M1 17.6622L4.33803 21.0002M1 17.6622H19.7452C20.2975 17.6622 20.7452 17.2145 20.7452 16.6622V12.125"
        stroke="#636363"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7456 4.33783L17.4076 7.67585M20.7456 4.33783L17.4076 0.999802M20.7456 4.33783H2.00041C1.44813 4.33783 1.00041 4.78554 1.00041 5.33783V9.875"
        stroke="#636363"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
