import styled from 'styled-components'
import {
  blueDark,
  gray,
  grayDark,
  grayLight,
  grayLighter,
} from '@bufferapp/ui/style/colors'

function getPlanItemSize(isTwoColumn: boolean): string {
  return isTwoColumn ? '49%' : '32%'
}

export const PlanItem = styled.div<{
  isTwoColumn: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 ${(props) => getPlanItemSize(props.isTwoColumn)};
  padding: 1.75rem 0.8rem;
  border: 1px solid ${grayLight};
  background-color: ${grayLighter};
  border-radius: 2px;
  span {
    display: flex;
    div {
      /* Tooltip icon */
      cursor: pointer;
      margin: 1px 0 0 2px;
      svg {
        fill: ${gray};
      }
    }
  }
  h3 {
    margin-bottom: 0;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  margin-bottom: 0.5rem;
  background-color: ${blueDark};
  border-radius: 100rem;
`

export const ValueWrapper = styled.div`
  margin-top: 1rem;
`

export const Warning = styled.div`
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  font-weight: 400;
  color: ${grayDark};
`

export const ExtraInfoWrapper = styled.div<{
  hasExtraInfo: boolean
}>`
  ${(props) => {
    if (props.hasExtraInfo) {
      return `
        p {
          margin-bottom: 8px;
        }
      `
    }
  }}
`
