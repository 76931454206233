import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'

import NumberInput from '../NumberInput'
import Switch from '../Switch'

import {
  SectionWrapper,
  SectionContentWrapper,
  DescriptionWrapper,
  InputWrapper,
  InputTitle,
  InputText,
} from './style'

const TEAM_MEMBER_RADIO_OPTIONS = [
  {
    label: 'Just one',
    value: 'one',
  },
  {
    label: 'More than one',
    value: 'more',
  },
]

const INTERVAL_RADIO_OPTIONS = [
  {
    label: 'monthly',
    value: 'month',
  },
  {
    label: 'yearly',
    value: 'year',
  },
]

// TODO: This componenet still needs to:
// 1. Apply correct styling as per designs
// 2. Correct content to be added
const PlanCalculatorInputSection = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'initialChannelsInputValue' implic... Remove this comment to see the full error message
  initialChannelsInputValue,
  // @ts-expect-error TS(7031) FIXME: Binding element 'channelsInputMinimumValue' implic... Remove this comment to see the full error message
  channelsInputMinimumValue,
  // @ts-expect-error TS(7031) FIXME: Binding element 'initialTeamMembersValue' implicit... Remove this comment to see the full error message
  initialTeamMembersValue,
  // @ts-expect-error TS(7031) FIXME: Binding element 'teamMembersSelected' implicitly h... Remove this comment to see the full error message
  teamMembersSelected,
  // @ts-expect-error TS(7031) FIXME: Binding element 'intervalSelected' implicitly has ... Remove this comment to see the full error message
  intervalSelected,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handleChannelInput' implicitly ha... Remove this comment to see the full error message
  handleChannelInput,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handleTeamMemeberInput' implicitl... Remove this comment to see the full error message
  handleTeamMemeberInput,
  // @ts-expect-error TS(7031) FIXME: Binding element 'handleIntervalInput' implicitly h... Remove this comment to see the full error message
  handleIntervalInput,
}) => {
  return (
    <SectionWrapper>
      <Text type="h2">Update your plan</Text>
      <SectionContentWrapper>
        <DescriptionWrapper>
          <InputTitle>Prepare your new plan in 3 steps</InputTitle>
          <InputText>
            Pricing is based on the number of channels and team members you
            have.
          </InputText>
        </DescriptionWrapper>
        <InputWrapper isSmall>
          <InputTitle>1. How many Channels?</InputTitle>
          <NumberInput
            initialValue={initialChannelsInputValue}
            onValueChange={(value: any) => handleChannelInput(value)}
            minimumValue={channelsInputMinimumValue}
          />
          <InputText>
            You currently have <strong>{initialChannelsInputValue}</strong>{' '}
            {initialChannelsInputValue > 1 ? `channels` : `channel`}
          </InputText>
        </InputWrapper>
        <InputWrapper>
          <InputTitle>2. How many users?</InputTitle>
          <Switch
            // @ts-expect-error TS(2322) FIXME: Type '{ id: string; inputName: string; defaultSele... Remove this comment to see the full error message
            id="team-memeber-selector"
            inputName="team-memeber-selector"
            defaultSelected={teamMembersSelected}
            radioOptions={TEAM_MEMBER_RADIO_OPTIONS}
            handleOnChange={(value: any) => handleTeamMemeberInput(value)}
          />
          <InputText>
            You currently have <strong>{initialTeamMembersValue}</strong>{' '}
            {initialTeamMembersValue > 1 ? `users` : `user`}
          </InputText>
        </InputWrapper>
        <InputWrapper isLarge>
          <InputTitle>3. Billing Frequency: </InputTitle>
          <Switch
            // @ts-expect-error TS(2322) FIXME: Type '{ id: string; inputName: string; defaultSele... Remove this comment to see the full error message
            id="plan-interval-selector"
            inputName="plan-interval-selector"
            defaultSelected={intervalSelected}
            radioOptions={INTERVAL_RADIO_OPTIONS}
            handleOnChange={(value: any) => handleIntervalInput(value)}
          />
          <InputText>
            <span>Save 2 months</span> with yearly
          </InputText>
        </InputWrapper>
      </SectionContentWrapper>
    </SectionWrapper>
  )
}

PlanCalculatorInputSection.propTypes = {
  initialChannelsInputValue: PropTypes.string.isRequired,
  channelsInputMinimumValue: PropTypes.number,
  initialTeamMembersValue: PropTypes.number.isRequired,
  teamMembersSelected: PropTypes.string.isRequired,
  intervalSelected: PropTypes.string.isRequired,
  handleChannelInput: PropTypes.func.isRequired,
  handleTeamMemeberInput: PropTypes.func.isRequired,
  handleIntervalInput: PropTypes.func.isRequired,
}

PlanCalculatorInputSection.defaultProps = {}

export default PlanCalculatorInputSection
