import { connect } from 'react-redux'
import {
  actions as channelListActions,
  selectChannelListLoggedIn,
} from '../../../channel-list/reducer'
import { SelectChannel } from './SelectChannel'
import { trackConnectionAttempted } from '../../../web/src/tracking'
import { Services } from '../../../channel-list/constants'
import {
  getCurrentPlanNameFromAccount,
  isAtPlanLimit,
} from '../../../shared-utils'

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function getServiceAndPagesFromState(state) {
  let service = ''
  let pages = []

  if (state.oauthInstagram?.pages?.length) {
    service = Services.INSTAGRAM
    pages = state.oauthInstagram.pages
  }

  if (state.oauthFacebook?.pages?.length) {
    service = Services.FACEBOOK
    pages = state.oauthFacebook.pages
  }

  if (state.oauthTwitter?.pages?.length) {
    service = Services.TWITTER
    pages = state.oauthTwitter.pages
  }

  if (state.oauthLinkedin?.pages?.length) {
    service = Services.LINKEDIN
    pages = state.oauthLinkedin.pages
  }

  return {
    service,
    pages,
  }
}

// default export = container
export const SelectChannelPage = connect(
  (state) => ({
    account: state.account,
    loggedIn: selectChannelListLoggedIn(state),
    service: getServiceAndPagesFromState(state).service,
    pages: getServiceAndPagesFromState(state).pages,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'currentOrganization' implicitly has an ... Remove this comment to see the full error message
    startPageRedirect: (currentOrganization): void => {
      trackConnectionAttempted({
        organizationId: currentOrganization?.id,
        serviceName: Services.STARTPAGE,
        cta: 'onboarding',
        planNameAtTimeOfAttempt:
          getCurrentPlanNameFromAccount(currentOrganization),
        isAtPlanLimit: isAtPlanLimit(currentOrganization),
      })
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'currentOrganization' implicitly has an ... Remove this comment to see the full error message
    shopifyLogin: (currentOrganization): void => {
      trackConnectionAttempted({
        organizationId: currentOrganization?.id,
        serviceName: Services.SHOPIFY,
        cta: 'onboarding',
        planNameAtTimeOfAttempt:
          getCurrentPlanNameFromAccount(currentOrganization),
        isAtPlanLimit: isAtPlanLimit(currentOrganization),
      })
      dispatch(channelListActions.resetConnectedPagesState())
    },
  }),
)(SelectChannel)
