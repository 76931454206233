import React from 'react'
import { PageLayout } from '../../../../account-components'
import { Loader } from '@bufferapp/ui'

import * as Styles from './styles'

const LoadingPage = (): JSX.Element => (
  <PageLayout withAppShell>
    <Styles.LoaderWrapper>
      <Loader />
    </Styles.LoaderWrapper>
  </PageLayout>
)

export default LoadingPage
