import { connect } from 'react-redux'
import { actions } from '../../reducer'

import AccountSettings from './AccountSettings'

export default connect(
  (state) => ({
    email: state.account.email,
    hasVerifiedEmail: state.account.hasVerifiedEmail,
    updatesDisabled: state.settings.updatesDisabled,
    notificationOpened: state.account.notificationOpened,
    notificationMessage: state.account.notificationMessage,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'newEmail' implicitly has an 'any' type.
    updateEmail: (newEmail, currPassword) => {
      dispatch(actions.updateEmail(newEmail, currPassword))
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'newPassword' implicitly has an 'any' ty... Remove this comment to see the full error message
    updatePassword: (newPassword, currPassword) => {
      dispatch(actions.updatePassword(newPassword, currPassword))
    },
    clearNotification: () => {
      dispatch(actions.clearNotification())
    },
  }),
)(AccountSettings)
