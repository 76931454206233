import styled from 'styled-components'
import { grayLighter } from '../../styles'

export const AppStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${grayLighter};
  display: flex;
  flex-direction: row;
`

export const AppWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
`
