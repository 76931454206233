import { useMutation } from '@apollo/client'
import { REMOVE_SUBSCRIPTION_SCHEDULE } from '../../../../apollo/mutations/billing'

type UseRemoveSubscriptionScheduleReturn = {
  onRemoveSubscriptionSchedule: () => void
  loading: boolean
}

const useRemoveSubscriptionSchedule = ({
  organizationId,
  setRemoveScheduleError,
}: {
  organizationId: string
  setRemoveScheduleError: (error: string) => void
}): UseRemoveSubscriptionScheduleReturn => {
  const errorMsg =
    'An error occurred while removing your scheduled update. Please try again or contact us for help.'

  const [removeSubscriptionSchedule, { loading }] = useMutation(
    REMOVE_SUBSCRIPTION_SCHEDULE,
    {
      onCompleted: (data) => {
        if (data?.removeSubscriptionSchedule?.success) {
          window.location.reload()
        }
        if (data?.removeSubscriptionSchedule?.message) {
          setRemoveScheduleError(errorMsg)
        }
      },
      onError: () => {
        setRemoveScheduleError(errorMsg)
      },
    },
  )

  const onRemoveSubscriptionSchedule = (): void => {
    removeSubscriptionSchedule({ variables: { organizationId } })
  }

  return { onRemoveSubscriptionSchedule, loading }
}

export default useRemoveSubscriptionSchedule
