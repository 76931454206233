import styled from 'styled-components'
import ButtonLink from '../ButtonLink'

export const BreadcrumbStyled = styled(ButtonLink)`
  margin: 8px 0px;
`

export const BreadcrumbLabel = styled.span`
  margin-left: 8px;
`
