import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { PageLayout } from '../../../../account-components'
import { Loader } from '@bufferapp/ui'

import { useAccount } from '../../context/Account'
import useGetPortalSession from '../Billing/components/BillingInformation/hooks/useGetPortalSession'

import { ContentWrapper } from './styles'

// @ts-expect-error TS(TS7031) FIXME: Binding element 'refetch' implicitly has an 'any' type.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BillingStripePortal = ({ refetch }): JSX.Element => {
  const account = useAccount()
  const { openBillingPortal } = useGetPortalSession()

  useEffect((): void => {
    const organizationId = account?.currentOrganization?.id

    if (organizationId) {
      openBillingPortal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return (
    <PageLayout
      withAppShell
      withOrgSwitcher
      withSidebar
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; withAppShell: true; wit... Remove this comment to see the full error message
      refetch={refetch}
    >
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    </PageLayout>
  )
}

BillingStripePortal.propTypes = {
  refetch: PropTypes.func,
}

export default BillingStripePortal
