import { useEffect, useState } from 'react'

import {
  PLANS_PRICING_INITIAL_VALUE,
  calculatePricing,
  fincPlanPricingFromList,
} from '../utils/pricingCalculations'

function getCalculatedPricingForPlan(
  // @ts-expect-error TS(7006) FIXME: Parameter 'planName' implicitly has an 'any' type.
  planName,
  // @ts-expect-error TS(7006) FIXME: Parameter 'intervalSelected' implicitly has an 'an... Remove this comment to see the full error message
  intervalSelected,
  // @ts-expect-error TS(7006) FIXME: Parameter 'listOfCalculatedPricing' implicitly has... Remove this comment to see the full error message
  listOfCalculatedPricing,
) {
  const planPricingInfo = fincPlanPricingFromList(
    listOfCalculatedPricing,
    planName,
  )
  return intervalSelected === 'month'
    ? planPricingInfo.baseMonthlyPrice
    : planPricingInfo.baseYearlyPrice
}

export function usePlanPriceCalculator(
  // @ts-expect-error TS(7006) FIXME: Parameter 'channelInputValue' implicitly has an 'a... Remove this comment to see the full error message
  channelInputValue,
) {
  const [listOfCalculatedPricing, setListOfCalculatedPricing] = useState(
    PLANS_PRICING_INITIAL_VALUE,
  )

  useEffect(() => {
    setListOfCalculatedPricing(calculatePricing(channelInputValue))
  }, [channelInputValue])

  return {
    listOfCalculatedPricing,
    getCalculatedPricingForPlan,
  }
}
