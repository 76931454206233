import React from 'react'

export const UpgradeIllustrationYoutube = () => (
  <svg
    width="344"
    height="490"
    viewBox="0 0 344 490"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31 0H336C340.418 0 344 3.58172 344 8V482C344 486.418 340.418 490 336 490H31V0Z"
      fill="#EBD5FF"
    />
    <g clipPath="url(#clip0_1879_195842)">
      <path
        d="M162.922 311.828H390.039C392.768 311.828 394.981 314.04 394.981 316.769V463.887C394.981 466.616 392.768 468.828 390.039 468.828H162.922C160.193 468.828 157.981 466.616 157.981 463.887V316.769C157.981 314.04 160.193 311.828 162.922 311.828Z"
        fill="white"
      />
      <path
        d="M333.393 359.828H346.569C350.662 359.828 353.981 363.147 353.981 367.24V380.416C353.981 384.51 350.662 387.828 346.569 387.828H333.393C329.299 387.828 325.981 384.51 325.981 380.416V367.24C325.981 363.147 329.299 359.828 333.393 359.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M302.393 359.828H315.569C319.662 359.828 322.981 363.147 322.981 367.24V380.416C322.981 384.51 319.662 387.828 315.569 387.828H302.393C298.299 387.828 294.981 384.51 294.981 380.416V367.24C294.981 363.147 298.299 359.828 302.393 359.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M271.393 359.828H284.569C288.662 359.828 291.981 363.147 291.981 367.24V380.416C291.981 384.51 288.662 387.828 284.569 387.828H271.393C267.299 387.828 263.981 384.51 263.981 380.416V367.24C263.981 363.147 267.299 359.828 271.393 359.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M240.393 359.828H253.569C257.662 359.828 260.981 363.147 260.981 367.24V380.416C260.981 384.51 257.662 387.828 253.569 387.828H240.393C236.299 387.828 232.981 384.51 232.981 380.416V367.24C232.981 363.147 236.299 359.828 240.393 359.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M209.393 359.828H222.569C226.662 359.828 229.981 363.147 229.981 367.24V380.416C229.981 384.51 226.662 387.828 222.569 387.828H209.393C205.299 387.828 201.981 384.51 201.981 380.416V367.24C201.981 363.147 205.299 359.828 209.393 359.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M178.393 359.828H191.569C195.662 359.828 198.981 363.147 198.981 367.24V380.416C198.981 384.51 195.662 387.828 191.569 387.828H178.393C174.299 387.828 170.981 384.51 170.981 380.416V367.24C170.981 363.147 174.299 359.828 178.393 359.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M333.393 325.828H346.569C350.662 325.828 353.981 329.147 353.981 333.24V345.416C353.981 349.51 350.662 352.828 346.569 352.828H333.393C329.299 352.828 325.981 349.51 325.981 345.416V333.24C325.981 329.147 329.299 325.828 333.393 325.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M302.393 325.828H315.569C319.662 325.828 322.981 329.147 322.981 333.24V345.416C322.981 349.51 319.662 352.828 315.569 352.828H302.393C298.299 352.828 294.981 349.51 294.981 345.416V333.24C294.981 329.147 298.299 325.828 302.393 325.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M271.393 325.828H284.569C288.662 325.828 291.981 329.147 291.981 333.24V345.416C291.981 349.51 288.662 352.828 284.569 352.828H271.393C267.299 352.828 263.981 349.51 263.981 345.416V333.24C263.981 329.147 267.299 325.828 271.393 325.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M240.393 325.828H253.569C257.662 325.828 260.981 329.147 260.981 333.24V345.416C260.981 349.51 257.662 352.828 253.569 352.828H240.393C236.299 352.828 232.981 349.51 232.981 345.416V333.24C232.981 329.147 236.299 325.828 240.393 325.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M209.393 325.828H222.569C226.662 325.828 229.981 329.147 229.981 333.24V345.416C229.981 349.51 226.662 352.828 222.569 352.828H209.393C205.299 352.828 201.981 349.51 201.981 345.416V333.24C201.981 329.147 205.299 325.828 209.393 325.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M178.393 325.828H191.569C195.662 325.828 198.981 329.147 198.981 333.24V345.416C198.981 349.51 195.662 352.828 191.569 352.828H178.393C174.299 352.828 170.981 349.51 170.981 345.416V333.24C170.981 329.147 174.299 325.828 178.393 325.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M333.393 393.828H346.569C350.662 393.828 353.981 397.147 353.981 401.24V414.416C353.981 418.51 350.662 421.828 346.569 421.828H333.393C329.299 421.828 325.981 418.51 325.981 414.416V401.24C325.981 397.147 329.299 393.828 333.393 393.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M333.393 428H346.569C350.662 428 353.981 431.318 353.981 435.412V448.588C353.981 452.682 350.662 456 346.569 456H333.393C329.299 456 325.981 452.682 325.981 448.588V435.412C325.981 431.318 329.299 428 333.393 428Z"
        fill="#9C2BFF"
      />
      <path
        d="M302.393 393.828H315.569C319.662 393.828 322.981 397.147 322.981 401.24V414.416C322.981 418.51 319.662 421.828 315.569 421.828H302.393C298.299 421.828 294.981 418.51 294.981 414.416V401.24C294.981 397.147 298.299 393.828 302.393 393.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M302.393 428H315.569C319.662 428 322.981 431.318 322.981 435.412V448.588C322.981 452.682 319.662 456 315.569 456H302.393C298.299 456 294.981 452.682 294.981 448.588V435.412C294.981 431.318 298.299 428 302.393 428Z"
        fill="#9C2BFF"
      />
      <path
        d="M271.393 393.828H284.569C288.662 393.828 291.981 397.147 291.981 401.24V414.416C291.981 418.51 288.662 421.828 284.569 421.828H271.393C267.299 421.828 263.981 418.51 263.981 414.416V401.24C263.981 397.147 267.299 393.828 271.393 393.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M271.393 427.828H284.569C288.662 427.828 291.981 431.147 291.981 435.24V448.416C291.981 452.51 288.662 455.828 284.569 455.828H271.393C267.299 455.828 263.981 452.51 263.981 448.416V435.24C263.981 431.147 267.299 427.828 271.393 427.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M240.393 393.828H253.569C257.662 393.828 260.981 397.147 260.981 401.24V414.416C260.981 418.51 257.662 421.828 253.569 421.828H240.393C236.299 421.828 232.981 418.51 232.981 414.416V401.24C232.981 397.147 236.299 393.828 240.393 393.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M240.393 427.828H253.569C257.662 427.828 260.981 431.147 260.981 435.24V448.416C260.981 452.51 257.662 455.828 253.569 455.828H240.393C236.299 455.828 232.981 452.51 232.981 448.416V435.24C232.981 431.147 236.299 427.828 240.393 427.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M209.393 393.828H222.569C226.662 393.828 229.981 397.147 229.981 401.24V414.416C229.981 418.51 226.662 421.828 222.569 421.828H209.393C205.299 421.828 201.981 418.51 201.981 414.416V401.24C201.981 397.147 205.299 393.828 209.393 393.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M209.393 427.828H222.569C226.662 427.828 229.981 431.147 229.981 435.24V448.416C229.981 452.51 226.662 455.828 222.569 455.828H209.393C205.299 455.828 201.981 452.51 201.981 448.416V435.24C201.981 431.147 205.299 427.828 209.393 427.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M178.393 393.828H191.569C195.662 393.828 198.981 397.147 198.981 401.24V414.416C198.981 418.51 195.662 421.828 191.569 421.828H178.393C174.299 421.828 170.981 418.51 170.981 414.416V401.24C170.981 397.147 174.299 393.828 178.393 393.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M178.393 427.828H191.569C195.662 427.828 198.981 431.147 198.981 435.24V448.416C198.981 452.51 195.662 455.828 191.569 455.828H178.393C174.299 455.828 170.981 452.51 170.981 448.416V435.24C170.981 431.147 174.299 427.828 178.393 427.828Z"
        fill="#EBD5FF"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.383 170.255C103.246 170.503 102.934 170.605 102.677 170.483C102.402 170.354 102.283 170.027 102.411 169.752C102.457 169.648 102.549 169.527 102.668 169.414C102.741 169.348 102.851 169.261 102.943 169.191C103.026 168.986 103.227 168.84 103.456 168.84C103.759 168.84 103.887 168.987 103.951 169.074C104.071 169.227 104.126 169.424 104.016 169.665C103.979 169.735 103.878 169.865 103.713 169.99C103.621 170.063 103.42 170.23 103.383 170.255Z"
      fill="#FFC6AB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.622 167.602C102.448 167.735 102.191 167.75 101.999 167.62C101.669 167.407 101.641 167.166 101.641 167.066C101.641 166.841 101.733 166.591 102.091 166.495C102.164 166.474 102.301 166.461 102.466 166.473C102.64 166.486 102.879 166.529 102.943 166.529C103.246 166.529 103.493 166.776 103.493 167.079C103.493 167.384 103.246 167.629 102.943 167.629C102.906 167.629 102.76 167.617 102.622 167.602Z"
      fill="#FFC6AB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M252.504 251.774C252.504 251.774 266.869 303.748 237.203 312.099C224.645 315.635 192.666 319.104 175.074 312.099C157.304 305.02 125.058 263.053 121.965 262.038C105.526 256.644 121.11 284.315 115.072 306.793C106.44 338.937 85.2798 350.367 72.9448 360.828L52.9813 348.019L107.615 233.157C128.196 208.152 164.13 239.016 195.261 260.191L200.934 252.121L252.504 251.774Z"
      fill="#3D3D3D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M306.459 311.897H344C344 311.897 309.486 287.38 308.236 286.054C307.843 285.638 302.752 287.682 297.831 289.796C297.81 289.79 297.788 289.786 297.767 289.784L283 295.076C284.942 300.666 286.668 305.553 288.523 311.897C288.523 311.897 291.716 319.924 296.354 330.968C299.805 339.185 308.073 350.326 307.893 327.241C307.85 321.663 307.294 316.498 306.459 311.897Z"
      fill="#B76E4C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M316.26 285.578L329.05 294.44L281.383 303.947L279.75 297.206L316.26 285.578Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.059 172.271L140.534 162.426C140.534 162.426 137.585 137.153 119.563 124.21C101.54 111.267 120.681 137.902 119.563 139.916C118.445 141.929 93.6145 106.67 88.6265 111.843C83.6495 117.016 108.776 145.253 108.776 145.253C108.776 145.253 82.915 122.136 79.8017 129.997C78.7712 132.602 93.8776 144.941 99.0848 151.773C100.959 154.231 78.9686 136.116 79.1111 136.543C85.0528 153.791 94.1407 161.616 95.522 163.476C103.941 174.874 109.96 182.071 109.96 182.071L155.059 172.271Z"
      fill="#B76E4C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.111 271.766C267.352 269.409 207.163 309.224 207.485 314.619C207.743 319.145 138.767 293.076 139.221 298.117C141.536 324.064 142.227 380.24 146.578 405.868C146.578 405.868 130.436 417.247 128.881 412.064C122.734 391.561 107.028 345.204 99.1796 322.03C96.307 313.566 94.4838 308.193 94.5511 308.296C79.0454 269.016 103.511 254.581 120.506 260.438C161.906 274.706 199.111 271.766 199.111 271.766Z"
      fill="#3D3D3D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.75 358.435L118.569 384.811L132.71 421.842L152.257 405.565L145.75 358.435Z"
      fill="white"
    />
    <path
      d="M145.902 358.652L116.753 385.645"
      stroke="#9C2BFF"
      strokeWidth="2.05928"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.4716 365.342L48.9523 351.961"
      stroke="#9C2BFF"
      strokeWidth="2.05928"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.181 376.489L159.485 446.471C159.485 446.471 146.68 454 136.296 430.938C133.174 423.988 128.371 411.503 128.929 410.994C148.163 393.42 142.532 376.907 142.532 376.907L160.181 376.489Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.7637 386.55L15.1511 413.421C15.1511 413.421 3.19406 404.605 20.3129 385.991C25.4753 380.387 35.0655 371.063 35.7526 371.376C59.4724 382.154 72.4392 370.485 72.4392 370.485L79.7637 386.55Z"
      fill="black"
    />
    <path
      d="M122.145 260.9C122.145 260.9 90.9168 260.638 89.6224 281.665C88.4113 301.266 100.027 322.693 104.774 340.758"
      stroke="white"
      strokeWidth="1.02757"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.981 411.877C124.981 411.877 84.8993 400.498 89.3222 415.891C93.757 431.286 124.981 411.877 124.981 411.877Z"
      stroke="black"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.4197 368.604C33.4197 368.604 28.3215 326.907 15.5145 337.225C2.71108 347.556 33.4197 368.604 33.4197 368.604Z"
      stroke="black"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.415 368.646C37.415 368.646 -0.464431 350.492 1.33457 366.84C3.1453 383.191 37.415 368.646 37.415 368.646Z"
      stroke="black"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M163.981 306.828C163.981 306.828 131.472 277.495 114.981 248.828"
      stroke="white"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.4813 384.828C34.5483 384.828 32.9813 383.261 32.9813 381.328C32.9813 379.395 34.5483 377.828 36.4813 377.828C38.4143 377.828 39.9813 379.395 39.9813 381.328C39.9813 383.261 38.4143 384.828 36.4813 384.828Z"
      fill="white"
      stroke="white"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M140.481 416.828C138.548 416.828 136.981 415.261 136.981 413.328C136.981 411.395 138.548 409.828 140.481 409.828C142.414 409.828 143.981 411.395 143.981 413.328C143.981 415.261 142.414 416.828 140.481 416.828Z"
      fill="white"
      stroke="white"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M204.981 264.828L190.981 256.828"
      stroke="white"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M240.76 188.581L239.79 183.458L226.094 186.578C215.391 191.371 207.077 197.074 191.32 199.942C182.933 201.04 161.528 185.758 151.589 162.828L87.9813 170.32C107.071 196.623 152.923 227.43 172.655 228.945C188.169 230.135 201.138 219.224 217.816 202.973L200.665 251.786L252.638 251.366L247.147 222.311L276.697 301.828C276.697 301.828 317.2 286.247 318.981 285.69C318.983 285.681 256.553 211.507 240.844 188.496C240.808 188.448 240.784 188.481 240.76 188.581Z"
      fill="#9C2BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M225.589 150.986C223.802 152.39 226.738 160.758 218.105 166.306C206.671 169.227 203.159 177.32 202.981 181.909C202.981 181.909 224.711 183.599 243.618 184.998C257.572 186.03 269.978 186.904 270.981 186.823C268.713 173.568 259.212 174.952 259.202 163.299C259.192 149.936 253.276 140.851 241.287 139.886C230.146 138.989 228.369 148.798 225.589 150.986Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M243.981 162.828L240.728 188.569C240.728 188.569 231.846 194.636 225.981 186.885L228.63 167.203L243.981 162.828Z"
      fill="#B76E4C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M242.841 164.213L226.577 169.065C226.577 169.065 220.924 171.186 227.086 174.103C230.883 175.899 240.626 172.305 242.841 164.213Z"
      fill="black"
    />
    <path
      d="M232.981 170.828C226.354 170.828 220.981 165.008 220.981 157.828C220.981 150.648 226.354 144.828 232.981 144.828C239.609 144.828 244.981 150.648 244.981 157.828C244.981 165.008 239.609 170.828 232.981 170.828Z"
      fill="#B76E4C"
    />
    <path
      d="M237.981 163.828C237.981 163.828 235.547 168.552 230.981 166.144"
      stroke="black"
      strokeWidth="1.02944"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M244.481 160.828C242.548 160.828 240.981 159.037 240.981 156.828C240.981 154.619 242.548 152.828 244.481 152.828C246.414 152.828 247.981 154.619 247.981 156.828C247.981 159.037 246.414 160.828 244.481 160.828Z"
      fill="#B76E4C"
    />
    <path
      d="M225.981 158.828L218.981 160.899L225.981 162.828"
      stroke="black"
      strokeWidth="1.02944"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M231.981 158.828C231.429 158.828 230.981 158.38 230.981 157.828C230.981 157.276 231.429 156.828 231.981 156.828C232.534 156.828 232.981 157.276 232.981 157.828C232.981 158.38 232.534 158.828 231.981 158.828Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M242.441 157.828L238.575 157.22L238.792 151.129L234.698 156.496L220.981 153.989C220.981 153.989 223.597 139.418 238.047 139.837C250.441 140.198 242.441 157.828 242.441 157.828Z"
      fill="black"
    />
    <path
      d="M250.981 137.828C247.115 137.828 243.981 134.694 243.981 130.828C243.981 126.962 247.115 123.828 250.981 123.828C254.847 123.828 257.981 126.962 257.981 130.828C257.981 134.694 254.847 137.828 250.981 137.828Z"
      fill="black"
    />
    <path
      d="M273.977 186.828C273.977 186.828 274.244 184.413 270.55 177.898C268.508 174.301 261.679 171.489 261.436 166.312C260.682 150.184 258.847 149.17 257.231 147.021C251.288 139.067 237.981 139.849 237.981 139.849"
      stroke="black"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="83.3597" cy="100.359" r="58.3589" fill="#FF0000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.957 88.5611C111.957 88.5611 111.386 84.477 109.636 82.6779C107.418 80.3178 104.93 80.3066 103.789 80.1683C95.6231 79.5684 83.3729 79.5684 83.3729 79.5684H83.347C83.347 79.5684 71.0975 79.5684 62.9305 80.1683C61.7899 80.3066 59.3032 80.3178 57.0836 82.6779C55.3335 84.477 54.7641 88.5611 54.7641 88.5611C54.7641 88.5611 54.1805 93.3571 54.1805 98.1531V102.65C54.1805 107.445 54.7641 112.241 54.7641 112.241C54.7641 112.241 55.3335 116.326 57.0836 118.125C59.3032 120.485 62.2191 120.41 63.518 120.657C68.1873 121.112 83.36 121.253 83.36 121.253C83.36 121.253 95.6231 121.234 103.789 120.635C104.93 120.497 107.418 120.485 109.636 118.125C111.386 116.326 111.957 112.241 111.957 112.241C111.957 112.241 112.539 107.445 112.539 102.65V98.1531C112.539 93.3571 111.957 88.5611 111.957 88.5611ZM77.3354 108.098L77.3328 91.4461L93.1014 99.8007L77.3354 108.098Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.538 115.974C139.19 109.647 134.882 105.208 131.591 112.925C125.128 128.131 128.972 167.487 102.764 162.196C96.0352 160.837 130.501 165.328 130.501 165.328L151.501 162.828L140.538 115.974Z"
      fill="#B76E4C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.796 111.307C135.796 111.307 132.932 110.934 131.964 115.962C131.482 118.464 132.785 118.856 132.785 118.856C133.484 119.347 136.621 119.585 138.839 119.699"
      fill="#B76E4C"
    />
    <path
      d="M135.796 111.307C135.796 111.307 132.932 110.934 131.964 115.962C131.482 118.464 132.785 118.856 132.785 118.856C133.484 119.347 136.621 119.585 138.839 119.699"
      stroke="black"
      strokeWidth="1.022"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M131.561 135.828C131.561 135.828 121.496 141.808 120.002 150.983"
      stroke="black"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_1879_195842">
        <rect
          width="186"
          height="157"
          fill="white"
          transform="translate(158 312)"
        />
      </clipPath>
    </defs>
  </svg>
)
