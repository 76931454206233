import styled from 'styled-components'
import { blue, grayDark } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 720px;
`

export const CopyBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 0 48px 48px;
  max-width: 376px;

  p {
    color: ${grayDark};
  }
`

export const CopyGroup = styled.div`
  margin-bottom: 16px;
`

export const List = styled.ul`
  margin-bottom: 32px;
`

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    margin-right: 6px;
    color: ${blue};
  }
`
