import gql from 'graphql-tag'

export const TRACK_ONBOARDING_SURVEY_VIEWED = gql`
  mutation trackOnboardingSurveyViewed($input: OnboardingSurveyActionInput!) {
    trackOnboardingSurveyViewed(input: $input) {
      ... on TrackActionOnboardingSurveySuccess {
        surveyId
        actionsTaken {
          action
          date
        }
      }
      ... on InvalidInputError {
        message
      }
      ... on UnexpectedError {
        message
      }
      ... on UnauthorizedError {
        message
      }
    }
  }
`

export const TRACK_ONBOARDING_SURVEY_COMPLETED = gql`
  mutation trackOnboardingSurveyCompleted(
    $input: OnboardingSurveyCompletedInput!
  ) {
    trackOnboardingSurveyCompleted(input: $input) {
      ... on TrackActionOnboardingSurveySuccess {
        surveyId
        actionsTaken {
          action
          date
        }
      }
      ... on InvalidInputError {
        message
      }
      ... on UnexpectedError {
        message
      }
      ... on UnauthorizedError {
        message
      }
    }
  }
`

export const TRACK_ONBOARDING_SURVEY_SKIPPED = gql`
  mutation trackOnboardingSurveySkipped($input: OnboardingSurveyActionInput!) {
    trackOnboardingSurveySkipped(input: $input) {
      ... on TrackActionOnboardingSurveySuccess {
        surveyId
        actionsTaken {
          action
          date
        }
      }
      ... on InvalidInputError {
        message
      }
      ... on UnexpectedError {
        message
      }
      ... on UnauthorizedError {
        message
      }
    }
  }
`
