import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import Button from '@bufferapp/ui/Button'
import { white } from '../../styles'

const BREAKPOINTS = {
  mobileMax: 550,
  laptopMin: 1250,
}

const QUERIES = {
  mobile: `(max-width: ${BREAKPOINTS.mobileMax}px)`,
  laptopAndUp: `(max-width: ${BREAKPOINTS.laptopMin}px)`,
}

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
`

export const LeftSide = styled.div`
  flex: 2;
  background-color: ${white};
  padding: 100px 80px;

  @media ${QUERIES.mobile} {
    padding: 40px 24px;
  }
`

export const SurveyWrapper = styled.div`
  max-width: 566px; /* this is set to this value to keep Other answer in the last line in the 3 questions */
`

export const Heading = styled(Text)`
  margin-bottom: 28px;

  @media ${QUERIES.mobile} {
    font-size: 24px;
    line-height: 34px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
`

export const DoneButton = styled(Button)`
  width: 150px;
  button {
    justify-content: center;
    width: 100%;
  }
`
export const SkipButton = styled(Button)`
  border: none; /* not needed but it is used to remove ts errors in Button being used without styles */
`
export const SurveySidebar = styled.div<{ bgImage: string }>`
  flex: 1;
  background-image: url(${(props): string => props.bgImage});
  background-size: cover;

  @media ${QUERIES.laptopAndUp} {
    display: none;
  }
`
