import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import {
  TextWrapper,
  SwitchWrapper,
  LoaderWrapper,
  BetaCommunityWrapper,
  BetaCommunityTextWrapper,
  ActionButtonWrapper,
  BetaFeature,
  BetaFeaturesWrapper,
  NoBetaFeaturesText,
  FooterTextWrapper,
  BetaInfoWrapper,
  BetaFeatureTextWrapper,
  BetaFeatureButtonWrapper,
  CardFooter,
  ErrorWrapper,
  LegacyNoticeWrapper,
} from './style'
import {
  BlockWrapper,
  Card,
  CardBody,
  PageLayout,
  TitleWrapper,
} from '../../../../account-components'
import { Loader, Text, Switch, Button, Notice } from '@bufferapp/ui'
import useBetaFeatures from './hooks/useBetaFeatures'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'
import DiscordIcon from '@bufferapp/ui/Icon/Icons/Discord'
import ListIcon from '@bufferapp/ui/Icon/Icons/List'
import { useBetaEnrollment } from './hooks/useBetaEnrollment'
import { useBetaDisenrollment } from './hooks/useBetaDisenrollment'
import {
  BufferTracker,
  Client,
  Product,
} from '@bufferapp/buffer-tracking-browser-ts'
import { getProductUrl } from '../../../../shared-utils'
import { useAccount } from '../../context/Account'
import { HELLONEXT_LOGIN_SERVICE_URL } from '../../../../shared-utils/constants'

dayjs.extend(advancedFormat)

// @ts-expect-error TS(7031) FIXME: Binding element 'refetch' implicitly has an 'any' ... Remove this comment to see the full error message
const BetaFeaturesPage = ({ refetch }) => {
  const account = useSelector((state) => state.account)
  const helpcenterLink =
    'https://support.buffer.com/article/551-how-to-test-buffers-beta-features?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more'

  const { currentOrganization, hasVerifiedEmail } = account
  const canEdit = currentOrganization?.canEdit

  // Get common tracking props from account context
  const accountContext = useAccount()
  const commonTrackingProperties =
    accountContext?.currentOrganization?.commonTrackingProperties

  // Handle legacy users
  const isOneBuffer = currentOrganization?.isOneBufferOrganization
  const showLegacyNotice = !isOneBuffer
  const migrationURL = getProductUrl('account')
    ? `${getProductUrl('account')}/new-plans/?cta=beta-migration-cta`
    : 'https://account.buffer.com/new-plans/?cta=beta-migration-cta'

  // Beta program enabled state
  const [isBetaProgramEnabled, setIsBetaProgramEnabled] = useState(
    currentOrganization?.isBetaEnabled,
  )

  const [isOrganizationLoaded, setIsOrganizationLoaded] = useState(
    !!currentOrganization?.id,
  )

  useEffect(() => {
    setIsBetaProgramEnabled(currentOrganization?.isBetaEnabled)
    setIsOrganizationLoaded(!!currentOrganization?.id)
  }, [currentOrganization])

  // Get beta features query
  const { data, loading, error } = useBetaFeatures()
  const betaFeatures = data?.betaFeatures || []

  // Error states
  const [errorText, setErrorText] = useState(null)

  // Enroll/disenroll mutations
  const [isLoading, setIsLoading] = useState(false)
  const { addOrganizationToBetaProgram } = useBetaEnrollment({
    organizationId: currentOrganization.id,
    isBetaProgramEnabled,
    setIsBetaProgramEnabled,
    setIsLoading,
    setErrorText,
    refetch,
  })
  const { removeOrganizationFromBetaProgram } = useBetaDisenrollment({
    organizationId: currentOrganization.id,
    isBetaProgramEnabled,
    setIsBetaProgramEnabled,
    setIsLoading,
    setErrorText,
    refetch,
  })

  const enrollOrDisenrollBetaProgram = () => {
    if (isBetaProgramEnabled === true) {
      removeOrganizationFromBetaProgram()
    }
    if (isBetaProgramEnabled === false) {
      addOrganizationToBetaProgram()
    }
  }

  // Tracking and link to Discord invite for Beta program users
  const handleJoinCommunity = () => {
    BufferTracker.joinCommunityButtonClicked({
      clientName: Client.Core,
      product: Product.Account,
      organizationId: currentOrganization.id,
    })
    window.open('https://discord.gg/zhymxvCeUq', '_blank')
  }

  useEffect(() => {
    if (currentOrganization.id && commonTrackingProperties) {
      BufferTracker.pageViewed({
        organizationId: currentOrganization.id,
        product: Product.Account,
        title: 'Beta Features',
        url: window.location.href,
        ...commonTrackingProperties,
      })
    }
  }, [currentOrganization.id, commonTrackingProperties])

  const renderBody = () => {
    return (
      <React.Fragment>
        <TitleWrapper>
          <Text type="h1">Beta Features</Text>
        </TitleWrapper>
        {!canEdit && (
          <BlockWrapper>
            {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
            <Notice type="warning" data-testid="beta-non-admin-banner">
              <Text type="p">
                Contact an Admin in your organization to enroll in the Beta
                program
              </Text>
            </Notice>
          </BlockWrapper>
        )}
        {showLegacyNotice && (
          <BlockWrapper>
            <LegacyNoticeWrapper>
              {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
              <Notice type="warning" data-testid="beta-legacy-banner">
                <Text type="p">
                  Beta features are only available on the latest version of
                  Buffer.{` `}
                  <a
                    data-testid="account-beta-notice-updatenow"
                    href={migrationURL}
                    className="legacy-upgrade-link"
                  >
                    Update now
                  </a>{' '}
                  to try them out!
                </Text>
              </Notice>
            </LegacyNoticeWrapper>
          </BlockWrapper>
        )}
        <BlockWrapper>
          <Card title="Try new Buffer features early">
            <React.Fragment>
              <CardBody>
                <TextWrapper>
                  <Text type="p">Enable Beta features:</Text>
                </TextWrapper>
                <SwitchWrapper>
                  <LoaderWrapper>
                    {(isLoading || !isOrganizationLoaded) && <Loader />}
                  </LoaderWrapper>
                  <Switch
                    label={isBetaProgramEnabled ? 'On' : 'Off'}
                    id="beta-features-switch"
                    disabled={
                      !canEdit ||
                      !isOneBuffer ||
                      isLoading ||
                      !isOrganizationLoaded
                    }
                    handleSwitch={() => {
                      setIsLoading(true)
                      setErrorText(null)
                      enrollOrDisenrollBetaProgram()
                    }}
                    isOn={isBetaProgramEnabled}
                  />
                </SwitchWrapper>
                <br />
                <br />
                <TextWrapper>
                  <Text type="p">
                    What&apos;s the catch? Share feedback with us below to help
                    improve Buffer!
                  </Text>
                </TextWrapper>
                {errorText && (
                  <ErrorWrapper>
                    <Text type="help" hasError>
                      {errorText}
                    </Text>
                  </ErrorWrapper>
                )}
              </CardBody>
              <CardFooter>
                <FooterTextWrapper>
                  <Text type="p">
                    Beta features are experimental and currently only available
                    in a web browser. Enabling them may lead to a less stable
                    Buffer experience. For more info, visit our{' '}
                    <a href={helpcenterLink} target="_blank" rel="noreferrer">
                      Help Center
                    </a>
                    .
                  </Text>
                  <Text type="p">
                    We are exploring an open Beta program for our mobile apps.
                    Stay tuned!
                  </Text>
                </FooterTextWrapper>
              </CardFooter>
            </React.Fragment>
          </Card>
        </BlockWrapper>
        <BlockWrapper>
          <Card title="Current Beta features">
            <CardBody>
              <BetaInfoWrapper>
                <InfoIcon />
                <Text type="p">
                  {isBetaProgramEnabled
                    ? 'Here are the features in Beta, which you may spot as you use Buffer'
                    : ' Join our Beta program above to access these current Beta features'}
                </Text>
              </BetaInfoWrapper>
            </CardBody>
            <CardFooter>
              <BetaFeaturesWrapper>
                {betaFeatures.length > 0 ? (
                  betaFeatures.map((feature, index) => {
                    return (
                      <BetaFeature key={index}>
                        <BetaFeatureTextWrapper
                          isBetaProgramEnabled={isBetaProgramEnabled}
                        >
                          <Text type="p" className="featureTitle">
                            {feature.name}
                          </Text>
                          <Text type="p" className="featureDescription">
                            {feature.description}
                          </Text>
                        </BetaFeatureTextWrapper>
                        <BetaFeatureButtonWrapper>
                          {feature.feedbackUrl !== null &&
                            isBetaProgramEnabled && (
                              /* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */
                              <Button
                                type="secondary"
                                label="Share Feedback"
                                onClick={() =>
                                  window.open(
                                    hasVerifiedEmail
                                      ? `${HELLONEXT_LOGIN_SERVICE_URL}&redirect=${feature.feedbackUrl}`
                                      : `${feature.feedbackUrl}`,
                                    '_blank',
                                  )
                                }
                                size="small"
                              />
                            )}
                        </BetaFeatureButtonWrapper>
                      </BetaFeature>
                    )
                  })
                ) : (
                  <NoBetaFeaturesText>
                    <Text type="p">
                      There are currently no Beta features - check back soon, or
                      ask us what&apos;s coming up in the Discord community!
                    </Text>
                  </NoBetaFeaturesText>
                )}
              </BetaFeaturesWrapper>
            </CardFooter>
          </Card>
        </BlockWrapper>
        <BlockWrapper>
          {isBetaProgramEnabled && (
            <Card title="Thanks for joining our Beta program! 🎉">
              <CardBody>
                <BetaCommunityWrapper>
                  <BetaCommunityTextWrapper>
                    <Text type="p" color="grayDark">
                      Join our Discord community to connect with the Buffer team
                      and other Buffer Beta users.
                    </Text>
                  </BetaCommunityTextWrapper>
                  <ActionButtonWrapper>
                    {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
                    <Button
                      type="secondary"
                      label="Join the Community"
                      onClick={() => handleJoinCommunity()}
                      icon={<DiscordIcon />}
                      size="small"
                    />
                  </ActionButtonWrapper>
                </BetaCommunityWrapper>
                <BetaCommunityWrapper>
                  <BetaCommunityTextWrapper>
                    <Text type="p" color="grayDark">
                      As you try out our Beta features, we&apos;d love to hear
                      your feedback and get your help in shaping Buffer.
                    </Text>
                  </BetaCommunityTextWrapper>
                  <ActionButtonWrapper>
                    {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
                    <Button
                      type="secondary"
                      label="View our Roadmap"
                      icon={<ListIcon />}
                      onClick={() =>
                        window.open(
                          'https://suggestions.buffer.com/roadmap',
                          '_blank',
                        )
                      }
                      size="small"
                    />
                  </ActionButtonWrapper>
                </BetaCommunityWrapper>
              </CardBody>
            </Card>
          )}
        </BlockWrapper>
      </React.Fragment>
    )
  }

  return (
    <>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; withSidebar: true; with... Remove this comment to see the full error message */}
      <PageLayout withSidebar withAppShell withOrgSwitcher refetch={refetch}>
        {account.isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          renderBody()
        )}
      </PageLayout>
    </>
  )
}

BetaFeaturesPage.propTypes = {
  refetch: PropTypes.func,
}

export default BetaFeaturesPage
