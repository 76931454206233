import React from 'react'
import PropTypes from 'prop-types'
import { ArrowLeft as ArrowLeftIcon } from '@bufferapp/ui/Icon'
import { BreadcrumbStyled, BreadcrumbLabel } from './style'

// @ts-expect-error TS(7031) FIXME: Binding element 'href' implicitly has an 'any' typ... Remove this comment to see the full error message
const Breadcrumb = ({ href, label }) => (
  <BreadcrumbStyled href={href}>
    <ArrowLeftIcon />
    <BreadcrumbLabel>{label}</BreadcrumbLabel>
  </BreadcrumbStyled>
)

Breadcrumb.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string.isRequired,
}

Breadcrumb.defaultProps = {
  href: '/',
  label: '',
}

export default Breadcrumb
