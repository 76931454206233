import React, { useEffect, useRef, useState } from 'react'
import * as Styled from './styles'
import type { AnswerType, SurveyQuestionType } from '../../types'
import { getIcon } from '../../helpers'

export const SurveyAnswers = ({
  questionId,
  answers,
  multipleSelection,
  onChangeSelection,
}: {
  questionId: string
  answers: AnswerType[]
  multipleSelection: boolean
  onChangeSelection: ({ questionId, answers }: SurveyQuestionType) => void
}): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [optionalAnswer, setOptionalAnswer] = useState('')
  const showTextBox = selectedOptions.includes('other')
  const optionalAnswerInputRef = useRef<HTMLInputElement | null>(null)

  /* if element is checked, we will add to the array of selected elements,
    otherwise we will remove it */
  const updateOptions = ({
    prevOptions,
    isSelected,
    value,
  }: {
    prevOptions: string[]
    isSelected: boolean
    value: string
  }): string[] => {
    return isSelected
      ? [...prevOptions, value]
      : prevOptions.filter((opt) => opt !== value)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target.value
    const isSelected = event.target.checked
    setSelectedOptions((prevOptions) => {
      const newOptions = multipleSelection
        ? updateOptions({ prevOptions, isSelected, value })
        : [value]
      return newOptions
    })
  }

  const changeOptionalAnswer = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setOptionalAnswer(event.target.value)
  }

  function addInfoToOtherOption(
    options: string[],
    optionalAnswer: string,
  ): string[] {
    return options.map((option) => {
      if (option === 'other') {
        return option + ': ' + optionalAnswer // Add optional answer to other option
      } else {
        return option
      }
    })
  }

  useEffect(() => {
    // Notify the parent component about the change
    let updatedOptions = selectedOptions
    if (optionalAnswer) {
      updatedOptions = addInfoToOtherOption(selectedOptions, optionalAnswer)
    }
    onChangeSelection({ questionId, answers: updatedOptions })
  }, [selectedOptions, optionalAnswer])

  /* Focus on the text box when the user selects the other option */
  useEffect(() => {
    if (
      showTextBox &&
      optionalAnswerInputRef !== null &&
      optionalAnswerInputRef.current
    ) {
      optionalAnswerInputRef.current.focus()
    }
  }, [showTextBox, optionalAnswerInputRef])

  return (
    <Styled.AnswersWrapper>
      {answers.map((answer, index) => (
        <Styled.AnswerLabel key={index}>
          {/* Checkbox or radio button for answer */}
          <Styled.AnswerInput
            type={multipleSelection ? 'checkbox' : 'radio'}
            name={multipleSelection ? answer.id : questionId}
            value={answer.id}
            onChange={handleInputChange}
            checked={selectedOptions.includes(answer.id)}
            aria-checked={selectedOptions.includes(answer.id)}
          />
          {answer.icon && getIcon(answer.icon)}
          {answer.label}
        </Styled.AnswerLabel>
      ))}
      {/* Other Answer */}
      <Styled.OtherAnswerWrapper>
        <Styled.AnswerLabel>
          <Styled.AnswerInput
            type={multipleSelection ? 'checkbox' : 'radio'}
            name={multipleSelection ? 'other' : questionId}
            value="other"
            onChange={handleInputChange}
            checked={selectedOptions.includes('other')}
            aria-checked={selectedOptions.includes('other')}
          />
          Other
        </Styled.AnswerLabel>
        {/* Text box for optional answer */}
        {showTextBox && (
          <Styled.OtherTextBox
            ref={optionalAnswerInputRef}
            type="text"
            onChange={changeOptionalAnswer}
            name="optionalAnswer"
            placeholder="Your answer (optional)"
            value={optionalAnswer}
            maxLength={100}
          />
        )}
      </Styled.OtherAnswerWrapper>
    </Styled.AnswersWrapper>
  )
}

export default SurveyAnswers
