import React from 'react'
import { Button, Text } from '@bufferapp/ui'
import { Card, ContactAdvocacyWrapper } from './style'
import { HelpScoutBeacon } from '../../../../../../helpScoutBeacon'

const ContactAdvocacy = (): JSX.Element => {
  return (
    <ContactAdvocacyWrapper>
      <Card>
        <Text type="h1">Ready to update your plan?</Text>
        <Text type="p">
          We would love to help you update your account. Please reach out to our
          Customer Advocacy team who can walk you through the migration - or
          answer any questions you may have!
        </Text>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; label... Remove this comment to see the full error message */}
        <Button
          data-testid="account-billing-migrationhub-contactadvocacy"
          type="primary"
          label="Get in touch"
          onClick={(): void => {
            HelpScoutBeacon.openLegacyMigrationForm()
          }}
        ></Button>
      </Card>
    </ContactAdvocacyWrapper>
  )
}

export default ContactAdvocacy
