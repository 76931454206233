import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useAccount } from '../../../../context/Account'
import {
  capitalizeFirstLetter,
  getProductUrl,
} from '../../../../../../shared-utils'
import Button from '@bufferapp/ui/Button'

import { MIGRATE_TO_NEW_BUFFER } from '../../../../apollo/mutations/billing'
import { GET_ACCOUNT } from '../../../../apollo/queries/account'

const MigratePlanToNewBufferButton = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  label,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedChannelQuantity' implicit... Remove this comment to see the full error message
  selectedChannelQuantity,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedInterval' implicitly has ... Remove this comment to see the full error message
  selectedInterval,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedPlan' implicitly has an '... Remove this comment to see the full error message
  selectedPlan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDisabled' implicitly has an 'an... Remove this comment to see the full error message
  isDisabled,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openModal' implicitly has an 'any... Remove this comment to see the full error message
  openModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setShowingModal' implicitly has a... Remove this comment to see the full error message
  setShowingModal,
}) => {
  const [error, setError] = useState(null)
  const [migrateToBufferViaHub, { loading }] = useMutation(
    MIGRATE_TO_NEW_BUFFER,
    {
      onCompleted(data) {
        if (data?.billingMigrateToBufferViaHub.userFriendlyMessage) {
          setError(data?.billingMigrateToBufferViaHub.userFriendlyMessage)
        } else {
          openModal(false)
          setShowingModal(false)
          window.location.href = `${getProductUrl(
            'account',
          )}/billing?migration=success`
        }
      },
      onError(e) {
        setError(
          // @ts-expect-error TS(2345) FIXME: Argument of type '"Whoops! Looks like there was a ... Remove this comment to see the full error message
          `Whoops! Looks like there was a problem migrating your subscription.`,
        )
        throw new Error(
          `There was a problem migrating to new buffer: ${e.message}.`,
        )
      },
      refetchQueries: [{ query: GET_ACCOUNT }],
    },
  )

  const account = useAccount()

  function handleOnClick() {
    migrateToBufferViaHub({
      variables: {
        interval: selectedInterval,
        organizationId: account.currentOrganization.id,
        plan: selectedPlan,
        quantity: selectedChannelQuantity,
      },
    })
  }

  return (
    // @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message
    <Button
      type="primary"
      onClick={() => handleOnClick()}
      label={loading ? 'Processing...' : capitalizeFirstLetter(label)}
      fullWidth
      disabled={isDisabled}
    />
  )
}

MigratePlanToNewBufferButton.propTypes = {
  label: PropTypes.string.isRequired,
  selectedChannelQuantity: PropTypes.number.isRequired,
  selectedInterval: PropTypes.string.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  setShowingModal: PropTypes.func.isRequired,
}

export default MigratePlanToNewBufferButton
