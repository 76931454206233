import React from 'react'
import PropTypes from 'prop-types'
import { SimpleModal, Text, Button } from '@bufferapp/ui'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
} from '../../../../../../../../account-components'

// @ts-expect-error TS(7006) FIXME: Parameter 'onClose' implicitly has an 'any' type.
const openSubscriptionUpdate = (onClose): void => {
  const { MODALS, actions } = window?.appshell || {}
  actions.openModal(MODALS.subscriptionUpdate, {
    cta: 'connectChannels-notice-addChannels-1',
    upgradePathName: 'accountChannels-upgrade',
    shouldPickModalOnOrganizationState: true,
  })

  onClose()
}

const redirectToBillingPage = (): void => {
  // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
  window.location = '/billing'
}

type PaywallModalArgs = {
  onClose: () => void
  type: string
  isOneBufferOrg: boolean
  isFreePlan: boolean
  shouldShowBillingActions: boolean
}

const PaywallModal = ({
  onClose,
  type,
  isOneBufferOrg,
  isFreePlan,
  shouldShowBillingActions,
}: PaywallModalArgs): JSX.Element => {
  return (
    <SimpleModal closeAction={onClose}>
      <ModalContent>
        <ModalHeader padding>
          <h2>
            {!isFreePlan ? 'Add more channels' : 'Upgrade your Buffer plan'}
          </h2>
        </ModalHeader>
        <ModalBody>
          {type === 'limits' && (
            <Text type="p">
              You&apos;ve reached the limit on the number of channels you can
              connect based on your current plan.{' '}
              {!isFreePlan
                ? 'Add at least one more channel to continue.'
                : 'To connect more channels, please upgrade your plan.'}
            </Text>
          )}
        </ModalBody>
        <ModalFooter padding>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
          <Button type="text" label="Close" onClick={onClose} />
          {shouldShowBillingActions && (
            // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message
            <Button
              type="primary"
              label={!isFreePlan ? 'Add channels' : 'Upgrade Plan'}
              onClick={(): void => {
                isOneBufferOrg
                  ? openSubscriptionUpdate(onClose)
                  : redirectToBillingPage()
              }}
            />
          )}
        </ModalFooter>
      </ModalContent>
    </SimpleModal>
  )
}

PaywallModal.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,
  isOneBufferOrg: PropTypes.bool,
  isFreePlan: PropTypes.bool,
  isRevenueCat: PropTypes.bool,
  shouldShowBillingActions: PropTypes.bool,
}

PaywallModal.defaultProps = {
  type: 'limits',
  isOneBufferOrg: false,
  isFreePlan: false,
}

export default PaywallModal
