import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text, Tooltip } from '@bufferapp/ui'
import logos from '../../../web/src/pages/Channels/ConnectChannelsPage/components/logos'
import * as ChannelButton from '../../../web/src/pages/Channels/ConnectChannelsPage/components/ConnectChannelCards/styles'
import { getProductUrl } from '../../../shared-utils'

export const Button = styled(ChannelButton.ButtonStyled)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 1rem;
  justify-content: center;

  :disabled {
    opacity: 0.5;
  }
`

export const ButtonContent = styled.main`
  width: 100%;
  text-align: center;
  & h3 {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  & span {
    line-height: 1.25rem;
  }
`

const ServiceLogo = styled.div`
  margin-bottom: 4px;
  transform: scale(1.2);
`

const ServiceButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  width: 173px;
  height: 176px;
  padding: 18px 16px;
`

type ConnectChannelButtonProps = {
  service: string
  formattedServiceName?: string
  children: React.ReactNode
  onClick: () => void
  disabledMessage?: string
  product: string
}

const getDestinationUrl = (product: string): string => {
  if (product === 'analytics') return getProductUrl('analyze')
  return getProductUrl('publish')
}

export const ConnectChannelButton = ({
  service,
  formattedServiceName,
  children,
  onClick,
  disabledMessage,
  product,
}: ConnectChannelButtonProps): JSX.Element => {
  const destinationUrl = getDestinationUrl(product)

  const serviceName =
    service.toLowerCase() === 'googlebusinessprofile'
      ? 'googlebusiness'
      : service.toLowerCase()

  const isNewAuthFlowEnabled = !(
    service.toLowerCase() === 'shopify' || service.toLowerCase() === 'startpage'
  )

  const triggerNewConnectionFlow = (): void => {
    if (window.appshell?.actions) {
      const { actions } = window.appshell || {}
      actions.connectChannel({
        selectedService: serviceName,
        destinationUrl,
        cta: `account-onboarding-${product}-channelConnect-${serviceName}-1`,
      })
    }
  }

  const onConnectClick = (): void => {
    isNewAuthFlowEnabled ? triggerNewConnectionFlow() : onClick()
  }

  const button = (
    <ServiceButton
      disabled={!!disabledMessage}
      data-testid={`connect-button-${service.toLocaleLowerCase()}`}
      onClick={onConnectClick}
    >
      <ServiceLogo>
        {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
        {React.createElement(logos[service.toLowerCase()])}
      </ServiceLogo>
      <ButtonContent>
        <Text type={'h3'}>{formattedServiceName || service}</Text>
        <Text type="p">{children}</Text>
      </ButtonContent>
    </ServiceButton>
  )

  return disabledMessage ? (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: any; position: s... Remove this comment to see the full error message
    <Tooltip label={disabledMessage} position="bottom">
      {button}
    </Tooltip>
  ) : (
    button
  )
}

ConnectChannelButton.propTypes = {
  service: PropTypes.string,
  formattedServiceName: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabledMessage: PropTypes.string,
}
