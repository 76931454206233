import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Card,
  CardBody,
  BlockWrapper,
  PageLayout,
  TitleWrapper,
  Notification,
} from '../../../../account-components'
import { Button, Input, Loader, Text } from '@bufferapp/ui'

import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'

import useReferralCode from './useReferralCode'

const LoaderWrapper = styled.div`
  margin: 6rem 0;
`
import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'refetch' implicitly has an 'any' ... Remove this comment to see the full error message
const ReferralsPage = ({ refetch }) => {
  const account = useSelector((state) => state.account)
  const currentName = account?.currentOrganization.name

  const [showCopiedNotification, setShowCopiedNotification] = useState(false)
  const { data, loading, error } = useReferralCode(
    account.currentOrganization.id,
  )

  const renderBody = () => {
    if (loading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )
    }

    const referralCode = data.organizationReferralCode.referralCode

    const handleCopyReferralCode = (): void => {
      BufferTracker.referralLinkCopied({
        organizationId: account?.currentOrganization?.id,
      })

      navigator.clipboard.writeText(`https://buffer.com/join/${referralCode}`)
      setShowCopiedNotification(true)
      setTimeout(() => setShowCopiedNotification(false), 5000)
    }

    const handleShareReferralCode = (): void => {
      BufferTracker.referralPostStarted({
        organizationId: account?.currentOrganization?.id,
      })

      window.open(
        `https://publish.buffer.com/compose?text=I%E2%80%99m%20using%20Buffer%20to%20grow%20an%20engaged%20audience%20on%20social%20and%20beyond.%20Give%20it%20a%20try%20and%20create%20your%20free%20%28forever%29%20account%20today%3A&url=https://buffer.com/join/${referralCode}`,
        '_blank',
      )
    }

    return (
      <React.Fragment>
        {showCopiedNotification && (
          <Notification hideInMs={5000}>
            Your unique referral link has been copied.
          </Notification>
        )}

        <TitleWrapper>
          <Text type="h1">Refer a Friend</Text>
        </TitleWrapper>
        <BlockWrapper>
          <Card>
            <form>
              <React.Fragment>
                <CardBody>
                  <Text type="p">
                    Enjoying Buffer? Your friends will too!
                    <br />
                    <br />
                    Share Buffer to help them grow an audience with a totally
                    free account. You’ll also support Buffer’s growth, allowing
                    us to continue improving the service.
                    <br />
                    <br />
                  </Text>

                  <Text type="h3">How to share</Text>
                  <Text type="p">
                    1. Find the unique referral link below for your
                    organization.
                    <br />
                    2. Share your link publicly or with someone who’d enjoy
                    using Buffer.
                    <br />
                    3. Your friend signs up for their free Buffer account.
                    <br />
                    4. You and your friend can grow on social and beyond.
                    <br />
                    <br />
                    <strong>{currentName}'s unique referral link:</strong>
                  </Text>

                  <Styles.InputWrapper>
                    <Styles.InputInlineWrapper>
                      <Input
                        // @ts-expect-error TS(2322) FIXME: Type '{ label: string; name: string; id: string; o... Remove this comment to see the full error message
                        label=""
                        name="referral-link"
                        id="referral-link"
                        value={`https://buffer.com/join/${referralCode}`}
                        readOnly={true}
                        onChange={() => {}}
                      />
                    </Styles.InputInlineWrapper>
                    <Styles.CopyButtonWrapper>
                      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
                      <Button
                        type="secondary"
                        label="Copy"
                        disabled={false}
                        size="small"
                        // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
                        onClick={(event) => {
                          event.preventDefault()
                          handleCopyReferralCode()
                        }}
                      />
                    </Styles.CopyButtonWrapper>
                    <Styles.CopyButtonWrapper>
                      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
                      <Button
                        type="primary"
                        label="Share Now"
                        size="small"
                        disabled={false}
                        // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
                        onClick={(event) => {
                          event.preventDefault()
                          handleShareReferralCode()
                        }}
                      />
                    </Styles.CopyButtonWrapper>
                  </Styles.InputWrapper>
                </CardBody>
              </React.Fragment>
            </form>

            <Styles.CardFooter>
              <Styles.FooterTextWrapper>
                <Text type="p">
                  Help us improve the Buffer referral experience!{' '}
                  <a
                    href="https://buffersurvey.typeform.com/to/ZMKM3mtH"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Take our quick survey
                  </a>
                  .
                </Text>
              </Styles.FooterTextWrapper>
            </Styles.CardFooter>
          </Card>
        </BlockWrapper>
      </React.Fragment>
    )
  }

  return (
    <>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; withSidebar: true; with... Remove this comment to see the full error message */}
      <PageLayout withSidebar withAppShell withOrgSwitcher refetch={refetch}>
        {account.isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          renderBody()
        )}
      </PageLayout>
    </>
  )
}

ReferralsPage.propTypes = {
  refetch: PropTypes.func,
}

export default ReferralsPage
