import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'
import {
  Notification as NewNotification,
  TitleWrapper,
} from '../../../../account-components'
import Notification from '@bufferapp/ui/Notification'
import DeleteAccount from '../../../../delete-account'

import UpdateEmailForm from '../../components/UpdateEmailForm'
import UpdatePasswordForm from '../../components/UpdatePasswordForm'
import UpdateConfirmationForm from '../../components/UpdateConfirmationForm'
import Breadcrumb from '../../components/Breadcrumb'
import { PageWrapper, ContentWrapper, BlockWrapper } from './style'

import { queryParams } from '../../utils/urls'

class AccountSettings extends Component {
  state = {
    currEmail: '',
    modalCopy: '',
    newEmail: '',
    newPassword: '',
    onConfirmationStep: false,
    notification: '',
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  static getDerivedStateFromProps(props, state) {
    if (props.email !== state.currEmail) {
      return {
        currEmail: props.email,
        newEmail: '',
      }
    }
    return null
  }

  showConfirmationForm(show = true) {
    const newState = {
      onConfirmationStep: show,
    }

    if (!show) {
      // @ts-expect-error TS(2339) FIXME: Property 'newEmail' does not exist on type '{ onCo... Remove this comment to see the full error message
      newState.newEmail = ''
      // @ts-expect-error TS(2339) FIXME: Property 'newPassword' does not exist on type '{ o... Remove this comment to see the full error message
      newState.newPassword = ''
      // @ts-expect-error TS(2339) FIXME: Property 'modalCopy' does not exist on type '{ onC... Remove this comment to see the full error message
      newState.modalCopy = ''
    }

    this.setState(newState)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onEmailSubmit = (e) => {
    e.preventDefault()
    this.setState({
      newEmail: e.target[0].value,
      modalCopy: 'To confirm this email change, please enter your password',
    })
    this.showConfirmationForm()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onPasswordSubmit = (e) => {
    e.preventDefault()
    this.setState({
      newPassword: e.target[0].value,
      modalCopy:
        'To confirm this password change, please enter your previous password',
    })
    this.showConfirmationForm()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onConfirmationSubmit = (e) => {
    e.preventDefault()
    const currPassword = e.target[0].value
    this.updateAccount(currPassword)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onConfirmationCancel = (e) => {
    e.preventDefault()
    this.showConfirmationForm(false)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  setEmailVerificationNotificationText = (message) => {
    this.setState({
      notification: message,
    })
  }

  onEmailVerificationNotificationDismiss = () => {
    this.setState({
      notification: '',
    })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'currPassword' implicitly has an 'any' t... Remove this comment to see the full error message
  updateAccount(currPassword) {
    const { newEmail, newPassword } = this.state

    if (newEmail) {
      // @ts-expect-error TS(2339) FIXME: Property 'updateEmail' does not exist on type 'Rea... Remove this comment to see the full error message
      this.props.updateEmail(newEmail, currPassword)
      this.setState({
        newEmail: '',
      })
    } else if (newPassword) {
      // @ts-expect-error TS(2339) FIXME: Property 'updatePassword' does not exist on type '... Remove this comment to see the full error message
      this.props.updatePassword(newPassword, currPassword)
      this.setState({
        newPassword: '',
      })
    }

    this.showConfirmationForm(false)
  }

  onNotificationDismiss = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'clearNotification' does not exist on typ... Remove this comment to see the full error message
    this.props.clearNotification()
  }

  render() {
    const { currEmail, modalCopy, newEmail, onConfirmationStep, notification } =
      this.state
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'updatesDisabled' does not exist on type ... Remove this comment to see the full error message
      updatesDisabled,
      // @ts-expect-error TS(2339) FIXME: Property 'notificationOpened' does not exist on ty... Remove this comment to see the full error message
      notificationOpened,
      // @ts-expect-error TS(2339) FIXME: Property 'notificationMessage' does not exist on t... Remove this comment to see the full error message
      notificationMessage,
      // @ts-expect-error TS(2339) FIXME: Property 'redirect' does not exist on type 'Readon... Remove this comment to see the full error message
      redirect,
      // @ts-expect-error TS(2339) FIXME: Property 'product' does not exist on type 'Readonl... Remove this comment to see the full error message
      product,
      // @ts-expect-error TS(2339) FIXME: Property 'hasVerifiedEmail' does not exist on type... Remove this comment to see the full error message
      hasVerifiedEmail,
    } = this.props

    const productName = product[0].toUpperCase() + product.slice(1)
    const breadcrumbLabel = productName ? `Back to ${productName}` : 'Back'

    const showEmailVerificationSuccessNotification =
      // @ts-expect-error TS(2339) FIXME: Property 'messageCode' does not exist on type 'URL... Remove this comment to see the full error message
      queryParams().messageCode &&
      // @ts-expect-error TS(2339) FIXME: Property 'messageCode' does not exist on type 'URL... Remove this comment to see the full error message
      queryParams().messageCode === 'email-verification-success'

    const showEmailVerificationErrorNotification =
      // @ts-expect-error TS(2339) FIXME: Property 'messageCode' does not exist on type 'URL... Remove this comment to see the full error message
      queryParams().messageCode &&
      // @ts-expect-error TS(2339) FIXME: Property 'messageCode' does not exist on type 'URL... Remove this comment to see the full error message
      queryParams().messageCode === 'email-verification-error'

    const showEmailUpdateSuccessNotification =
      // @ts-expect-error TS(2339) FIXME: Property 'messageCode' does not exist on type 'URL... Remove this comment to see the full error message
      queryParams().messageCode &&
      // @ts-expect-error TS(2339) FIXME: Property 'messageCode' does not exist on type 'URL... Remove this comment to see the full error message
      queryParams().messageCode === 'email-update-success'

    return (
      <PageWrapper>
        {notificationOpened && (
          <Notification
            text={notificationMessage}
            onClose={this.onNotificationDismiss}
          />
        )}
        {showEmailVerificationSuccessNotification && (
          <NewNotification hideInMs={5000}>
            Your email was verified!
          </NewNotification>
        )}
        {showEmailVerificationErrorNotification && (
          <NewNotification hideInMs={5000}>
            We were not able to verify your email. Your verification link may
            have expired.
          </NewNotification>
        )}
        {showEmailUpdateSuccessNotification && (
          <NewNotification hideInMs={5000}>
            Your email has been successfully updated!
          </NewNotification>
        )}
        {notification && (
          <Notification
            text={notification}
            onClose={this.onEmailVerificationNotificationDismiss}
          />
        )}
        <ContentWrapper>
          <BlockWrapper>
            {redirect && <Breadcrumb href={redirect} label={breadcrumbLabel} />}
            <TitleWrapper>
              <Text type="h1">Account</Text>
            </TitleWrapper>
            <UpdateEmailForm
              email={newEmail || currEmail}
              onSubmit={this.onEmailSubmit}
              updatesDisabled={updatesDisabled}
              hasVerifiedEmail={hasVerifiedEmail}
              setEmailVerificationNotificationText={
                this.setEmailVerificationNotificationText
              }
            />
          </BlockWrapper>
          <BlockWrapper>
            <UpdatePasswordForm
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              onSubmit={this.onPasswordSubmit}
            />
          </BlockWrapper>
          {onConfirmationStep && (
            <UpdateConfirmationForm
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              modalCopy={modalCopy}
              onSubmit={this.onConfirmationSubmit}
              onCancel={this.onConfirmationCancel}
              newPassword={this.state.newPassword}
            />
          )}
          <DeleteAccount />
        </ContentWrapper>
      </PageWrapper>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
AccountSettings.propTypes = {
  updateEmail: PropTypes.func,
  updatePassword: PropTypes.func,
  clearNotification: PropTypes.func,
  notificationOpened: PropTypes.bool,
  notificationMessage: PropTypes.string,
  updatesDisabled: PropTypes.bool,
  email: PropTypes.string,
  redirect: PropTypes.string,
  product: PropTypes.string,
  hasVerifiedEmail: PropTypes.bool,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
AccountSettings.defaultProps = {
  email: null,
  updateEmail: null,
  updatePassword: null,
  clearNotification: null,
  notificationOpened: false,
  notificationMessage: '',
  updatesDisabled: false,
  redirect: '',
  product: '',
  hasVerifiedEmail: false,
}

export default AccountSettings
