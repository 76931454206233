import React from 'react'

import { Text } from '@bufferapp/ui'

import * as Styles from './styles'

export const SocialProof = ({
  text,
}: {
  text: string | TrustedHTML | undefined
}): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Styles.CopyWrapper>
        {text && (
          <Text type="p">
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </Text>
        )}
      </Styles.CopyWrapper>
      <Styles.NameWrapper>
        <img
          src="https://buffer.com/resources/content/images/size/w300/2020/06/Joel-Gascoigne.png"
          alt="Joel from Buffer avatar"
        />{' '}
        <Text type="p">Joel from Buffer</Text>
      </Styles.NameWrapper>
    </Styles.Wrapper>
  )
}
