import { grayDark } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const CompanyLogosContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 6rem;
  justify-content: center;
  align-items: center;

  p {
    margin: 4rem 0;
    font-size: 14px;
    color: ${grayDark};
  }
`

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  width: 80%;
  max-width: 1020px;
`

export const CompanyLogo = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: center;
`
