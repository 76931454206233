import React from 'react'

import * as Styles from './styles'
import { useAccount } from '../../../../../web/src/context/Account'
import {
  HELLONEXT_URL,
  HELLONEXT_LOGIN_SERVICE_URL,
} from '../../../../../shared-utils/constants'
import type { Account } from '../../../../../web/src/pages/Channels/auth-flows/shared/SelectChannelList/utils'

export const RequestChannel = (): JSX.Element => {
  const account: Account = useAccount()
  const URL = `${HELLONEXT_URL}/b/channel-requests`
  const hasVerifiedEmail = account?.hasVerifiedEmail ?? false

  const LINK = hasVerifiedEmail
    ? `${HELLONEXT_LOGIN_SERVICE_URL}&redirect=${URL}`
    : URL

  return (
    <Styles.Wrapper>
      <Styles.Section>
        <span>Can&apos;t find what you need?</span>
      </Styles.Section>
      <Styles.Section>
        <a href={LINK} target="_blank" rel="noopener noreferrer">
          Request a Channel
        </a>
      </Styles.Section>
    </Styles.Wrapper>
  )
}
