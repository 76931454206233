import React from 'react'

import { COMPANY_LOGOS } from '../../data/company-customers'

import { CompanyLogosContainer, LogosContainer, CompanyLogo } from './style'

// TODO: This componenet still needs to:
// 1. Correct styling
const CompanyLogos = () => {
  return (
    <CompanyLogosContainer>
      <p>Most customers like you have already upgraded</p>
      <LogosContainer>
        {COMPANY_LOGOS.map((company) => {
          return (
            <CompanyLogo key={company.name}>
              <img
                width={company.width}
                src={company.imageURL}
                alt={company.alt}
              />
            </CompanyLogo>
          )
        })}
      </LogosContainer>
    </CompanyLogosContainer>
  )
}

export default CompanyLogos
