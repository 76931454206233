import React from 'react'
import { Text } from '@bufferapp/ui'

import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import { AccordionFooter } from '../AccordionFooter'
import { SocialProof } from './components/SocialProof'

import { blue } from '@bufferapp/ui/style/colors'
import * as Styles from './styles'

import type { ConnectableService } from '../../../../../../interfaces'

export const AccordionBody = ({
  service,
}: {
  service: ConnectableService
}): JSX.Element => {
  return (
    <>
      <Styles.Wrapper>
        <Styles.Body>
          <Text type="p">
            <strong>Why {service?.title}</strong>
          </Text>
          <ul>
            {service?.description.map((description) => (
              <li key={description}>
                <CheckmarkIcon color={blue} size="medium" />
                <Text type="p">{description}</Text>
              </li>
            ))}
          </ul>
          {service.socialProof && <SocialProof text={service.socialProof} />}
          <div>
            <Text type="p">
              <strong>Useful Links</strong>
            </Text>
            <ul>
              {service.links &&
                service.links.map((link) => (
                  <li key={link.url}>
                    <Text type="p">
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        {link.text}
                      </a>
                    </Text>
                  </li>
                ))}
            </ul>
          </div>
        </Styles.Body>
        <Styles.Data>
          <Styles.DataSection>
            <Text type="p">
              <strong>Supported Features</strong>
            </Text>
            <Styles.TagGroup>
              {service?.contentTypes.map((contentType) => (
                <Styles.Tag key={contentType}>
                  {contentType.replace('_', ' ')}
                </Styles.Tag>
              ))}
            </Styles.TagGroup>
          </Styles.DataSection>

          <Styles.DataSection>
            <Text type="p">
              <strong>{service?.title} works well with</strong>
            </Text>
            <Styles.TagGroup>
              {service.similarNetworks &&
                service.similarNetworks.map((network) => (
                  <Styles.Tag key={network}>{network}</Styles.Tag>
                ))}
            </Styles.TagGroup>
          </Styles.DataSection>
        </Styles.Data>
      </Styles.Wrapper>
      <AccordionFooter service={service} />
    </>
  )
}
