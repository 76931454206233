import React from 'react'
import { gray } from '@bufferapp/ui/style/colors'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'

import type { ConnectableService } from '../../../../../../interfaces'

import * as Styles from './styles'

export const AccordionFooter = ({
  service,
}: {
  service: ConnectableService
}): JSX.Element => {
  return (
    <Styles.Wrapper>
      <WarningIcon size="medium" color={gray} />
      <p>
        Cannot connect your {service.title} account?{' '}
        <a
          href="https://support.buffer.com/?openContact=true&utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact support
        </a>
      </p>
    </Styles.Wrapper>
  )
}
