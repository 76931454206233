import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import styled from 'styled-components'
import {
  Card,
  CardBody,
  CardFooter,
  BlockWrapper,
  PageLayout,
  TitleWrapper,
  LabelGroup,
} from '../../../../account-components'
import {
  Button,
  Input,
  Loader,
  Notice,
  Notification,
  Text,
  Tooltip,
} from '@bufferapp/ui'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'

import { ORGANIZATION_SET_NAME } from '../../apollo/mutations/organization'

dayjs.extend(advancedFormat)

const LoaderWrapper = styled.div`
  margin: 6rem 0;
`

const OrganizationsPage = ({
  refetch,
}: {
  refetch: () => void
}): JSX.Element => {
  const account = useSelector((state) => state.account)
  const currentName = account.currentOrganization.name
  const organizationId = account.currentOrganization.id

  const [showSuccessNotification, setShowSuccessNotification] = useState(false)
  const [newName, setNewOrgName] = useState('')

  const [errorText, setErrorText] = useState('')

  useEffect(() => {
    setNewOrgName(currentName)
  }, [currentName])

  const [organizationSetName, { loading, error }] = useMutation(
    ORGANIZATION_SET_NAME,
    {
      onCompleted() {
        // Re-fetch account details
        refetch()
        setShowSuccessNotification(true)
        setTimeout(() => {
          setShowSuccessNotification(false)
        }, 6000)
      },
    },
  )

  if (error && !errorText) {
    setErrorText(
      'Sorry, but there was a problem saving your name. Please try again or contact us if this problem persists.',
    )
  }

  const renderBody = (): JSX.Element => {
    return (
      <React.Fragment>
        {showSuccessNotification ? (
          <Notification
            text="Your organization name was successfully updated."
            onClose={(): void => {
              setShowSuccessNotification(false)
            }}
          />
        ) : null}
        <TitleWrapper>
          <Text type="h1">Organization</Text>
        </TitleWrapper>
        {!account.currentOrganization.canEdit && (
          <BlockWrapper>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
            <Notice type="note">
              <InfoIcon className="note-icon" />
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
              <Text>Contact an Admin in your organization to edit name.</Text>
            </Notice>
          </BlockWrapper>
        )}
        <BlockWrapper>
          <Card
            title={account.currentOrganization.name || 'Organization Details'}
          >
            <form>
              <React.Fragment>
                <CardBody>
                  <LabelGroup text="Creation Date">
                    <Text type="p" color="grayDark">
                      {dayjs(account.currentOrganization.createdAt).format(
                        'MMMM Do, YYYY',
                      )}
                    </Text>
                  </LabelGroup>
                  <Input
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error TS(2322) FIXME: Type '{ label: string; name: string; id: string; o... Remove this comment to see the full error message
                    label="Organization Name"
                    name="org-name"
                    id="org-name"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      if (errorText) {
                        setErrorText('')
                      }

                      setNewOrgName(e.target.value)
                    }}
                    value={newName}
                    required
                  />
                  {errorText ? (
                    <Text type="help" hasError>
                      {errorText}
                    </Text>
                  ) : (
                    ''
                  )}
                </CardBody>
                <CardFooter>
                  {/* @ts-expect-error TS(2322) */}
                  <Tooltip
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    label={
                      newName.includes('.')
                        ? 'Organization names cannot contain periods.'
                        : ''
                    }
                  >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; disabled: boo... Remove this comment to see the full error message */}
                    <Button
                      type="primary"
                      label={loading ? 'Saving...' : 'Save Changes'}
                      disabled={
                        loading ||
                        newName.length === 0 ||
                        (newName && newName.trim() === '') ||
                        (newName && newName.trim() === currentName) ||
                        !account.currentOrganization.canEdit ||
                        newName.includes('.')
                      }
                      onClick={(e: React.FormEvent<HTMLFormElement>): void => {
                        e.preventDefault()
                        setErrorText('')
                        organizationSetName({
                          variables: {
                            organizationId,
                            name: newName.trim(),
                          },
                        })
                      }}
                    />
                  </Tooltip>
                </CardFooter>
              </React.Fragment>
            </form>
          </Card>
        </BlockWrapper>
      </React.Fragment>
    )
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; withSidebar: true; with... Remove this comment to see the full error message */}
      <PageLayout withSidebar withAppShell withOrgSwitcher refetch={refetch}>
        {account.isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          renderBody()
        )}
      </PageLayout>
    </>
  )
}

OrganizationsPage.propTypes = {
  refetch: PropTypes.func,
}

export default OrganizationsPage
