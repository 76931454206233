import React from 'react'
import PropTypes from 'prop-types'
import { Notice, Button, Link, Text } from '@bufferapp/ui'

import { useAccount } from '../../../../../../context/Account'

import * as Styles from './styles'
import {
  getGatewayStore,
  getAppsStoreLinks,
} from '../../../../../Billing/utils/planHelpers'

const openSubscriptionUpdate = (): void => {
  const { MODALS, actions } = window?.appshell || {}
  actions.openModal(MODALS.subscriptionUpdate, {
    cta: 'channelsConnect-channelLimitWarning-addChannels-1',
    upgradePathName: 'addChannel-upgrade',
    shouldPickModalOnOrganizationState: true,
  })
}

const redirectToBillingPage = (): void => {
  // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
  window.location = '/billing'
}

const ChannelLimitWarning = ({
  channelLimit,
  shouldShowBillingActions,
}: {
  channelLimit: number
  shouldShowBillingActions: boolean
}): JSX.Element => {
  const account = useAccount()
  const shouldDisplayMobileMessage = !shouldShowBillingActions
  const shouldDisplayActionButtons = shouldShowBillingActions
  const isOneBufferOrg = account?.currentOrganization?.isOneBufferOrganization
  const gatewayStore = getGatewayStore(account.currentOrganization)
  const appStoreLinks = getAppsStoreLinks(account.currentOrganization)
  return (
    <Styles.NoticeWrapper>
      {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
      <Notice type="warning" disableAnimation>
        <Styles.NoticeContent>
          <Text type="p">
            You’ve hit your plan limit of {channelLimit} connected channel
            {channelLimit !== 1 ? 's' : ''}.{' '}
            {shouldDisplayMobileMessage && (
              <Text type="p">
                Your subscription is managed through the{' '}
                <strong>{gatewayStore}</strong>. Open the{' '}
                {appStoreLinks.link ? (
                  <Link newTab href={appStoreLinks.link}>
                    {appStoreLinks.app}
                  </Link>
                ) : (
                  appStoreLinks.app
                )}{' '}
                app to add or remove channels.
              </Text>
            )}
          </Text>
          {shouldDisplayActionButtons && (
            // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; size: string;... Remove this comment to see the full error message
            <Button
              type="primary"
              label={isOneBufferOrg ? 'Add Channels' : 'Upgrade Plan'}
              size="small"
              onClick={(): void => {
                isOneBufferOrg
                  ? openSubscriptionUpdate()
                  : redirectToBillingPage()
              }}
            />
          )}
        </Styles.NoticeContent>
      </Notice>
    </Styles.NoticeWrapper>
  )
}

ChannelLimitWarning.propTypes = {
  channelLimit: PropTypes.number,
  shouldShowBillingActions: PropTypes.bool,
}

export default ChannelLimitWarning
