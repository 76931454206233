import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Hide, View } from '@bufferapp/ui/Icon'
import { Input } from '@bufferapp/ui'

import {
  InputPasswordStyled,
  InputPasswordWrapper,
  ShowPasswordToggle,
} from './style'

class PasswordField extends Component {
  state = {
    hidden: true,
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onClick = (e) => {
    e.preventDefault()
    const { hidden } = this.state
    this.setState({
      hidden: !hidden,
    })
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'showToggle' does not exist on type 'Read... Remove this comment to see the full error message
    const { showToggle, onChange, hasError, help, disabled } = this.props
    const { hidden } = this.state

    return (
      <InputPasswordStyled>
        <InputPasswordWrapper>
          <Input
            // @ts-expect-error TS(2322) FIXME: Type '{ id: string; type: string; label: string; o... Remove this comment to see the full error message
            id="newPassword"
            type={hidden ? 'password' : 'text'}
            label="Password"
            onChange={onChange}
            name="new-password"
            hasError={hasError}
            help={help}
            autoComplete="off"
            disabled={disabled}
          />
          {showToggle && (
            <ShowPasswordToggle type="button" onClick={this.onClick}>
              {hidden ? <View width="14" height="10" /> : <Hide height="14" />}
              {hidden ? <span>Show</span> : <span>Hide</span>}
            </ShowPasswordToggle>
          )}
        </InputPasswordWrapper>
      </InputPasswordStyled>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
PasswordField.propTypes = {
  showToggle: PropTypes.bool,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  help: PropTypes.string,
  disabled: PropTypes.bool,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
PasswordField.defaultProps = {
  showToggle: false,
  onChange: null,
  hasError: false,
  help: '',
  disabled: false,
}

export default PasswordField
