import { useMutation } from '@apollo/client'
import { REMOVE_ORGANIZATION_FROM_BETA_PROGRAM } from '../../../apollo/mutations/beta'

type BetaEnrollmentReturn = {
  removeOrganizationFromBetaProgram: () => void
}

export const useBetaDisenrollment = ({
  organizationId,
  isBetaProgramEnabled,
  setIsBetaProgramEnabled,
  setIsLoading,
  setErrorText,
  refetch,
}: {
  organizationId: string
  isBetaProgramEnabled: boolean
  setIsBetaProgramEnabled: (isBetaProgramEnabled: boolean) => void
  setIsLoading: (isLoading: boolean) => void
  setErrorText: (errorText: any) => void
  refetch: () => void
}): BetaEnrollmentReturn => {
  const errorMessage =
    "There's been an error removing you from our Beta program. Please try again or contact us for help."
  const [removeOrganizationFromBetaProgram] = useMutation(
    REMOVE_ORGANIZATION_FROM_BETA_PROGRAM,
    {
      variables: {
        organizationId,
      },
      onCompleted: (data) => {
        const isError =
          data?.removeOrganizationFromBetaProgram?.userFriendlyMessage
        setTimeout(() => {
          setIsLoading(false)
          if (isError) {
            setErrorText(errorMessage)
            return
          }
          setIsBetaProgramEnabled(!isBetaProgramEnabled)
        }, 1000)
        setTimeout(() => {
          const { actions } = window?.appshell || {}
          if (actions) {
            actions.refetchAccount()
          }
          refetch()
        }, 1500)
      },
      onError: () => {
        setErrorText(errorMessage)
        setIsLoading(false)
      },
    },
  )

  return {
    removeOrganizationFromBetaProgram,
  }
}
