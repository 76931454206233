import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import { white, grayDark, blue } from '@bufferapp/ui/style/colors'

export const Body = styled.div`
  padding: 0 16px;
  width: 100%;
  background-color: ${white};
  text-align: center;

  p {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
`

export const ModalHeader = styled.div`
  padding: 0 16px 16px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  h3 {
    margin: 0 auto;
    font-weight: 700;
  }
`

export const LargeIllustrationWrapper = styled.img`
  height: 212px;
`

export const StyledText = styled(Text)`
  color: ${grayDark};
`
export const IllustrationCloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  svg {
    fill: #b8b8b8;
  }
  position: absolute;
  top: 10px;
  right: 10px;
`

export const Link = styled.a`
  display: inline-flex;
  flex-direction: row;
  margin-left: 0.3rem;
  color: ${blue};
  cursor: pointer;
`
