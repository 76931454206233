export const MIGRATION_TABLE_ROW_CONTENT = [
  {
    category: 'Features',
    features: [
      {
        id: 'channelsYouActivate',
        name: 'Only pay for the channels you activate',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
      },
      {
        id: 'accessToNewChannels',
        name: 'Access to new channels like Youtube, TikTok, Mastodon, Threads, and Bluesky',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
      },
      {
        id: 'accessToTwitterThreads',
        name: 'Threaded posts on X, Threads and Bluesky',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
      },
      {
        id: 'accessToIdeas',
        name: 'Capture & organize content ideas with boards and tags',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
        tooltip:
          'Store all your best social media post ideas, tweak them until they’re ready, and drop them straight into your Buffer queue with Ideas.',
      },
      {
        id: 'linkedinFirstComment',
        name: 'Schedule PDF Carousels & first comments on LinkedIn',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
      },
      {
        id: 'accessToBufferAssistant',
        name: 'Buffer AI Assistant',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
        tooltip:
          'With the Buffer AI Assistant you can generate ideas in just a click and repurpose your existing content dozens of times, helping you grow your engagement accross all socials platforms.',
      },
      {
        id: 'accessToAnalytics',
        name: 'Analytics included on all plans',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
        tooltip:
          'Access to our analytics features required a separate subscription (and additional cost) on the legacy version of Buffer. With the latest version of Buffer, all features are included for one price',
      },
      {
        id: 'accessToEngagement',
        name: 'Reply to audience comments on Facebook and Instagram',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
      },
      {
        id: 'unlimitedTeamMemeber',
        name: 'Unlimited team members (on our team and agency plans)',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
      },
      {
        id: 'accessToOurBetaProgram',
        name: 'Access to Buffer Open Beta',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
      },
      {
        id: 'accessToAllNewFeatures',
        name: 'Access to upcoming features guaranteed',
        YOUR_PLAN: false,
        LATEST_VERSION: true,
      },
    ],
  },
]

export const PLANS_FOR_COLUMNS = [
  {
    planId: 'YOUR_PLAN',
    planName: 'Your Plan',
    description: 'This is a legacy version of Buffer',
    hasCTA: false,
  },
  {
    planId: 'LATEST_VERSION',
    planName: 'Latest Version of Buffer',
    description: 'Update to unlock all features',
    hasCTA: true,
  },
]
