import React from 'react'
import PropTypes from 'prop-types'
import CentralizedBilling from '../../../../centralized-billing'
import { PageLayout, TitleWrapper } from '../../../../account-components'
import { Text } from '@bufferapp/ui'

import OneBufferBillingPage from './OneBufferBillingPage'

import { generateCreditCardBanner } from './utils/generateCreditCardBanner'

import { ContentWrapper } from './style'
import { useAccount } from '../../context/Account'

const Billing = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'activeProducts' implicitly has an... Remove this comment to see the full error message
  activeProducts,
  // @ts-expect-error TS(7031) FIXME: Binding element 'billing' implicitly has an 'any' ... Remove this comment to see the full error message
  billing,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openCreditCardFormModal' implicit... Remove this comment to see the full error message
  openCreditCardFormModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'refetch' implicitly has an 'any' ... Remove this comment to see the full error message
  refetch,
}) => {
  const account = useAccount()
  const { currentOrganization } = account

  const bannerOptions = generateCreditCardBanner({
    orgDetails: billing.selectedOrganizationDetails,
    cta: openCreditCardFormModal,
    canEdit: currentOrganization && currentOrganization.canEdit,
  })

  return (
    <PageLayout
      withAppShell
      withOrgSwitcher
      withSidebar
      bannerOptions={bannerOptions}
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; withAppShell: true; wit... Remove this comment to see the full error message
      refetch={refetch}
    >
      <ContentWrapper>
        <TitleWrapper>
          <Text type="h1">Billing</Text>
        </TitleWrapper>
        {currentOrganization?.isOneBufferOrganization ? (
          <OneBufferBillingPage refetch={refetch} />
        ) : (
          <CentralizedBilling
            // @ts-expect-error
            activeProducts={activeProducts}
            loading={loading}
            account={account}
          />
        )}
      </ContentWrapper>
    </PageLayout>
  )
}

Billing.propTypes = {
  activeProducts: PropTypes.arrayOf(PropTypes.string),
  billing: PropTypes.object,
  loading: PropTypes.bool,
  openCreditCardFormModal: PropTypes.func,
  refetch: PropTypes.func,
}

Billing.defaultProps = {
  activeProducts: [],
  loading: false,
}

export default Billing
