import React from 'react'
import { Button, Select } from '@bufferapp/ui'

import {
  FacebookHelpItems,
  GenericHelpItems,
  InstagramHelpItems,
  type SupportHelpItem,
} from '../../ChannelSelection/constants'
import { ChannelThirdPartyService } from '../../../../../../../channel-connections/interfaces'

import * as Styled from './styles'
import { ChevronDown } from '@bufferapp/ui/Icon'

function getHelpLinks(
  serviceName: ChannelThirdPartyService,
): SupportHelpItem[] {
  switch (serviceName) {
    case ChannelThirdPartyService.instagram:
      return [...InstagramHelpItems, ...GenericHelpItems]
    case ChannelThirdPartyService.facebook:
      return [...FacebookHelpItems, ...GenericHelpItems]

    default:
      return GenericHelpItems
  }
}

export function SupportHelpDropdown({
  serviceName,
}: {
  serviceName: ChannelThirdPartyService
}): JSX.Element {
  return (
    <Styled.SelectWrapper data-testid="tag-selector" hasSelectedTags={true}>
      <Select
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        onSelectClick={(selectedItem): void => selectedItem.selectedItemClick()}
        label={'Need Help?'}
        capitalizeItemLabel={false}
        items={getHelpLinks(serviceName)}
        size="medium"
        customButton={(onButtonClick: () => void): JSX.Element => (
          // @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message
          <Button
            label={'Need Help?'}
            icon={<ChevronDown />}
            iconEnd
            onClick={(event: React.FormEvent<HTMLFormElement>): void => {
              event.preventDefault()
              onButtonClick()
            }}
            type="secondary"
            disabled={false}
          />
        )}
      />
    </Styled.SelectWrapper>
  )
}
