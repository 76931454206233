import styled from 'styled-components'

export const SelectWrapper = styled.div<{ hasSelectedTags: boolean }>`
  display: flex;

  div[class*='style__SelectStyled'] {
    max-width: 395px;
    width: 395px;
    &:before {
        display: none;
      }
    }
  }

  p {
    margin-left: 0 !important;
  }

  p.noResultsMessage {
    margin-left: auto !important;
  }
`
