import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const InputWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
`

export const InputInlineWrapper = styled.div`
  width: 68%;
`

export const CopyButtonWrapper = styled.div`
  margin-top: 8px;
`

const LoaderWrapper = styled.div`
  margin: 6rem 0;
`

export const CardFooter = styled.div`
  display: flex;
  padding: 8px 16px;
  border-top: 1px solid #e8e8e8;

  div {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`
export const FooterTextWrapper = styled.div`
  color: ${grayDarker};
  p {
    font-size: 12px;
  }
`
