import { HC_UTM_PARAMS } from '../../../../../shared-utils/constants'

export const CHANNELS = [
  {
    service: 'YouTube',
    description: 'Channel',
    showNewTag: true,
    cta: 'Connect',
  },
  {
    service: 'Mastodon',
    description: 'Profile',
    showNewTag: true,
    cta: 'Connect',
  },
  {
    service: 'Start Page',
    description: 'Simple, powerful link-in-bio with embedded status updates',
    cta: 'Create',
  },
  {
    service: 'TikTok',
    description: 'Business account',
    faq: {
      url: `https://support.buffer.com/article/559-using-tiktok-with-buffer?${HC_UTM_PARAMS}#01H85RV55S1DNACQ1PEY9Y71WG`,
      text: 'Learn more →',
    },
    cta: 'Connect',
  },
  {
    service: 'Google Business Profile',
    description: 'Account',
    cta: 'Connect',
  },
  {
    service: 'Instagram',
    description: 'Business or Creator accounts',
    faq: {
      url: `https://support.buffer.com/article/565-troubleshooting-facebook-and-instagram-connections?${HC_UTM_PARAMS}#h_01HBEFDCSJ6GDNVG23SNCVRWP2`,
      text: 'Learn more →',
    },
    cta: 'Connect',
  },
  {
    service: 'Facebook',
    description: 'Page or Group',
    cta: 'Connect',
  },
  {
    service: 'Twitter',
    description: 'Profile',
    cta: 'Connect',
  },
  {
    service: 'LinkedIn',
    description: 'Page or Profile',
    cta: 'Connect',
  },
  {
    service: 'Pinterest',
    description: 'Business',
    cta: 'Connect',
  },
  {
    service: 'Shopify',
    description: 'Store',
    cta: 'Connect',
  },
  {
    service: 'Threads',
    description: 'Profile',
    cta: 'Connect',
  },
]

export const ERROR_CODES = {
  CHANNEL_IS_NOT_BUSINESS: '6015',
}
