import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'

import * as Styles from './styles'
import Button from '@bufferapp/ui/Button'

const PlanItem = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'icon' implicitly has an 'any' typ... Remove this comment to see the full error message
  icon,
  // @ts-expect-error TS(7031) FIXME: Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(7031) FIXME: Binding element 'description' implicitly has an 'a... Remove this comment to see the full error message
  description,
  // @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
  value,
  // @ts-expect-error TS(7031) FIXME: Binding element 'extraInfo' implicitly has an 'any... Remove this comment to see the full error message
  extraInfo,
  // @ts-expect-error TS(7031) FIXME: Binding element 'extraCTACopy' implicitly has an '... Remove this comment to see the full error message
  extraCTACopy,
  // @ts-expect-error TS(7031) FIXME: Binding element 'extraCTAOnClick' implicitly has a... Remove this comment to see the full error message
  extraCTAOnClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isOnTrial' implicitly has an 'any... Remove this comment to see the full error message
  isOnTrial,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasCreditCard' implicitly has an ... Remove this comment to see the full error message
  hasCreditCard,
  isTwoColumn = false,
}) => {
  return (
    <Styles.PlanItem isTwoColumn={isTwoColumn}>
      <Styles.IconWrapper>{icon}</Styles.IconWrapper>
      <Text type="p">
        <strong>{title}</strong>
      </Text>
      <Text type="p" color="grayDark">
        {description}
      </Text>
      <Styles.ValueWrapper>
        {!hasCreditCard && isOnTrial ? (
          <Styles.Warning>
            Please add a payment method below to see upcoming bill
          </Styles.Warning>
        ) : (
          <Text type="h3">{value}</Text>
        )}
      </Styles.ValueWrapper>
      <Styles.ExtraInfoWrapper hasExtraInfo={!!extraInfo}>
        <Text type="p" color="red">
          {extraInfo}
        </Text>
        {extraCTACopy && !isTwoColumn && (
          // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: any; onClick: any; fu... Remove this comment to see the full error message
          <Button
            type="primary"
            label={extraCTACopy}
            onClick={extraCTAOnClick}
            fullWidth
          />
        )}
      </Styles.ExtraInfoWrapper>
    </Styles.PlanItem>
  )
}

PlanItem.propTypes = {
  extraCTACopy: PropTypes.string,
  extraCTAOnClick: PropTypes.func,
  extraInfo: PropTypes.string,
  icon: PropTypes.node,
  isTwoColumn: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalItems: PropTypes.number,
  isOnTrial: PropTypes.bool,
  hasCreditCard: PropTypes.bool,
}

export default PlanItem
