import { ConnectionAvailability } from '../../../../hooks/channel-connections/types'

export function getChannelConnectionAvailabilityCopy(
  connectionAvailability: ConnectionAvailability,
): string {
  switch (connectionAvailability) {
    case ConnectionAvailability.alreadyConnected:
      return 'Connected'
    case ConnectionAvailability.unavailable:
      return 'Unavailable'
    case ConnectionAvailability.available:
      return ''

    default:
      return ''
  }
}
