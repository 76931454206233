import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TitleWrapper } from '../../../../account-components'
import { Text } from '@bufferapp/ui'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'

import { FAQData } from './data/faq'
import { FeatureImageStar } from './data/feature-image-star'

import PlanCalculatorInputSection from './components/PlanCalculatorInputSection'
import PlanCard from './components/PlanCard'
import Accordion from './components/Accordion'

import { useSplitEnabled } from '@bufferapp/features'
import {
  PageWrapper,
  BackgroundWrapper,
  ContentWrapper,
  CenterLayoutWrapper,
  Notice,
  InputSectionWrapper,
  BodySectionWrapper,
  FeatureImageWrapper,
  FeatureImageText,
  FeatureImage,
  PlansContactWrapper,
  ContactWrapper,
  SupportLink,
} from './style'
import { useAccount } from '../../context/Account'
import ContactAdvocacy from './components/ContactAdvocacy'
import { usePlanPriceCalculator } from './hooks/usePlanPriceCalculator'
import { createPlanCardsList } from './utils/plans'
import ComparisonTable from './components/ComparisonTable'
import CompanyLogos from './components/CompanyLogos'

import {
  getCustomerDiscount,
  getCustomerDiscountId,
} from './utils/getCustomerDiscount'
import { getCustomerTeamMemberCount } from './utils/getCustomerTeamMemberCount'
import { getCustomerChannelCount } from './utils/getCustomerChannelCount'
import {
  getCustomerBillingBreakdown,
  getTotalLegacyPriceByInterval,
} from './utils/getCustomerBillingBreakdown'
import { getUserChannelCount } from '../../../../shared-utils'
import { HelpScoutBeacon } from '../../../../helpScoutBeacon'

const CHANNELS_INPUT_MINIMUM_VALUE = 1
const AGENCY_MINIMUM_CHANNELS = 10
const DEFAULT_TEAM_MEMEBERS_VALUE = 'one'
const DEFAULT_INTERVAL_VALUE = 'month'

export function shouldBeDisabled(
  // @ts-expect-error TS(7006) FIXME: Parameter 'channelsInputValue' implicitly has an '... Remove this comment to see the full error message
  channelsInputValue,
  // @ts-expect-error TS(7006) FIXME: Parameter 'selectedTeamMembers' implicitly has an ... Remove this comment to see the full error message
  selectedTeamMembers,
  // @ts-expect-error TS(7006) FIXME: Parameter 'planName' implicitly has an 'any' type.
  planName,
) {
  switch (planName) {
    case 'essentials':
      return selectedTeamMembers !== DEFAULT_TEAM_MEMEBERS_VALUE
    case 'team':
      return false
    case 'agency':
      return channelsInputValue < AGENCY_MINIMUM_CHANNELS
    default:
      return false
  }
}

export function shouldBeHighligted(
  // @ts-expect-error TS(7006) FIXME: Parameter 'channelsInputValue' implicitly has an '... Remove this comment to see the full error message
  channelsInputValue,
  // @ts-expect-error TS(7006) FIXME: Parameter 'selectedTeamMembers' implicitly has an ... Remove this comment to see the full error message
  selectedTeamMembers,
  // @ts-expect-error TS(7006) FIXME: Parameter 'planName' implicitly has an 'any' type.
  planName,
) {
  switch (planName) {
    case 'essentials':
      return selectedTeamMembers === DEFAULT_TEAM_MEMEBERS_VALUE
    case 'team':
      return (
        selectedTeamMembers !== DEFAULT_TEAM_MEMEBERS_VALUE &&
        channelsInputValue < 10
      )
    case 'agency':
      return (
        channelsInputValue >= 10 &&
        selectedTeamMembers !== DEFAULT_TEAM_MEMEBERS_VALUE
      )
    default:
      return false
  }
}

const NewPlans = () => {
  const account = useAccount()
  const { currentOrganization } = account

  const [channelsInputValue, setChannelsInputValue] = useState(
    CHANNELS_INPUT_MINIMUM_VALUE,
  )

  const [teamMemberSelected, setTeamMembersSelected] = useState(
    DEFAULT_TEAM_MEMEBERS_VALUE,
  )
  const [intervalSelected, setIntervalSelected] = useState(
    DEFAULT_INTERVAL_VALUE,
  )

  const plans = createPlanCardsList()

  const { listOfCalculatedPricing, getCalculatedPricingForPlan } =
    usePlanPriceCalculator(channelsInputValue)

  const canMigrate = currentOrganization?.canMigrateToBufferViaHub?.canMigrate
  const reasons = currentOrganization?.canMigrateToBufferViaHub?.reasons || []

  const discountId = getCustomerDiscountId(currentOrganization)
  const { isEnabled: isLegacyMigrationEligible } = useSplitEnabled(
    'legacy-migration-eligibility',
  )

  const showMigrationPlanSelector = canMigrate && isLegacyMigrationEligible

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  function handleChannelInput(value) {
    return setChannelsInputValue(value)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  function handleTeamMemeberInput(value) {
    return setTeamMembersSelected(value)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  function handleIntervalInput(value) {
    setIntervalSelected(value)
  }

  const customerTeamMemberCount =
    getCustomerTeamMemberCount(currentOrganization)
  const customerTeamMemberCountText =
    customerTeamMemberCount > 1
      ? `${customerTeamMemberCount} users`
      : `${customerTeamMemberCount} user`
  const customerChannelCount = getCustomerChannelCount(currentOrganization)
  const customerChannelCountText =
    customerChannelCount > 1
      ? `${customerChannelCount} channels`
      : `${customerChannelCount} channel`

  const customerDiscount = getCustomerDiscount(currentOrganization)

  const planPricingBreakdown = getCustomerBillingBreakdown(
    currentOrganization,
    customerDiscount,
  )

  const legacyPlanIntervalPrice = getTotalLegacyPriceByInterval(
    currentOrganization,
    intervalSelected,
  )

  const cta = new URLSearchParams(window.location.search).get('cta') || ''

  // Set starting values to match current user
  const connectedChannelCount = getUserChannelCount(account)
  const startingChannelCount =
    connectedChannelCount === 0
      ? CHANNELS_INPUT_MINIMUM_VALUE
      : connectedChannelCount

  const startingTeamMemberCount = customerTeamMemberCount === 1 ? 'one' : 'more'

  const startingInterval = planPricingBreakdown[0]?.cycle || 'month'

  useEffect(() => {
    currentOrganization &&
      BufferTracker.migrationPageViewed({
        organizationId: currentOrganization.id,
        canMigrate: showMigrationPlanSelector,
        reasons,
        discountId,
        cta,
      })
    setChannelsInputValue(startingChannelCount)
    setTeamMembersSelected(startingTeamMemberCount)
    setIntervalSelected(startingInterval)
  }, [currentOrganization])

  return (
    <PageWrapper>
      <BackgroundWrapper withGradient>
        <ContentWrapper>
          <TitleWrapper>
            <CenterLayoutWrapper>
              <Notice>You&apos;re currently on a Legacy Buffer plan</Notice>
              <Text type="h2">Unlock All The Latest Buffer Features</Text>
              <Text type="p">
                You’re on a Legacy Buffer plan. That means you’re missing out on
                a number of features and benefits. Switch over to the newest
                version of Buffer today!
              </Text>
            </CenterLayoutWrapper>
          </TitleWrapper>
          <ComparisonTable />
        </ContentWrapper>
      </BackgroundWrapper>
      <BackgroundWrapper>
        <ContentWrapper id="plan-selection">
          {showMigrationPlanSelector ? (
            <InputSectionWrapper>
              <PlanCalculatorInputSection
                initialChannelsInputValue={`${startingChannelCount}`}
                teamMembersSelected={teamMemberSelected}
                initialTeamMembersValue={customerTeamMemberCount}
                channelsInputMinimumValue={CHANNELS_INPUT_MINIMUM_VALUE}
                intervalSelected={intervalSelected}
                handleChannelInput={(value: any) => handleChannelInput(value)}
                handleTeamMemeberInput={(value: any) =>
                  handleTeamMemeberInput(value)
                }
                handleIntervalInput={(value: any) => handleIntervalInput(value)}
              />
              <BodySectionWrapper>
                {' '}
                <PlanCard
                  key={'your-plan'}
                  planName={'Current Plan'}
                  description={'You are on a Legacy Buffer plan.'}
                  details={[
                    {
                      isHighlighted: false,
                      text: `${customerChannelCountText}`,
                    },
                    {
                      isHighlighted: false,
                      text: `${customerTeamMemberCountText}`,
                    },
                  ]}
                  isHighlighted={false}
                  isDisabled={false}
                  planPricing={legacyPlanIntervalPrice}
                  discount={customerDiscount}
                  planPricingBreakdown={planPricingBreakdown}
                  planInterval={intervalSelected}
                  selectedChannelQuantity={channelsInputValue}
                  isNormal
                  hasCTA={false}
                />
                {plans.map((plan) => {
                  return (
                    <PlanCard
                      key={plan.planName}
                      planName={plan.planName}
                      description={plan.description}
                      details={plan.details}
                      isHighlighted={shouldBeHighligted(
                        channelsInputValue,
                        teamMemberSelected,
                        plan.planName,
                      )}
                      isDisabled={shouldBeDisabled(
                        channelsInputValue,
                        teamMemberSelected,
                        plan.planName,
                      )}
                      planPricing={getCalculatedPricingForPlan(
                        plan.planName,
                        intervalSelected,
                        listOfCalculatedPricing,
                      )}
                      discount={customerDiscount}
                      legacyPlanPrice={legacyPlanIntervalPrice}
                      planInterval={intervalSelected}
                      selectedChannelQuantity={channelsInputValue}
                    />
                  )
                })}
              </BodySectionWrapper>
              <BodySectionWrapper>
                <PlansContactWrapper>
                  <p>We are here to help if you have any questions!</p>
                  <SupportLink
                    onClick={(): void => {
                      HelpScoutBeacon.openLegacyMigrationForm()
                    }}
                  >
                    Contact support
                  </SupportLink>{' '}
                </PlansContactWrapper>
              </BodySectionWrapper>
            </InputSectionWrapper>
          ) : (
            <CenterLayoutWrapper>
              <ContactAdvocacy />
            </CenterLayoutWrapper>
          )}
        </ContentWrapper>
      </BackgroundWrapper>
      <BackgroundWrapper>
        <ContentWrapper>
          <Accordion heading={'Frequently Asked Questions'} items={FAQData} />
          <ContactWrapper>
            <p>Still have more questions?</p>
            <SupportLink
              onClick={(): void => {
                HelpScoutBeacon.openLegacyMigrationForm()
              }}
            >
              Contact support
            </SupportLink>{' '}
          </ContactWrapper>
        </ContentWrapper>
      </BackgroundWrapper>
      <BodySectionWrapper>
        <CompanyLogos />
      </BodySectionWrapper>
      <FeatureImageWrapper>
        <FeatureImageText>
          <span>
            <FeatureImageStar />
          </span>
          <Text type="h2">You’re missing all these features</Text>
        </FeatureImageText>
        <FeatureImage />
      </FeatureImageWrapper>
    </PageWrapper>
  )
}

NewPlans.propTypes = {
  refetch: PropTypes.func,
}

NewPlans.defaultProps = {
  loading: false,
}

export default NewPlans
