import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { reducer as asyncDataFetchReducer } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { reducer as notificationsReducer } from '@bufferapp/notifications'
import { reducer as environmentReducer } from '../environment'
import accountReducer from '../web/src/reducer'
import { reducer as settingsReducer } from '../settings'
import { reducer as billing } from '../billing-details'
import { reducer as channelList } from '../channel-list'
import { reducer as deleteAccount } from '../delete-account'
import { reducer as cancelPlan } from '../cancel-plan'
import { reducer as centralizedBilling } from '../centralized-billing'
import { reducer as creditCard } from '../credit-card'

// @ts-expect-error TS(7006) FIXME: Parameter 'history' implicitly has an 'any' type.
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    asyncDataFetch: asyncDataFetchReducer,
    notifications: notificationsReducer,
    environment: environmentReducer,
    account: accountReducer,
    settings: settingsReducer,
    billing,
    channelList,
    deleteAccount,
    cancelPlan,
    centralizedBilling,
    creditCard,
  })
