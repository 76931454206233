import { useQuery } from '@apollo/client'
import { REFERRAL_CODE } from './queries'

const useReferralCode = (organizationId: string) => {
  const { data, loading, error } = useQuery(REFERRAL_CODE, {
    variables: {
      organizationId,
    },
  })

  return {
    data,
    loading,
    error,
  }
}

export default useReferralCode
