import { useMutation } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'

import { CONNECT_CHANNELS } from '../../../apollo/mutations/channels'
import type {
  RequestConnectChannelsArgs,
  UseConnectChannelsArgs,
  UseConnectChannelsReturn,
} from './types'
import { useAccount } from '../../../context/Account'
import {
  checkOauthStateTokensMatch,
  getConnectedChannelsIds,
  isNewConnection,
  mapDataToChannelWithCredentials,
} from '../utils'
import type {
  ChannelWithCredentialsInput,
  ConnectableChannelData,
} from '../types'
import { GET_ACCOUNT } from '../../../apollo/queries/account'

export default function useConnectChannels(
  args?: UseConnectChannelsArgs,
): UseConnectChannelsReturn {
  const isMountedRef = useRef(true)
  const account = useAccount()
  const [connectedChannels, setConnectedChannels] = useState(undefined)
  const [error, setError] = useState<string | undefined>(undefined)

  const [requestConnectChannels, { loading }] = useMutation(CONNECT_CHANNELS, {
    onCompleted(data) {
      if (isMountedRef.current) {
        const { channels } = data.channelsConnectWithResponse
        setConnectedChannels(channels)

        if (data?.channelsConnectWithResponse?.userFriendlyMessage) {
          setError(data?.channelsConnectWithResponse?.userFriendlyMessage)
          if (args?.onFailure) {
            args.onFailure(data?.channelsConnectWithResponse?.cause)
          }
        }

        const newConnection = isNewConnection(
          data?.channelsConnectWithResponse?.channels,
        )

        if (args?.onSuccess) {
          const channelIds = getConnectedChannelsIds(channels)
          args.onSuccess(channelIds, newConnection)
        }
      }
    },
    refetchQueries: [{ query: GET_ACCOUNT }],
    context: {
      headers: {
        'x-buffer-domain': 'channel-authorization',
        'x-buffer-service': args?.service,
      },
    },
  })

  // Cleanup: Ensure we don't trigger a requiest/update if the component is unmounting
  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  function handleRequestConnectChannels(
    args: RequestConnectChannelsArgs,
  ): void {
    const orgId = account?.currentOrganization?.id

    // We need to convert or channel data received from useRequestAvailableChannelsForAccount
    // into an Object structure which the BE wants, we include information about the orgId
    const channelsToConnect = args.selectedChannels.map(
      (rawChannel: ConnectableChannelData): ChannelWithCredentialsInput =>
        mapDataToChannelWithCredentials({
          channel: rawChannel,
          account,
          service: args.service,
        }),
    )

    // We wont call the mutation if our state tokens for oauth dont match
    // This is because something in the middle of the oauth process may have gone wrong
    const shouldRequestConnectChannels =
      checkOauthStateTokensMatch(args.service) &&
      orgId &&
      channelsToConnect.length > 0

    if (shouldRequestConnectChannels) {
      requestConnectChannels({
        variables: {
          channels: channelsToConnect,
        },
      })
    }
  }

  return {
    connectedChannels,
    handleRequestConnectChannels: (args: RequestConnectChannelsArgs) =>
      handleRequestConnectChannels(args),
    isRequestConnectChannelsLoading: loading,
    requestConnectChannelsErrorMessage: error,
  }
}
