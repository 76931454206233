import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text, Button } from '@bufferapp/ui'
import { gray } from '@bufferapp/ui/style/colors'
import { ActivateTrial } from './ActivateTrial'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'

export const ProductCard = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 28px;
  gap: 8px;

  width: 100%;
  height: 100%;

  background: #ffffff;

  border: 1px solid ${gray};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  p {
    margin-bottom: 8px;
  }
`
type ProductButtonProps = {
  productLogo: React.ReactNode
  product: string
  productId: string
  children: React.ReactNode
  onClick: () => void
  organizationId: string
  cta: string
  buttonType: string
}

const ProductButton = ({
  product,
  productId,
  productLogo,
  onClick,
  children,
  cta,
  organizationId,
  buttonType,
}: ProductButtonProps): JSX.Element => {
  useEffect(() => {
    /* we want to check if the button for starting a trial is rendered all the times */
    if (cta === 'Start 14-day trial') {
      BufferTracker.ctaViewed({
        organizationId,
        cta: 'onboarding-productSelector-analyze-startTrial-1',
        upgradePathName: 'onboarding-analyze-startTrial',
        product: 'account',
      })
    }
  }, [])

  return (
    <ProductCard data-testid={`select-button-${productId}`}>
      {productLogo}
      <Text type="h3">{product}</Text>
      <Text type="p">{children}</Text>
      {cta === 'Start 14-day trial' ? (
        <ActivateTrial />
      ) : (
        // @ts-expect-error TS(2740) FIXME: Type '{ onClick: any; label: any; type: any; }' is... Remove this comment to see the full error message
        <Button
          data-testid={`button-cta-${productId}`}
          onClick={onClick}
          label={cta}
          type={buttonType}
        />
      )}
    </ProductCard>
  )
}

ProductButton.propTypes = {
  productLogo: PropTypes.object.isRequired,
  product: PropTypes.string,
  productId: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  cta: PropTypes.string,
  buttonType: PropTypes.string,
}

export default ProductButton
