type Product = {
  id: string
  name: string
  description: string
  buttonType: 'primary' | 'secondary'
  logo: {
    image: string
    alt: string
  }
  cta: string
}

export const getProducts = (canActivateTrial: boolean): Product[] => {
  return [
    {
      id: 'publishing',
      name: 'Grow your audience',
      description: 'Schedule posts to build your audience',
      buttonType: 'primary',
      logo: {
        image:
          'https://buffer-ui.s3.amazonaws.com/products/publish-card_%402x.jpeg',
        alt: 'Publish',
      },
      cta: 'Connect Channels',
    },
    {
      id: 'startpage',
      name: 'Build your Start Page',
      buttonType: 'secondary',
      description: 'Create a simple page for your business',
      logo: {
        image:
          'https://buffer-ui.s3.amazonaws.com/products/start-page-card_%402x.jpeg',
        alt: 'Build your Start Page',
      },
      cta: 'Create a Start Page',
    },
    {
      id: 'analytics',
      name: 'Analyze your content',
      buttonType: 'secondary',
      description: 'Analyze your content with one click',
      logo: {
        image:
          'https://buffer-ui.s3.amazonaws.com/products/analyze-card_%402x.jpeg',
        alt: 'Analyze your content',
      },
      cta: canActivateTrial ? 'Start 14-day trial' : 'Connect Channels',
    },
  ]
}
