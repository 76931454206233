import styled from 'styled-components'
import { grayDark, grayDarker } from '@bufferapp/ui/style/colors'

export const LoaderWrapper = styled.div`
  margin: 6rem 0;
`

export const TextWrapper = styled.div`
  display: flex;
  color: ${grayDarker};
  flex-direction: column;
  align-items: center;
  p {
    padding-bottom: 12px;
  }
`

export const FooterTextWrapper = styled.div`
  color: ${grayDarker};
  p {
    font-size: 12px;
  }
`

export const ActionButtonWrapper = styled.div`
  margin: 8px 0;
`

export const AlphaFeaturesWrapper = styled.div`
  padding: 8px 0;
`

export const AlphaFeature = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const AlphaFeatureTextWrapper = styled.div`
  max-width: 70%;
  .featureTitle {
    font-weight: bold;
    color: ${grayDarker};
  }
  .featureDescription {
    margin-top: 4px;
    color: ${grayDark};
  }
`

export const AlphaFeatureButtonWrapper = styled.div`
  height: 30px;
`

export const NoAlphaFeaturesText = styled.div`
  font-style: italic;
`

export const AlphaInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    padding-left: 8px;
  }
  svg {
    color: ${grayDark};
  }
`

export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid #e8e8e8;

  div {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`
