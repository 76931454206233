import styled from 'styled-components'

import {
  grayDarker,
  grayLighter,
  grayLight,
  white,
} from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  background: ${white};
  border-top: 1px solid ${grayLight};
`

export const Body = styled.div`
  padding: 24px;
  border-radius: 0 0 4px 4px;

  h4 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  ul {
    margin: 0;
    padding: 0;
    width: 360px;
    margin: 8px 0 32px;

    &:last-of-type {
      margin: 8px 0 0;
    }
  }

  li {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 8px;
    align-items: flex-start;

    svg {
      margin-right: 8px;
    }
  }
`

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 270px; */
  padding: 24px;
`

export const DataSection = styled.div`
  margin-bottom: 32px;
`

export const TagGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Tag = styled.span`
  background: ${grayLighter};
  border-radius: 4px;
  padding: 4px 8px;
  margin: 4px 4px 0 0;
  color: ${grayDarker};
  font-size: 14px;
`
