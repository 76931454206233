const baseImageURL = 'https://static.buffer.com/images/account'

export const COMPANY_LOGOS = [
  {
    imageURL: `${baseImageURL}/migration-hub-Dribbble-X2.png`,
    alt: 'dribble company logo',
    name: 'dribble',
    width: 116,
  },
  {
    imageURL: `${baseImageURL}/migration-hub-Burrow-X2.png`,
    alt: 'burrow company logo',
    name: 'burrow',
    width: 146,
  },
  {
    imageURL: `${baseImageURL}/migration-hub-Huckberry-X2.png`,
    alt: 'huckberry company logo',
    name: 'huckberry',
    width: 170,
  },
  {
    imageURL: `${baseImageURL}/migration-hub-HappySocks-X2.png`,
    alt: 'happy socks company logo',
    name: 'happy socks',
    width: 88,
  },
  {
    imageURL: `${baseImageURL}/migration-hub-Food52-X2.png`,
    alt: 'food52 company logo',
    name: 'food52',
    width: 117,
  },
]
