import React from 'react'
import { ConnectionIconWrapper, ConnectionWrapper } from './styles'
import BufferIcon from '@bufferapp/ui/Icon/Icons/Buffer'
import ConnectionSVG from './ConnectionSVG'
import { SocialIcon } from '../../components/SocialIcon'
import type { Service } from '../../../../../../channel-connections/interfaces'

interface ConnectionHeaderProps {
  service: Service
}
export const ConnectionHeader = ({
  service,
}: ConnectionHeaderProps): JSX.Element => {
  return (
    <ConnectionWrapper data-testid="connection-header">
      <BufferIcon size="large" color={null} />
      <ConnectionIconWrapper>
        <ConnectionSVG />
      </ConnectionIconWrapper>
      <SocialIcon serviceName={service} size="large" useColor={true} />
    </ConnectionWrapper>
  )
}
