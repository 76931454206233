import React from 'react'
import PropTypes from 'prop-types'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'

import { DetailsWrapper, LineWrapper } from './style'

// @ts-expect-error TS(7006) FIXME: Parameter 'isIncluded' implicitly has an 'any' typ... Remove this comment to see the full error message
function renderDetailIcon(isIncluded) {
  return isIncluded ? <CheckmarkIcon /> : <CrossIcon />
}

const PlanCardDetails = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'details' implicitly has an 'any' ... Remove this comment to see the full error message
  details,
}) => {
  return (
    <DetailsWrapper>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type. */}
      {details.map((item, index) => (
        <LineWrapper
          key={`${item.text}-${index}`}
          isIncluded={item.isIncluded}
          isHighlighted={item.isHighlighted}
        >
          {renderDetailIcon(item.isIncluded)}
          <span>{item.text}</span>
        </LineWrapper>
      ))}
    </DetailsWrapper>
  )
}

PlanCardDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      isIncluded: PropTypes.bool.isRequired,
      isHighlighted: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

PlanCardDetails.defaultProps = {
  details: [],
}

export default PlanCardDetails
