import gql from 'graphql-tag'

export const ACCOUNT_INITIATE_EMAIL_VERIFICATION = gql`
  mutation AccountInitiateEmailVerification {
    accountInitiateEmailVerification {
      ... on AccountInitiateEmailVerificationResponse {
        success
      }
      ... on AccountInitiateEmailVerificationError {
        userFriendlyMessage
      }
    }
  }
`
