import {
  type ConnectableChannelData,
  ConnectionAvailability,
} from '../../../../hooks/channel-connections/types'

export function isChannelSelected(
  channel: ConnectableChannelData,
  selectedChannels: ConnectableChannelData[],
): boolean {
  return selectedChannels.includes(channel)
}

export function getOnlyAvailableChannels(
  channels: ConnectableChannelData[],
): ConnectableChannelData[] {
  return channels.filter(
    (channel) =>
      channel.connectionAvailability === ConnectionAvailability.available,
  )
}

export function shouldAutoSelectChannel(
  availableChannels: ConnectableChannelData[],
): boolean {
  const isOnlyOneChannelAvailable = availableChannels.length === 1

  if (!isOnlyOneChannelAvailable) return false

  const isChannelAvailable =
    availableChannels[0].connectionAvailability ===
    ConnectionAvailability.available

  return isOnlyOneChannelAvailable && isChannelAvailable
}
