import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'
import CaretDownIcon from '@bufferapp/ui/Icon/Icons/CaretDown'
import CaretUpIcon from '@bufferapp/ui/Icon/Icons/CaretUp'

import {
  AccordionSectionWrapper,
  Label,
  SectionContentWrapper,
  SectionContent,
} from './style'

const AccordionSection = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  label,
  // @ts-expect-error TS(7031) FIXME: Binding element 'content' implicitly has an 'any' ... Remove this comment to see the full error message
  content,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  function handleOnClick() {
    return setIsOpen(!isOpen)
  }

  return (
    <AccordionSectionWrapper>
      <Label onClick={() => handleOnClick()}>
        <Text type="h3">{label}</Text>
        {isOpen ? <CaretDownIcon /> : <CaretUpIcon />}
      </Label>
      <SectionContentWrapper>
        <SectionContent isOpen={isOpen}>{content}</SectionContent>
      </SectionContentWrapper>
    </AccordionSectionWrapper>
  )
}

AccordionSection.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
}

AccordionSection.defaultProps = {}

export default AccordionSection
