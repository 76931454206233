import styled, { css } from 'styled-components'
import { ButtonBase, secondary, small } from '@bufferapp/ui/Button/style'
import { white, blueDarker } from '../../styles'

const dark = css`
  background-color: ${blueDarker};
  border: 1px solid ${blueDarker};
  color: ${white};

  &:hover {
    background-color: ${blueDarker};
    border: 1px solid ${blueDarker};
    color: ${white};
  }
`

// NOTE: A "ButtonLink" component is going to be
// added to the @bufferapp/ui library soon. This should
// be replaced by that component.
export const ButtonLinkStyled = styled.a<{
  dark?: boolean
}>`
  ${ButtonBase}
  ${secondary}
  ${small}
  text-decoration: none;
  display: inline-flex;
  ${(props) => props.dark && dark}
`
