import axios from 'axios'
import { push } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useMutation } from '@apollo/client'
import { CHANNELS_AUTHORIZATION } from '../../../apollo/mutations/channels'
import { GET_CHANNELS_FOR_ORGANIZATION } from '../../../apollo/queries/channels'

import { PageLayout } from '../../../../../account-components'
import { actions } from '../../../../../channel-list/reducer'

import AuthFlows from '../auth-flows'
import GenericErrorModal from '../auth-flows/shared/GenericError'
import ChannelList from './ChannelList'

import { getChannelsCookies } from '../utils/cookies'
import { OauthFlowResponse } from './OAuthFlowResponse'
import { ChannelConnectTitle, ContactAdminMessage } from './components'
import * as Styles from './styles'

import { errorTypes } from '../../../../../channel-list'
import LoaderWithOverlay from '../auth-flows/shared/LoaderWithOverlay/LoaderWithOverlay'
import {
  getErrorCodeFromUrl,
  getErrorMessageFromUrl,
  getServiceFromUrl,
  getStateTokenFromUrl,
} from '../utils/queryParams'
import { CHANNEL_STEALING_ERROR_MESSAGE } from './errors'

import { BufferTracker, Client } from '@bufferapp/buffer-tracking-browser-ts'
import { isAtPlanLimit } from '../../../../../shared-utils'
import { HC_UTM_PARAMS } from '../../../../../shared-utils/constants'
import { handleChannelConnectionSuccessModalFlow } from '../utils/modals'

const HELP_CENTER_URL = `https://support.buffer.com/article/573-refreshing-a-channel-in-buffer?${HC_UTM_PARAMS}`

export const ConnectChannelsPage = (): JSX.Element => {
  const dispatch = useDispatch()
  const [showLoader, setShowLoader] = useState(false)
  const [showAuthFlow, setShowAuthFlow] = useState(false)
  const [serviceName, setServiceName] = useState('')

  function cleanupQueryParams(): void {
    dispatch(push('/channels/connect'))
  }

  useEffect(() => {
    dispatch(actions.resetConnectedPagesState())
  }, [])

  const [oauthFlowResponse, setOauthFlowResponse] = useState({
    error: false,
    errorCode: null,
    fetched: false,
    service: '',
    type: '',
    channels: [],
    channelToReconnect: null,
  })

  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  const isChannelStealing = (message): boolean => {
    return message === CHANNEL_STEALING_ERROR_MESSAGE
  }

  const [channelsAuthorization] = useMutation(CHANNELS_AUTHORIZATION, {
    onCompleted: (data) => {
      if (data?.channelsAuthorization?.channel) {
        dispatch(push('/channels'))
        dispatch(actions.showChannelConnectSuccessNotification())

        handleChannelConnectionSuccessModalFlow([
          data?.channelsAuthorization?.channel.id,
        ])
      }
      if (data?.channelsAuthorization?.userFriendlyMessage) {
        const { userFriendlyMessage } = data.channelsAuthorization

        if (isChannelStealing(userFriendlyMessage)) {
          dispatch(
            actions.showChannelConnectErrorNotification(
              errorTypes.CHANNEL_STEALING,
            ),
          )
        }
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ error: true; errorCode: any; s... Remove this comment to see the full error message
        setOauthFlowResponse({
          error: true,
          errorCode: userFriendlyMessage,
          service: serviceName,
        })
        setShowLoader(false)
      }
    },
    onError: (error) => {
      setOauthFlowResponse({
        error: true,
        // @ts-expect-error TS(2322) FIXME: Type 'ApolloError' is not assignable to type 'null... Remove this comment to see the full error message
        errorCode: error,
        service: serviceName,
      })
      setShowLoader(false)
    },
    refetchQueries: [{ query: GET_CHANNELS_FOR_ORGANIZATION }],
    awaitRefetchQueries: true,
  })

  const account = useSelector((state) => state.account)

  const isError = getErrorMessageFromUrl()

  useEffect(() => {
    if (isAtPlanLimit(account)) {
      BufferTracker.upgradePathViewed({
        organizationId: account?.currentOrganization?.id || '',
        upgradePathName: 'addChannel-upgrade',
        product: 'account',
        clientName: Client.PublishWeb,
      })
    }
    /** We use the state url param to keep track of tiktok in the callback here
     * because tiktok does not accept params in the redirect url
     **/
    const stateTokenFromUrl = getStateTokenFromUrl()

    if (
      window.location.search.match('connected=false') ||
      window.location.search.match('error=access_denied')
    ) {
      const service = getServiceFromUrl()
      const errorCode = getErrorCodeFromUrl()

      setOauthFlowResponse({
        error: true,
        service,
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'null'.
        errorCode,
      })
      cleanupQueryParams()
    } else if (window.location.search.match('oauth=true')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, service] = window.location.search.match(/service=(\w+)/) || []
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [__, code] = window.location.search.match(/code=(.+?)(?=$|&)/) || []

      const {
        oauthChannelsType,
        oauthChannelsChannelId,
        oauthChannelObjectId,
      } = getChannelsCookies()

      axios
        .get(`/oauth/${service}/getChannelsList`, {
          params: {
            code,
            type: oauthChannelsType,
            channelObjectId: oauthChannelObjectId,
          },
        })
        .then(({ data }) => {
          setOauthFlowResponse({
            fetched: true,
            service,
            type: oauthChannelsType,
            channels: data.channels,
            // @ts-expect-error TS(2322) FIXME: Type '{ serviceId: string; } | null' is not assign... Remove this comment to see the full error message
            channelToReconnect: oauthChannelsChannelId
              ? {
                  serviceId: oauthChannelsChannelId,
                  channelObjectId: oauthChannelObjectId,
                }
              : null,
          })
        })
        .catch(() => {
          // @ts-expect-error TS(2345) FIXME: Argument of type '{ fetched: true; error: true; se... Remove this comment to see the full error message
          setOauthFlowResponse({
            fetched: true,
            error: true,
            service,
          })
        })

      cleanupQueryParams()
    }
  }, [account])

  const minimumChannelLimitDefault = 10
  const channelLimit =
    account.currentOrganization && account.currentOrganization.channelLimit
      ? account.currentOrganization.channelLimit
      : minimumChannelLimitDefault

  const connectedChannelsCount =
    account && account.channels
      ? account.channels.filter(
          // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
          (channel) =>
            channel.organizationId === account.currentOrganization.id &&
            !channel.isLocked,
        ).length
      : 0

  const { canEdit } = account.currentOrganization
  const showAdminWarningMessage = !account.isLoading && !canEdit
  // @ts-expect-error TS(2339) FIXME: Property 'channelList' does not exist on type 'Def... Remove this comment to see the full error message
  const channelList = useSelector((state) => state.channelList)
  const hasConnectError = channelList.showChannelConnectErrorNotification
  const connectionErrorType = channelList.errorType

  const onAuthFlowClose = (): void => {
    setShowAuthFlow(false)
    setServiceName('')
    dispatch(actions.resetConnectedPagesState())
  }

  return (
    <PageLayout withSidebar withAppShell>
      <OauthFlowResponse
        actions={actions}
        currentOrganizationId={account.currentOrganization.id}
        dispatch={dispatch}
        oauthFlowResponse={oauthFlowResponse}
        setOauthFlowResponse={setOauthFlowResponse}
        setServiceName={setServiceName}
        setShowAuthFlow={setShowAuthFlow}
      />
      {hasConnectError && (
        <GenericErrorModal
          errorType={connectionErrorType}
          onClose={(): void => {
            setShowAuthFlow(false)
            setServiceName('')
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ error: false; service: string;... Remove this comment to see the full error message
            setOauthFlowResponse({ error: false, service: '' })
            setShowLoader(false)
            dispatch(actions.resetConnectedPagesState())
            dispatch(actions.hideChannelConnectErrorNotification())
          }}
          link={HELP_CENTER_URL}
        />
      )}

      {showAuthFlow && !hasConnectError && (
        // @ts-expect-error TS(2741) FIXME: Property 'channelToReconnect' is missing in type '... Remove this comment to see the full error message
        <AuthFlows onClose={onAuthFlowClose} serviceName={serviceName} />
      )}

      {showLoader && <LoaderWithOverlay />}

      <ChannelConnectTitle />
      {showAdminWarningMessage && <ContactAdminMessage />}

      <Styles.BlockWrapperStyled wide>
        <ChannelList
          channelLimit={channelLimit}
          atChannelLimit={connectedChannelsCount >= channelLimit}
          showAuthFlowCallback={setShowAuthFlow}
          setServiceNameCallback={setServiceName}
        />
      </Styles.BlockWrapperStyled>
    </PageLayout>
  )
}

export default ConnectChannelsPage
