import { useState } from 'react'

export function useNumberInput(
  // @ts-expect-error TS(7006) FIXME: Parameter 'initialValue' implicitly has an 'any' t... Remove this comment to see the full error message
  initialValue,
  minimumValue = 0,
) {
  const [lastUpdatedValue, setLastUpdatedValue] = useState(initialValue)
  const [value, setValue] = useState(parseInt(initialValue))

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  function setNumberValue(value) {
    // Prevent the number from being set to an undefined value
    // This prevents price calculations returning Nan
    if (!value) {
      return
    }

    const newValue = parseInt(value)
    setValue(newValue)
    setLastUpdatedValue(newValue)
  }

  function increaseValue() {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const newValue = parseInt(value + 1)
    setLastUpdatedValue(newValue)
    return setValue(newValue)
  }

  function decreaseValue() {
    if (value <= minimumValue) return setValue(minimumValue)

    // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const newValue = parseInt(value - 1)
    setLastUpdatedValue(newValue)
    return setValue(newValue)
  }

  return {
    value,
    lastUpdatedValue,
    increaseValue,
    decreaseValue,
    setNumberValue,
  }
}
