import { FeaturesWrapper } from '@bufferapp/features'
import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store, { history } from '../../store'
import { getEnvironment } from './utils/getEnvironment'

import { PopcornProvider } from '@buffer-mono/popcorn'
import ErrorBoundary from './ErrorBoundary'
import APIGatewayProvider from './apollo/providers/APIGatewayProvider'
import App from './pages/App'

store.dispatch({
  type: 'APP_INIT',
})
const renderApp = (AppComponent: React.FC): void => {
  const root = document.getElementById('root')
  if (!root) {
    return
  }
  createRoot(root).render(
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <PopcornProvider theme="light">
            <APIGatewayProvider>
              <FeaturesWrapper environment={getEnvironment()}>
                <AppComponent />
              </FeaturesWrapper>
            </APIGatewayProvider>
          </PopcornProvider>
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>,
  )
}

renderApp(App)

export { default as middleware } from './middleware'
export { actionTypes, actions, default as reducer } from './reducer'
