import React from 'react'

export const FeatureImageStar = () => {
  return (
    <svg
      width="52"
      height="53"
      viewBox="0 0 52 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9414 35.8853C18.0034 36.0989 18.1991 36.2459 18.4216 36.2459C18.6441 36.2459 18.8398 36.0989 18.9018 35.8853L22.6404 23L35.2537 19.1836C35.4646 19.1197 35.6089 18.9254 35.6089 18.705C35.6089 18.4846 35.4646 18.2902 35.2537 18.2264L22.6404 14.41L18.9018 1.52474C18.8398 1.31107 18.6441 1.16406 18.4216 1.16406C18.1991 1.16406 18.0034 1.31107 17.9414 1.52474L14.2028 14.41L1.58957 18.2264C1.37865 18.2902 1.23438 18.4846 1.23438 18.705C1.23438 18.9254 1.37865 19.1197 1.58957 19.1836L14.2028 23L17.9414 35.8853ZM51.3897 26.2453C51.4952 26.0495 51.4586 25.8075 51.2999 25.6516C51.1412 25.4957 50.8986 25.4634 50.7047 25.5723L41.414 30.7905L32.1232 25.5723C31.9293 25.4634 31.6867 25.4957 31.528 25.6516C31.3693 25.8075 31.3327 26.0495 31.4382 26.2453L36.555 35.7459L31.4382 45.2465C31.3327 45.4424 31.3693 45.6844 31.528 45.8403C31.6867 45.9962 31.9293 46.0285 32.1232 45.9196L41.414 40.7014L50.7047 45.9196C50.8986 46.0285 51.1412 45.9962 51.2999 45.8403C51.4586 45.6844 51.4952 45.4424 51.3897 45.2465L46.2729 35.7459L51.3897 26.2453ZM24.8635 36.9459C24.9726 36.7493 24.9371 36.5041 24.7767 36.3465C24.6163 36.189 24.3705 36.1579 24.1759 36.2705L17.2746 40.2641L10.3734 36.2705C10.1788 36.1579 9.93299 36.189 9.77259 36.3465C9.6122 36.5041 9.57667 36.7493 9.68575 36.9459L13.6037 44.0065L9.68575 51.0672C9.57667 51.2637 9.6122 51.509 9.77259 51.6665C9.93299 51.824 10.1788 51.8551 10.3734 51.7425L17.2746 47.7489L24.1759 51.7425C24.3705 51.8551 24.6163 51.824 24.7767 51.6665C24.9371 51.509 24.9726 51.2637 24.8635 51.0672L20.9455 44.0065L24.8635 36.9459Z"
        fill="url(#paint0_linear_1375_3420)"
        stroke="white"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1375_3420"
          x1="18.4762"
          y1="15.3417"
          x2="51.1562"
          y2="51.5761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE247" />
          <stop offset="1" stopColor="#FFBB37" />
        </linearGradient>
      </defs>
    </svg>
  )
}
