import React, { useEffect } from 'react'

import { useAccount } from '../../../web/src/context/Account'

import {
  Badge,
  Button,
  CheckIcon,
  CloseIcon,
  Flex,
  Heading,
  Label,
  Text,
} from '@buffer-mono/popcorn'
import {
  BufferTracker,
  Client,
  Product,
} from '@bufferapp/buffer-tracking-browser-ts'
import { onboardingPage } from '../../../routes'
import { OnboardingPage } from '../OnboardingPage'
import styles from './TrialPrompt.module.css'
import { useHistory } from 'react-router-dom'

export const TrialPrompt = (): JSX.Element => {
  const account = useAccount()
  const history = useHistory()
  const organizationId = account?.currentOrganization?.id

  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null

  useEffect(() => {
    if (organizationId) {
      BufferTracker.onboardingTrialPromptViewed({
        clientName: Client.Core,
        organizationId,
        product: Product.Account,
      })
    }
  }, [organizationId])

  useEffect(() => {
    const onAppShellLoad = (): void => {
      const { COMPONENTS, actions } = window.appshell || {}

      if (!actions || !COMPONENTS) {
        return
      }

      actions?.renderComponent({
        containerId: 'startTrialOnboardingTrialPromptCta',
        componentKey: COMPONENTS.startTrialButton,
        options: {
          cta: 'account-onboarding-trial-prompt-startTrial-1',
          ctaButton: 'account-onboarding-trial-prompt-startTrial-1',
          upgradePathName: 'onboarding-trial-prompt',
        },
      })
    }

    // This has been implemented to fix the Start Trial button
    // not rendering consistantly in Safari browsers
    // Check if the page has already loaded
    if (window.appshell !== undefined) {
      onAppShellLoad()
    } else {
      window.addEventListener('load', onAppShellLoad)
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onAppShellLoad)
    }
  }, [])

  const onSkipPromptClick = (): void => {
    BufferTracker.onboardingSkipped({
      organizationId,
      product: Product.Account,
      ...commonTrackingProperties,
    })
    history.push(onboardingPage.getRoute('publishing'))
  }

  const freeList = [
    '3 Connected channels',
    '10 Scheduled posts per channel',
    '1 Scheduled Twitter/X Threads',
    '3 Tags',
  ]
  const trialList = [
    'Unlimited Connected channels',
    '2,000 Scheduled posts per channel',
    'Unlimited Scheduled Twitter/X Threads',
    'Unlimited Tags',
  ]

  const commonList = [
    'Analytics, Insights and Reporting',
    'Engagement tools',
    'Draft collaboration tools',
    'Hashtag Manager',
    'Unlimited team member and clients',
  ]

  return (
    <OnboardingPage
      title="Unlock all our premium features for free ✨"
      subtitle="Consider a 14-day free trial of our Team plan for more powerful social marketing features:"
      usePopcorn={true}
    >
      <Flex gap="space-300">
        <div className={styles.planCard}>
          <Heading size="large">Free</Heading>
          <ul className={styles.list}>
            {freeList.map((item, index) => (
              <li key={index}>
                <CheckIcon color="brand" />
                <Label size="medium">{item}</Label>
              </li>
            ))}
            {commonList.map((item, index) => (
              <li key={index}>
                <CloseIcon color="subtle" className={styles.disabled} />
                <Label className={styles.disabled} size="medium">
                  {item}
                </Label>
              </li>
            ))}
          </ul>
          <Flex
            className={styles.flexWithPaddingBottom}
            direction="column"
            align="center"
            gap="space-200"
          >
            <Button
              className={styles.fullWidthButton}
              onClick={onSkipPromptClick}
              size="large"
              variant="secondary"
              color="subtle"
            >
              Stay wih Limited Features
            </Button>
          </Flex>
        </div>
        <div className={`${styles.planCard} ${styles.planCardSuggested}`}>
          <Flex align="center" gap="space-150">
            <Heading size="large">Team</Heading>
            <Badge>Recommended</Badge>
          </Flex>
          <ul className={styles.list}>
            {trialList.map((item, index) => (
              <li key={index}>
                <CheckIcon color="brand" />
                <Label size="medium">{item}</Label>
              </li>
            ))}
            {commonList.map((item, index) => (
              <li key={index}>
                <CheckIcon color="brand" />
                <Label size="medium">{item}</Label>
              </li>
            ))}
          </ul>
          <Flex direction="column" align="center" gap="space-100">
            <div
              className={styles.startTrialButton}
              id="startTrialOnboardingTrialPromptCta"
            />
            <Text color="subtle">No credit card required</Text>
          </Flex>
        </div>
      </Flex>
    </OnboardingPage>
  )
}
