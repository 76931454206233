import gql from 'graphql-tag'

export const GET_BETA_FEATURES = gql`
  query BetaFeatures {
    betaFeatures {
      name
      description
      feedbackUrl
      platforms
    }
  }
`
