import { grayDarker, grayLight } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const NumberInputContainer = styled.div`
  display: flex;
  width: 170px;
  height: 36px;
  margin-bottom: 0.5rem;

  border: 1px ${grayLight} solid;
  border-radius: 4px;
`

export const NumberInputButton = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;
  min-width: 50px;

  background: #d5dbff99;
  color: ${grayDarker};
  cursor: pointer;
`

export const NumberInputCountDisplay = styled.div`
  display: flex;
  flex: 2 1 0;

  justify-content: center;
  align-items: center;

  input {
    border: none;
    text-align: center;
    margin: 0;

    /* Disable number arrows */
    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
  }
`
