import React, { useState } from 'react'

import { Notification, Tooltip, Text, Link, Button } from '@bufferapp/ui'
import {
  Card,
  CardBody,
  CardFooter,
} from '../../../../../../account-components'
import { Channels, Dollar, Info, People } from '@bufferapp/ui/Icon'
import { white } from '@bufferapp/ui/style/colors'
import { useSelector } from 'react-redux'

import { getGatewayStore } from '../../utils/planHelpers'
import PlanItem from './components/PlanItem'

import {
  generatePlanDetails,
  hasMoreChannelsThanLimit,
  getExtraInfoCopy,
} from './utils'
import { HC_UTM_PARAMS } from '../../../../../../shared-utils/constants'

import * as Styles from './styles'
import PlanBillingNotice from './components/PlanBillingNotice/PlanBillingNotice'
import { isPayingStripeCustomer } from './components/TaxMessaging/utils'
import BillingCard from './components/BillingCard/BillingCard'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import useGetPortalSession from '../BillingInformation/hooks/useGetPortalSession'
import { useAccount } from '../../../../context/Account'
import { numberWithCurrency } from '../../utils/numberWithCurrency'
import useRemoveSubscriptionSchedule from './useRemoveSubscriptionSchedule'
import { Error } from '../../style'
import RemoveSubscriptionScheduleModal from '../RemoveSubscriptionScheduleModal'

function renderUpgradeCta(handleOnClick: (cta: string) => void): JSX.Element {
  const cta = 'billing-plansCard-changePlanFromPaidMobile-1'

  return (
    // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message
    <Button
      type="primary"
      label="Upgrade"
      onClick={(): void => handleOnClick(cta)}
    ></Button>
  )
}

type PlansCardProps = {
  callback: (cta: string) => void
  balance: number
  canEdit: boolean | undefined
  channelsCount: number | undefined
  channelsLimit: number
  channelsLocked: number
  currentPlan: any
  discount: {
    type: 'percent' | 'amount'
    value: number
    duration: 'forever' | 'once' | 'repeating'
    durationInMonths: number
  }
  hasCreditCard: boolean
  shouldShowBillingActions: boolean
  shouldShowUpgradeCtasForMobilePayingCutomers: boolean
  membersCount: number
  // FIXME: Type 'any' is not a valid type, but `subscription` is a complex object
  subscription: any
  upcomingInvoiceAmount: string
  userLimit: number
  upcomingInvoiceAmountExcludingTax: string
  upcomingInvoiceAmountTax: string
  showPaymentPastDue: boolean
  currency: string
}

const PlansCard = ({
  callback,
  canEdit,
  channelsCount,
  channelsLimit,
  channelsLocked,
  currentPlan,
  hasCreditCard,
  membersCount,
  subscription,
  upcomingInvoiceAmount,
  userLimit,
  shouldShowBillingActions,
  balance,
  discount,
  shouldShowUpgradeCtasForMobilePayingCutomers,
  upcomingInvoiceAmountExcludingTax,
  upcomingInvoiceAmountTax,
  showPaymentPastDue,
  currency,
}: PlansCardProps): JSX.Element => {
  const [subscriptionType, setSubscriptionType] = useState('')
  const [showCancelSuccessNotification, setShowCancelSuccessNotification] =
    useState(false)

  const accountContext = useAccount()

  const plan = (subscription && subscription.plan) || null
  const trial = (subscription && subscription.trial) || null

  const account = useSelector((state) => state.account)

  // These values are all needed for PlanBillingNotice
  // which renders tax collection messaging
  const isSubscriptionTaxEnabled = Boolean(
    account?.currentOrganization?.billing?.isSubscriptionTaxEnabled,
  )
  const isCustomerTaxExpempt = Boolean(
    account?.currentOrganization?.billing?.taxExempt !== 'none',
  )
  const customerTaxDetails = account?.currentOrganization?.billing?.taxDetails
  const isStripePayingCustomer = isPayingStripeCustomer(account)

  const { openBillingPortal } = useGetPortalSession()

  const handleUpdateAddressCTA = (): void => {
    BufferTracker.billingPortalSessionCreated({
      organizationId: account?.currentOrganization?.id,
      customerId: account?.currentOrganization?.billing?.gateway?.gatewayId,
      ctaApp: 'account',
      ctaView: 'billing',
      ctaLocation: 'plansBillingCard',
      ctaButton: 'pleaseAddYourAddress',
      ctaVersion: '1',
    })

    openBillingPortal(true)
  }

  const organizationId = account?.currentOrganization?.id

  const [removeScheduleError, setRemoveScheduleError] = useState('')

  const [
    showRemoveSubscriptionScheduleModal,
    setShowRemoveSubscriptionScheduleModal,
  ] = useState(false)
  const { onRemoveSubscriptionSchedule, loading: isRemoveScheduleLoading } =
    useRemoveSubscriptionSchedule({
      organizationId,
      setRemoveScheduleError,
    })

  const planDetails = generatePlanDetails({
    accountContext,
    plan: {
      ...plan,
      renewalDate: subscription?.periodEnd,
      isCanceledAtPeriodEnd: subscription?.isCanceledAtPeriodEnd,
      canceledDate: subscription?.canceledAt,
    },
    trial,
    callback,
    canEdit,
    hasCreditCard,
    scheduledUpdate: subscription?.scheduledUpdate,
    setShowRemoveSubscriptionScheduleModal,
    isRemoveScheduleLoading,
    setSubscriptionType,
  })

  let userLimitCopy
  switch (userLimit) {
    case 0:
      userLimitCopy = 'Limited to 1'
      break
    case 9999:
      userLimitCopy = 'Unlimited'
      break
    default:
      userLimitCopy = `Limited to ${userLimit}`
  }

  const upcomingInvoiceAmountCopy = `$${
    hasCreditCard ? upcomingInvoiceAmount : '0.00'
  }`

  const upcomingInvoiceAmountExcludingTaxCopy = `${
    hasCreditCard
      ? numberWithCurrency(
          parseFloat(upcomingInvoiceAmountExcludingTax),
          currency,
        )
      : '$0.00'
  }`

  const upcomingInvoiceAmountTaxCopy = `${
    hasCreditCard
      ? numberWithCurrency(parseFloat(upcomingInvoiceAmountTax), currency)
      : '$0.00'
  }`

  const isAgency = currentPlan?.id === 'agency'

  const gatewayStoreName = getGatewayStore(account?.currentOrganization)

  const openSubscriptionUpdate = (): void => {
    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.subscriptionUpdate, {
      cta: 'billing-plansCard-unlock-1',
      upgradePathName: 'account-upgrade',
      shouldPickModalOnOrganizationState: true,
    })
  }

  const isOnActiveTrial = !!trial?.isActive

  /* Display Plan Name or Trial */
  const getPlanTitle = (): JSX.Element => {
    const title = isOnActiveTrial
      ? 'Free Trial'
      : planDetails.name.props
          .children /* we need to do this because planDetails.name is a ReactNode */
    return (
      <Text type="h3" color="grayDarker">
        {title}
      </Text>
    )
  }

  /* Display description for Plan or trail regarding Channels */
  const getPlanChannelsDescription = (): JSX.Element | string => {
    /* If the user is in a Trial */
    if (isOnActiveTrial) {
      return 'Unlimited'
    }

    /* Is an agency plan */
    if (isAgency) {
      return (
        <span>
          Agency pricing
          {/* @ts-expect-error TS(2322) */}
          <Tooltip
            className="tooltip"
            position="top"
            label={`$${currentPlan?.prices?.monthlyFlatFee} for ${currentPlan?.prices?.channelTierUnit} channels per month. Additional channels for $${currentPlan?.prices?.monthlyChannelPrice} per month.`}
          >
            <Info color="gray"></Info>
          </Tooltip>
        </span>
      )
    }

    /* Otherwise */
    if (currentPlan.interval === 'year') {
      return `$${currentPlan?.prices?.basePlanPrice} each per year`
    } else {
      return `$${currentPlan?.prices?.baseMonthlyPrice} each per month`
    }
  }

  return (
    <React.Fragment>
      <Card title="Plans">
        <CardBody>
          <Styles.BodyContent>
            <div>
              {getPlanTitle()}
              {!showPaymentPastDue && planDetails.description}
              {removeScheduleError && (
                <Styles.ScheduledUpdateErrorWrapper>
                  <Error isInline={true} error={removeScheduleError} />
                </Styles.ScheduledUpdateErrorWrapper>
              )}
            </div>
            {planDetails.label ? <div>{planDetails.label}</div> : null}
          </Styles.BodyContent>
          <PlanBillingNotice
            balance={balance}
            discount={discount}
            isCustomerTaxExpempt={isCustomerTaxExpempt}
            isSubscriptionTaxEnabled={isSubscriptionTaxEnabled}
            taxDetails={customerTaxDetails}
            isPayingCustomer={isStripePayingCustomer}
            onUpdateAddressClick={handleUpdateAddressCTA}
          />
          <Styles.PlanItemsWrapper>
            <PlanItem
              icon={<People color={white} />}
              title="Users"
              description={userLimitCopy}
              value={membersCount}
              isTwoColumn={!shouldShowBillingActions}
            />
            <PlanItem
              icon={<Channels color={white} />}
              title="Channels"
              description={getPlanChannelsDescription()}
              value={
                !isOnActiveTrial
                  ? `${channelsCount}/${channelsLimit}`
                  : channelsCount
              }
              extraInfo={getExtraInfoCopy(
                channelsLocked,
                channelsCount,
                channelsLimit,
              )}
              extraCTACopy={
                hasMoreChannelsThanLimit(
                  channelsLocked,
                  channelsCount,
                  channelsLimit,
                )
                  ? 'Unlock'
                  : null
              }
              extraCTAOnClick={openSubscriptionUpdate}
              isTwoColumn={!shouldShowBillingActions}
            />
            {shouldShowBillingActions && (
              <BillingCard
                icon={<Dollar color={white} />}
                title={
                  isOnActiveTrial ? 'First bill after trial' : 'Upcoming bill'
                }
                description={
                  <span>
                    Usage Changes
                    {/* @ts-expect-error TS(2322) */}
                    <Tooltip
                      className="tooltip"
                      position="top"
                      label="This amount could change if adjustments are made mid-billing cycle to your number of connected channels. We’ll always account for those changes and include the details in your invoice."
                    >
                      <Info color="gray"></Info>
                    </Tooltip>
                  </span>
                }
                valueExcludingTax={upcomingInvoiceAmountExcludingTaxCopy}
                valueOfTax={upcomingInvoiceAmountTaxCopy}
                value={upcomingInvoiceAmountCopy}
                isOnTrial={isOnActiveTrial}
                hasCreditCard={Boolean(hasCreditCard)}
              />
            )}
          </Styles.PlanItemsWrapper>
        </CardBody>
        {shouldShowBillingActions && (
          <CardFooter>{planDetails.actions}</CardFooter>
        )}
        {shouldShowUpgradeCtasForMobilePayingCutomers && (
          <CardFooter>{renderUpgradeCta(callback)}</CardFooter>
        )}
      </Card>

      {!shouldShowBillingActions && (
        <Styles.MobileTextWrappper>
          <Text type="help">
            Your subscription is managed through the {gatewayStoreName}{' '}
            <Link
              newTab
              fontWeight={700}
              href={`https://support.buffer.com/hc/en-us/articles/360038768454?${HC_UTM_PARAMS}`}
            >
              Learn more
            </Link>{' '}
          </Text>
        </Styles.MobileTextWrappper>
      )}

      {showRemoveSubscriptionScheduleModal && (
        <RemoveSubscriptionScheduleModal
          onCloseClick={(): void =>
            setShowRemoveSubscriptionScheduleModal(false)
          }
          onConfirmClick={(): void => {
            setShowRemoveSubscriptionScheduleModal(false)
            onRemoveSubscriptionSchedule()
          }}
          currentPlanName={currentPlan.name}
          currentChannelCount={channelsCount || 0}
        />
      )}
      {showCancelSuccessNotification && (
        <Notification
          text={`You've successfully canceled your ${subscriptionType}.`}
          onClose={(): void => setShowCancelSuccessNotification(false)}
        />
      )}
    </React.Fragment>
  )
}

export default PlansCard
