import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'

import AccordionSection from './AccordionSection'

import { AccordionWrapper, AccordianContent, ItemWrapper } from './style'

const Accordion = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'heading' implicitly has an 'any' ... Remove this comment to see the full error message
  heading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'items' implicitly has an 'any' ty... Remove this comment to see the full error message
  items,
}) => {
  return (
    <AccordionWrapper>
      <Text type="h2">{heading}</Text>
      <AccordianContent>
        {/* @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type. */}
        {items.map((item) => (
          <ItemWrapper key={item.title}>
            <AccordionSection label={item.title} content={item.content} />
          </ItemWrapper>
        ))}
      </AccordianContent>
    </AccordionWrapper>
  )
}

Accordion.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
    }),
  ).isRequired,
}

Accordion.defaultProps = {
  heading: '',
}

export default Accordion
