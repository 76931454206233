import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
  position: relative;
`

export const SupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 2;
  min-width: 300px;
  width: 100%;

  span {
    font-size: 14px;
    color: ${grayDarker};
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 130px;
`

export const CTAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  gap: 8px;
`

export const ItemContainerAnchor = styled.div`
  position: relative;
`
