import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import { white, grayDark } from '@bufferapp/ui/style/colors'

export const Body = styled.div`
  padding: 0 16px;
  width: 100%;
  background-color: ${white};
  text-align: center;

  p {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
`

export const ModalHeader = styled.div`
  padding: 0 16px 16px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  h3 {
    margin: 0 auto;
    font-weight: 700;
  }
`

export const LargeIllustrationWrapper = styled.div`
  margin: 32px auto 0;
  height: 300px;
  background-image: url('https://s3.amazonaws.com/static.buffer.com/images/account/fb-auth-illustration-02%402x.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

export const StyledText = styled(Text)`
  color: ${grayDark};
`
