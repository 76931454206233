import React from 'react'
import PropTypes from 'prop-types'
import { ButtonLinkStyled } from './style'

// @ts-expect-error TS(7031) FIXME: Binding element 'href' implicitly has an 'any' typ... Remove this comment to see the full error message
const ButtonLink = ({ href, label, children, ...props }) => (
  <ButtonLinkStyled href={href} {...props}>
    {label}
    {children}
  </ButtonLinkStyled>
)

ButtonLink.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.oneOf([PropTypes.func, PropTypes.node]),
}

ButtonLink.defaultProps = {
  href: '/',
  label: null,
  children: null,
}

export default ButtonLink
