import React from 'react'
import PropTypes from 'prop-types'

import { capitalizeFirstLetter } from '../../../../../../shared-utils'

import {
  SwitchInput,
  SwitchLabel,
  SwitchInputWrapper,
  SwitchInputConatiner,
} from './style'

// @ts-expect-error TS(7031) FIXME: Binding element 'inputName' implicitly has an 'any... Remove this comment to see the full error message
function Switch({ inputName, defaultSelected, radioOptions, handleOnChange }) {
  return (
    <SwitchInputWrapper>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type. */}
      {radioOptions.map((option) => (
        <SwitchInputConatiner
          key={option.value}
          checked={defaultSelected === option.value}
        >
          <SwitchInput
            required
            defaultValue={option.value}
            type="radio"
            name={inputName}
            id={option.value}
            checked={defaultSelected === option.value}
            onChange={(event: any) => handleOnChange(event.target.value)}
          />
          <SwitchLabel htmlFor={option.value}>
            {capitalizeFirstLetter(option.label)}
          </SwitchLabel>
        </SwitchInputConatiner>
      ))}
    </SwitchInputWrapper>
  )
}

Switch.propTypes = {
  inputName: PropTypes.string.isRequired,
  defaultSelected: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  radioOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
}

export default Switch
