import styled from 'styled-components'
import {
  gray,
  grayLight,
  grayDark,
  grayDarker,
} from '@bufferapp/ui/style/colors'

export const LoaderWrapper = styled.div`
  padding-right: 8px;
  svg {
    height: 16px;
    width: 16px;
  }
`

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 1rem;
  label {
    margin-bottom: 0px;
  }
`

export const LegacyNoticeWrapper = styled.div`
  a,
  a:visited,
  a:hover,
  a:active {
    color: blue;
  }
`

export const TextWrapper = styled.div`
  width: 70%;
  display: inline-flex;
  color: ${grayDarker};
`

export const SwitchWrapper = styled.div`
  display: inline-flex;
  margin-top: 13px;
  position: relative;
  float: right;
`

export const FooterTextWrapper = styled.div`
  color: ${grayDarker};
  p {
    font-size: 12px;
  }
`

export const BetaCommunityWrapper = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-of-type) {
    border-top: 1px solid ${grayLight};
  }
  &:first-of-type {
    padding-bottom: 16px;
  }
  &:last-of-type {
    margin-bottom: 0px;
    padding-top: 16px;
  }
`
export const BetaCommunityTextWrapper = styled.div`
  margin-right: 12px;
`

export const ActionButtonWrapper = styled.div``

export const BetaFeaturesWrapper = styled.div`
  padding: 8px 0;
`

export const BetaFeature = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const BetaFeatureTextWrapper = styled.div<{
  isBetaProgramEnabled: boolean
}>`
  max-width: 70%;
  .featureTitle {
    font-weight: bold;
    color: ${(props): string =>
      props.isBetaProgramEnabled === true ? grayDarker : gray};
  }
  .featureDescription {
    margin-top: 4px;
    color: ${(props): string =>
      props.isBetaProgramEnabled === true ? grayDark : gray};
  }
`

export const BetaFeatureButtonWrapper = styled.div`
  height: 30px;
`

export const NoBetaFeaturesText = styled.div`
  margin-top: 1rem;
  font-style: italic;
`

export const BetaInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    padding-left: 8px;
  }
  svg {
    color: ${grayDark};
  }
`

export const CardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid #e8e8e8;

  div {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`
