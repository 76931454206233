import gql from 'graphql-tag'

export const GET_ALPHA_FEATURES = gql`
  query AlphaFeatures {
    alphaFeatures {
      name
      description
      feedbackUrl
      platforms
    }
  }
`
