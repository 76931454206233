import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const SupportLink = styled.a`
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
  color: ${grayDarker};
`

export const Card = styled.div`
  margin-top: 3rem;
  max-width: 1000px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0;
  gap: 1rem;
  padding: 3rem 12rem;
  background-color: lightgrey;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    #f4f4f4 0%,
    rgba(247, 248, 253, 0.74) 100%
  );
`
export const ContactAdvocacyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 860px;
`
