import React from 'react'
import { Text } from '@bufferapp/ui'
import PropTypes from 'prop-types'

import { FeatureWrapper, Image, Title } from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'image' implicitly has an 'any' ty... Remove this comment to see the full error message
const Feature = ({ image, alt, title, description }) => (
  <FeatureWrapper>
    <Image src={image} alt={alt} />
    <Title>
      <Text type="h3">{title}</Text>
    </Title>
    <Text type="p">{description}</Text>
  </FeatureWrapper>
)

Feature.propTypes = {
  alt: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
}

export default Feature
