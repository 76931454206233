import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getStartTrialUrl } from '../../../shared-utils'
import { Text, Button } from '@bufferapp/ui'
import Feature from './Feature'
import { Wrapper, Title, SubTitle, Features, ButtonWrapper } from './styles'
import analyzeData from '../../data/analyze.json'

/**
 *
 */
class CrossSellingExperiment extends Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)

    this.state = {
      environment: null,
      texts: analyzeData,
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  static getDerivedStateFromProps(props, state) {
    const { environment, product } = props
    const { texts } = state

    if (environment !== state.environment) {
      // @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
      const updatedPlans = texts.plans.map((plan) => ({
        ...plan,
        url: getStartTrialUrl({
          product,
          environment,
          // @ts-expect-error TS(2345) FIXME: Argument of type '{ product: any; environment: any... Remove this comment to see the full error message
          plan: plan.id,
        }),
      }))

      return {
        environment,
        texts: {
          ...texts,
          plans: updatedPlans,
        },
      }
    }

    return null
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'texts' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { texts } = this.state
    const { title, subtitle, plans, notice, cards } = texts
    // @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
    const businessPlan = plans.find((plan) => plan.id === 'business')

    return (
      <Wrapper>
        <Title>
          <Text type="h1">{title}</Text>
        </Title>
        <SubTitle>
          <Text type="h3">{subtitle}</Text>
        </SubTitle>

        <Features>
          {/* @ts-expect-error TS(7006) FIXME: Parameter 'card' implicitly has an 'any' type. */}
          {cards.map((card, idx) => (
            <Feature {...card} key={idx} />
          ))}
        </Features>
        <ButtonWrapper>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; onClick: () =>... Remove this comment to see the full error message */}
          <Button
            type="primary"
            size="large"
            onClick={() => window.location.replace(businessPlan.url)}
            label={businessPlan.buttonLabel}
            fullWidth
          />
        </ButtonWrapper>
        <Text type="p">{notice}</Text>
      </Wrapper>
    )
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state) => ({
  environment: state.environment.environment,
})

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
CrossSellingExperiment.propTypes = {
  product: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(CrossSellingExperiment)
