import { Loader, Text } from '@bufferapp/ui'
import React from 'react'
import { useParams } from 'react-router-dom'

import { PageLayout } from '../../../../../account-components'
import { ChannelThirdPartyService } from '../../../../../channel-connections/interfaces'
import PostAuthenticationRedirect from '../../../components/ChannelConnections/PostAuthenticationRedirect/PostAuthenticationRedirect'
import { useChannelConnections } from '../../../context/ChannelConnections/ChannelConnections'
import { ConnectionHeader } from './ConnectionHeader/ConnectionHeader'
import ErrorMessage from './ErrorMessage'
import { LoadingWrapper } from './styles'
import { OAuthFlowErrorType } from './types'
import { getServiceName, isSingleFlowType } from '../utils/channels'

const OAuthConnection = (): JSX.Element => {
  const { service } = useParams<{
    service: ChannelThirdPartyService
  }>()
  const {
    connectionErrorType: errorType,
    loading,
    isRefreshingConnection,
  } = useChannelConnections()

  const validService = Object.keys(ChannelThirdPartyService).includes(service)
  const channelsCopy = isSingleFlowType(service) ? 'channel' : 'channels'

  return (
    <PageLayout withSidebar={false} withAppShell={false}>
      <LoadingWrapper>
        {validService && <ConnectionHeader service={service} />}
        {errorType && !loading && (
          <ErrorMessage errorType={errorType} service={service} />
        )}
        {!errorType && loading && (
          <>
            <Text type="h3">
              {`${
                isRefreshingConnection ? 'Refreshing' : 'Connecting'
              } ${getServiceName(service)} ${channelsCopy}...`}
            </Text>
            <Loader />
          </>
        )}
        {!validService && (
          <ErrorMessage
            errorType={OAuthFlowErrorType.notAllowedService}
            service={service}
          />
        )}
        <PostAuthenticationRedirect />
      </LoadingWrapper>
    </PageLayout>
  )
}

export default OAuthConnection
