import React from 'react'
import { Avatar } from '@bufferapp/ui'

import { getChannelConnectionAvailabilityCopy } from '../utils'

import {
  type ConnectableChannelData,
  ConnectionAvailability,
} from '../../../../../hooks/channel-connections/types'
import type { InputFieldProps, OnChangeArgs } from './types'
import * as Styled from './styles'
import { getServiceName } from '../../../../../pages/Channels/utils/channels'
import type { ChannelThirdPartyService } from '../../../../../../../channel-connections/interfaces'

function renderCheckBox(
  name: string,
  value: ConnectableChannelData,
  onChangeSelection: ({ channel, isSelected }: OnChangeArgs) => void,
  service: ChannelThirdPartyService,
  serviceType: string,
  isSelected: boolean,
  location?: string,
): JSX.Element {
  const { connectionAvailability } = value

  const connectionCopy = getChannelConnectionAvailabilityCopy(
    connectionAvailability || ConnectionAvailability.available,
  )
  return (
    <Styled.Label htmlFor={name}>
      <Styled.TextGroup>
        <strong>{name}</strong>
        <span>
          {getServiceName(service)} {serviceType}
        </span>
        {location && <span>{location}</span>}
      </Styled.TextGroup>
      <Styled.ConnectionAvailability>
        <strong>{connectionCopy}</strong>
      </Styled.ConnectionAvailability>
      <Styled.CheckBox
        type="checkbox"
        id={name}
        name={name}
        value={name}
        disabled={connectionAvailability !== ConnectionAvailability.available}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
          onChangeSelection({
            channel: value,
            isSelected: event.target.checked,
          })
        }
        checked={isSelected}
      />
    </Styled.Label>
  )
}

export default function InputField({
  name,
  value,
  hanleOnChange,
  service,
  serviceType,
  location,
  isSelected,
}: InputFieldProps): JSX.Element {
  const { connectionAvailability } = value
  const fallbackAvatarUrl =
    'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

  return (
    <Styled.ListItem
      isDisabled={connectionAvailability !== ConnectionAvailability.available}
    >
      <Avatar
        src={value?.avatar || fallbackAvatarUrl}
        alt={`profile image for ${getServiceName(service)}`}
        network={service}
      />
      <Styled.Selection>
        {renderCheckBox(
          name,
          value,
          hanleOnChange,
          service,
          serviceType,
          isSelected,
          location,
        )}
      </Styled.Selection>
    </Styled.ListItem>
  )
}
