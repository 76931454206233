import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  margin: 0 0.5rem;
`

export const EmailInputWrapper = styled.section`
  width: 100%;
  display: flex;
`

export const TooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 14px 0 14px 10px;
`
