import React from 'react'

import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'

import { IconWrapper } from './style'

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export function renderFeatureContent(data) {
  return (
    <IconWrapper checkMark={data}>
      {data ? <CheckmarkIcon size="small" /> : <CrossIcon size="small" />}
    </IconWrapper>
  )
}
