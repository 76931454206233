import React from 'react'

import { errorTypes } from '../../../../../channel-list/reducer'

import SelectChannelList from '../auth-flows/shared/SelectChannelList'
import NoChannelsError from '../auth-flows/shared/NoChannelsError'
import NonBusinessError from '../auth-flows/shared/NonBusinessError'
import GenericErrorModal from '../auth-flows/shared/GenericError'

import { ERROR_CODES } from './config'

// @ts-expect-error TS(7006) FIXME: Parameter 'oauthFlowResponse' implicitly has an 'a... Remove this comment to see the full error message
const notBusiness = (oauthFlowResponse) =>
  oauthFlowResponse.errorCode === ERROR_CODES.CHANNEL_IS_NOT_BUSINESS

// @ts-expect-error TS(7006) FIXME: Parameter 'oauthFlowResponse' implicitly has an 'a... Remove this comment to see the full error message
const isError = (oauthFlowResponse) => oauthFlowResponse.error

// @ts-expect-error TS(7006) FIXME: Parameter 'oauthFlowResponse' implicitly has an 'a... Remove this comment to see the full error message
const hasNoChannels = (oauthFlowResponse) =>
  oauthFlowResponse.channels &&
  oauthFlowResponse.channels.length === 0 &&
  oauthFlowResponse.fetched

// @ts-expect-error TS(7006) FIXME: Parameter 'oauthFlowResponse' implicitly has an 'a... Remove this comment to see the full error message
const shouldSelectChannels = (oauthFlowResponse) =>
  oauthFlowResponse.channels && oauthFlowResponse.channels.length > 0

// @ts-expect-error TS(7006) FIXME: Parameter 'oauthFlowResponse' implicitly has an 'a... Remove this comment to see the full error message
const getErrorType = (oauthFlowResponse) =>
  oauthFlowResponse.errorCode && oauthFlowResponse.errorCode === '6012'
    ? errorTypes.CHANNEL_STEALING
    : ''

// @ts-expect-error TS(7006) FIXME: Parameter 'oauthFlowResponse' implicitly has an 'a... Remove this comment to see the full error message
export function getOauthFlowResponseRenderCase(oauthFlowResponse) {
  if (notBusiness(oauthFlowResponse)) {
    return 'notBusinessChannel'
  }

  if (isError(oauthFlowResponse)) {
    return 'error'
  }

  if (hasNoChannels(oauthFlowResponse)) {
    return 'noChannels'
  }

  if (shouldSelectChannels(oauthFlowResponse)) {
    return 'selectChannels'
  }

  return null
}

export const OauthFlowResponse = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'actions' implicitly has an 'any' ... Remove this comment to see the full error message
  actions,
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentOrganizationId' implicitly... Remove this comment to see the full error message
  currentOrganizationId,
  // @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
  dispatch,
  // @ts-expect-error TS(7031) FIXME: Binding element 'oauthFlowResponse' implicitly has... Remove this comment to see the full error message
  oauthFlowResponse,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setOauthFlowResponse' implicitly ... Remove this comment to see the full error message
  setOauthFlowResponse,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setServiceName' implicitly has an... Remove this comment to see the full error message
  setServiceName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setShowAuthFlow' implicitly has a... Remove this comment to see the full error message
  setShowAuthFlow,
}) => {
  switch (getOauthFlowResponseRenderCase(oauthFlowResponse)) {
    case 'error':
      return (
        <GenericErrorModal
          errorType={getErrorType(oauthFlowResponse)}
          onClose={() => {
            setShowAuthFlow(false)
            setServiceName('')
            setOauthFlowResponse({ error: false, service: '' })
            dispatch(actions.resetConnectedPagesState())
            dispatch(actions.hideChannelConnectErrorNotification())
          }}
        />
      )
    case 'notBusinessChannel':
      return (
        <NonBusinessError
          service={oauthFlowResponse.service}
          onClose={() => {
            setShowAuthFlow(false)
            setServiceName('')
            setOauthFlowResponse({ error: false, service: '' })
            dispatch(actions.resetConnectedPagesState())
            dispatch(actions.hideChannelConnectErrorNotification())
          }}
        />
      )
    case 'noChannels':
      return (
        <NoChannelsError
          service={oauthFlowResponse.service}
          type={oauthFlowResponse.type}
          onClose={() => {
            setOauthFlowResponse({ channels: [], fetched: false })
          }}
        />
      )
    case 'selectChannels':
      return (
        <SelectChannelList
          channelToReconnect={oauthFlowResponse.channelToReconnect}
          service={oauthFlowResponse.service}
          pages={oauthFlowResponse.channels}
          type={oauthFlowResponse.type}
          // @ts-expect-error TS(2322) FIXME: Type '{ channelToReconnect: any; service: any; pag... Remove this comment to see the full error message
          currentOrganizationId={currentOrganizationId}
          onClose={() => {
            setOauthFlowResponse({ channels: [] })
          }}
        />
      )
    default:
      return null
  }
}
