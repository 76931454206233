import styled from 'styled-components'

export const ConnectionWrapper = styled.div`
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`

export const ConnectionIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`
