import styled, { css } from 'styled-components'
import {
  white,
  blue,
  grayDefault,
  grayLighter,
  grayLight,
  gray,
  grayDark,
  grayDarker,
  borderRadius,
  fontSize,
  lineHeight,
  fontWeightMedium,
  fontFamily,
  boxShadow,
} from '../../styles'

/**
 *
 */
const button = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${fontSize};
  line-height: ${lineHeight};
  font-weight: ${fontWeightMedium};
  border-radius: ${borderRadius};
  cursor: pointer;
  user-select: none;
  border: 0;
  -webkit-appearance: none;
  position: relative;
  transition-property: background-color, border-color, color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 0 0 auto;
  font-family: ${fontFamily};
  background-color: ${white};
  color: ${grayDefault};
  padding-top: 0;
  padding-bottom: 0;
  :hover {
    transition-property: background-color, border-color, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }
  :active {
    top: 1px;
  }
  :focus {
    box-shadow: 0 0 0 3px ${boxShadow};
    outline: 0;
  }
`

const buttonMedium = css`
  padding-right: 16px;
  padding-left: 16px;
  height: 40px;
`

const buttonSecondary = css`
  background-color: ${white};
  color: ${grayDark};
  border: 1px solid ${gray};

  :hover {
    color: ${grayDarker};
    border-color: ${grayDark};
  }

  :focus {
    border-color: ${blue};
    color: ${grayDarker};
  }
`

/**
 *
 */
export const InputPasswordStyled = styled.div`
  width: 100%;
`

export const InputPasswordWrapper = styled.div`
  position: relative;
`

export const ShowPasswordToggle = styled.button`
  ${button};
  ${buttonMedium};
  ${buttonSecondary};
  height: 33px;
  border: none;
  background-color: ${grayLighter};
  border-left: 1px solid ${grayLight};
  border-radius: 0px ${borderRadius} ${borderRadius} 0px;
  position: absolute;
  right: 1px;
  top: 28px;
  width: 90px;
  transition-property: none;
  transition-duration: 0s;
  transition-timing-function: none;
  fill: ${grayDark};
  color: ${grayDark};

  :active {
    top: 30px;
  }
  :hover {
    border-color: ${grayLight};
    border-left: 1px solid ${grayLight};
    transition-property: none;
    transition-duration: 0s;
    transition-timing-function: none;
    fill: ${grayDarker};
    color: ${grayDarker};
  }
  :focus {
    border-color: ${grayLight};
    box-shadow: none;
    outline: inherit;
  }

  svg {
    margin-right: 8px;
  }
`
