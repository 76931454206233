// TODO: use centralized account/organization types once available in buffer-mono

export const hasCompletedPaymentUpdate = ({
  currentOrganization,
}: {
  currentOrganization: any
}): boolean => {
  if (!currentOrganization || !currentOrganization?.dismissedObjects) {
    return false
  }

  return currentOrganization?.dismissedObjects?.some(
    (object: { id: string }) => object.id === 'payment-past-due-banner',
  )
}

export const isPaymentPastDue = ({
  currentOrganization,
}: {
  currentOrganization: any
}): boolean => {
  return (
    currentOrganization?.billing?.subscription?.isPaymentPastDue === true ||
    false
  )
}

export const showPastDueState = ({
  currentOrganization,
}: {
  currentOrganization: any
}): boolean => {
  return (
    !hasCompletedPaymentUpdate({ currentOrganization }) &&
    isPaymentPastDue({ currentOrganization }) &&
    currentOrganization?.isOneBufferOrganization === true
  )
}
