import { type ApolloError, useQuery } from '@apollo/client'
import { GET_ALPHA_FEATURES } from '../../../apollo/queries/alphaFeatures'
import type { AlphaFeature } from '../types'

type AlphaFeaturesReturn = {
  data: { alphaFeatures: AlphaFeature[] }
  loading: boolean
  error: ApolloError | undefined
}

const useAlphaFeatures = (): AlphaFeaturesReturn => {
  const { data, loading, error } = useQuery(GET_ALPHA_FEATURES)

  return {
    data,
    loading,
    error,
  }
}

export default useAlphaFeatures
