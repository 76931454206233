import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Text, Link } from '@bufferapp/ui'
import { ModalTitle } from '../../../../../../../account-components'
import { Cross, Warning } from '@bufferapp/ui/Icon'
import { useDispatch } from 'react-redux'

import { actions } from '../../../../../../../channel-list/reducer'

import {
  Body,
  ModalHeader,
  IconWrapper,
  LeftIconWrapper,
} from '../Modal/styles'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
const getGuideUrl = (service): string => {
  if (service === 'pinterest') {
    return 'https://help.pinterest.com/en/business/article/get-a-business-account#section-15096'
  }
  return ''
}

const capitalize = (string: string): string =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error TS(7031) FIXME: Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const NonBusinessError = ({ onClose, service }): JSX.Element => {
  const dispatch = useDispatch()

  return (
    // @ts-expect-error TS(2322)
    <Modal
      secondaryAction={{
        label: 'Close',
        callback: (): void => {
          dispatch(actions.close())
          onClose()
        },
      }}
      dismissible={false}
    >
      <ModalHeader>
        <LeftIconWrapper>
          <IconWrapper>
            <Warning />
          </IconWrapper>
        </LeftIconWrapper>
        <ModalTitle
          text={`Sorry, we only support ${capitalize(
            service,
          )} business accounts.`}
        />
        <IconWrapper>
          <Cross
            onClick={(): void => {
              dispatch(actions.close())
              onClose()
            }}
          />
        </IconWrapper>
      </ModalHeader>
      <Body>
        <Text type="p">
          Unfortunately, we’re unable to connect to {capitalize(service)}{' '}
          personal accounts. Are you up for connecting a {capitalize(service)}{' '}
          business account instead? In case it helps,{' '}
          <Link href={getGuideUrl(service)} newTab>
            here are some instructions for converting your personal account to a
            business account
          </Link>
          .
        </Text>
      </Body>
    </Modal>
  )
}

NonBusinessError.propTypes = {
  onClose: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
}

export default NonBusinessError
