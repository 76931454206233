import { useAccount } from '../../../../../web/src/context/Account'
import {
  isAtPlanLimit,
  getCurrentPlanNameFromAccount,
} from '../../../../../shared-utils'
import { trackConnectionAttempted } from '../../../tracking'
import { START_PAGE_URL } from '../../../utils/redirect'

export default function useGoToBufferChannel(): (service: string) => void {
  const account = useAccount()

  const goToBufferChannel = (service: string): void => {
    const formattedServiceName =
      service === 'start page' ? 'startPage' : service

    trackConnectionAttempted({
      organizationId: account?.currentOrganization?.id,
      serviceName: formattedServiceName,
      cta: 'channel-connect-page',
      planNameAtTimeOfAttempt: getCurrentPlanNameFromAccount(account),
      isAtPlanLimit: isAtPlanLimit(account),
    })

    if (service === 'start page') {
      window.location.assign(`${START_PAGE_URL}onboarding`)
    }
  }

  return goToBufferChannel
}
