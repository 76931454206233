import styled from 'styled-components'

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #e5e5e5;
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 512px;
  background-color: #ffffff;
  padding: 56px;

  text-decoration: none;

  h1 {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 16px;
  }
`
