import styled from 'styled-components'
import { white, grayLight } from '@bufferapp/ui/style/colors'

const BUTTON_COLOR = '#D5DBFF99'

export const SwitchInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 36px;
  margin-bottom: 0.5rem;

  background: ${white};
  border-radius: 4px;
  border: 1px solid ${grayLight};
  font-size: 14px;
  font-weight: 400;
`

export const SwitchInput = styled.input`
  cursor: pointer;
  display: flex;
  position: relative;
  transition: background-color 0.2s;

  opacity: 0;
  position: fixed;
  width: 0;
`

export const SwitchLabel = styled.label`
  display: flex;
  align-self: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s;
`

export const SwitchInputConatiner = styled.label<{
  checked: boolean
}>`
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  position: relative;
  min-width: 114px;
  height: 100%

  cursor: pointer;
  transition: background-color 0.2s;
  background: ${(props) => (props.checked ? `${BUTTON_COLOR}` : `${white}`)};
`
