import type { TaxDetails } from '../TaxMessaging/types'
import {
  hasValidTaxAddress,
  isCustomerLocatedInTaxArea,
} from '../TaxMessaging/utils'

// We only want to display the Tax Notice to Existing Customers
// Who do not have subscription enabled tax collection yet and are not tax exempt
export function shouldDisplayTaxMessaging({
  isPayingCustomer,
  isCustomerTaxExpempt,
  isSubscriptionTaxEnabled,
  taxDetails,
}: {
  isPayingCustomer: boolean
  isCustomerTaxExpempt: boolean
  isSubscriptionTaxEnabled: boolean
  taxDetails: TaxDetails
}): boolean {
  const isInTaxableLocation = isCustomerLocatedInTaxArea(
    taxDetails?.automaticTax,
  )

  const isCustomerWithAddressAndInTaxArea =
    isPayingCustomer &&
    hasValidTaxAddress(taxDetails) &&
    !isCustomerTaxExpempt &&
    !isSubscriptionTaxEnabled &&
    isInTaxableLocation

  const isCustomerWithOutAddress =
    isPayingCustomer &&
    !hasValidTaxAddress(taxDetails) &&
    !isCustomerTaxExpempt &&
    !isSubscriptionTaxEnabled

  if (isCustomerWithAddressAndInTaxArea || isCustomerWithOutAddress) {
    return true
  }

  return false
}
