import styled from 'styled-components'

import { grayLighter, grayDark, white } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  background: ${white};
  border-top: 1px solid ${grayLighter};
  padding: 24px;
  width: 100%;
  font-size: 14px;
  color: ${grayDark};

  a {
    color: ${grayDark};
  }

  svg {
    margin-right: 8px;
  }
`
