import React from 'react'

import { Text } from '@bufferapp/ui'
import SurveyAnswers from './SurveyAnswers'
import * as Styled from './styles'
import type { AnswerType, SurveyQuestionType } from '../../types'

export const SurveyQuestion = ({
  questionId,
  questionLabel,
  answers,
  onChangeSelection,
  multiselection = false,
}: {
  questionId: string
  questionLabel: string
  answers: AnswerType[]
  onChangeSelection: ({ questionId, answers }: SurveyQuestionType) => void
  multiselection?: boolean
}): JSX.Element => {
  return (
    <Styled.QuestionWrapper data-questionid={questionId}>
      <Text type="h3">{questionLabel}:</Text>
      <SurveyAnswers
        questionId={questionId}
        answers={answers}
        multipleSelection={multiselection}
        onChangeSelection={onChangeSelection}
      />
    </Styled.QuestionWrapper>
  )
}
