// TODO: These functions could get refactored to be a bit simpler
// Though for sake of this calculator and plan pricing not changing in the near future
// This is not a priority right now. But a nice to have
export const PLANS_PRICING_INITIAL_VALUE = [
  {
    planName: 'essentials',
    baseMonthlyPrice: 6,
    baseYearlyPrice: 60,
  },
  {
    planName: 'team',
    baseMonthlyPrice: 12,
    baseYearlyPrice: 120,
  },
  {
    planName: 'agency',
    quantityMonthlyPrice: 6,
    quantityYearlyPrice: 60,
    flatMonthlyPrice: 120,
    flatYearlyPrice: 1200,
    baseMonthlyPrice: 120,
    baseYearlyPrice: 1200,
  },
]

// @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
export function calculateAgencyPricing(plan, channelsCount) {
  if (channelsCount < 10) {
    const prices = {
      monthlyPrice: 120,
      yearlyPrice: 1200,
    }

    return prices
  }

  const numberOfExtraChannels = channelsCount - 10

  const newPrices = {
    monthlyPrice:
      plan.baseMonthlyPrice + numberOfExtraChannels * plan.quantityMonthlyPrice,
    yearlyPrice:
      plan.baseYearlyPrice + numberOfExtraChannels * plan.quantityYearlyPrice,
  }

  return newPrices
}

// @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
export function calculatePrice(plan, basePrice, channelsCount) {
  // Agency prices are calculated differently
  // Because of the flat pricing for under 10 channels
  if (plan.planName === 'agency') {
    return calculateAgencyPricing(plan, channelsCount)
  }

  return {
    monthlyPrice: plan.baseMonthlyPrice * channelsCount,
    yearlyPrice: plan.baseYearlyPrice * channelsCount,
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'listOfCalculatedPricing' implicitly has... Remove this comment to see the full error message
export function fincPlanPricingFromList(listOfCalculatedPricing, planName) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  return listOfCalculatedPricing.find((item) => item.planName === planName)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'numberOfChannels' implicitly has an 'an... Remove this comment to see the full error message
export function calculatePricing(numberOfChannels) {
  const result = PLANS_PRICING_INITIAL_VALUE.map((plan) => {
    const newPrices = calculatePrice(
      plan,
      plan.baseMonthlyPrice,
      numberOfChannels,
    )
    const newValue = {
      ...plan,
      baseMonthlyPrice: newPrices.monthlyPrice,
      baseYearlyPrice: newPrices.yearlyPrice,
    }
    return newValue
  })

  return result
}
