import gql from 'graphql-tag'

export const VOTE_FOR_FEATURE_ON_FEATURE_OS = gql`
  mutation voteForFeatureOnFeatureOS($accountId: String!, $featureId: Int!) {
    voteForFeatureOnFeatureOS(accountId: $accountId, featureId: $featureId) {
      ... on MutationBasicResponse {
        success
      }
      ... on BasicError {
        cause
      }
    }
  }
`
