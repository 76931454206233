import React from 'react'

import Notice from '@bufferapp/ui/Notice'

import { Container, CopyWrapper } from './styles'

import type { PlanBillingNoticeProps } from './types'
import BalanceAndDiscount from '../BalanceAndDiscount'
import TaxMessaging from '../TaxMessaging'
import {
  hasValidTaxAddress,
  isCustomerLocatedInTaxArea,
} from '../TaxMessaging/utils'

import { shouldDisplayTaxMessaging } from './utils'

export const PlanBillingNotice = (
  props: PlanBillingNoticeProps,
): JSX.Element | null => {
  const {
    balance,
    discount,
    isCustomerTaxExpempt,
    taxDetails,
    isSubscriptionTaxEnabled,
    isPayingCustomer,
    onUpdateAddressClick,
  } = props
  const customerTaxStatus = taxDetails?.automaticTax

  const isPayingCustomerWithValidTaxAddress =
    isPayingCustomer && hasValidTaxAddress(taxDetails)

  const isInTaxableLocation = isCustomerLocatedInTaxArea(customerTaxStatus)

  const shouldDisplayTaxMessage = shouldDisplayTaxMessaging({
    isPayingCustomer,
    isCustomerTaxExpempt,
    isSubscriptionTaxEnabled,
    taxDetails,
  })

  const shouldDisplayBalanceAndDiscount = balance !== 0 || discount

  // if no messsaging needs to be displayed
  if (!shouldDisplayBalanceAndDiscount && !shouldDisplayTaxMessage) return null

  return (
    <Container
      shouldDisplayTaxMessage={shouldDisplayTaxMessage}
      shouldDisplayBalanceAndDiscount={!!shouldDisplayBalanceAndDiscount}
    >
      {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
      <Notice type="note" disableAnimation>
        <CopyWrapper>
          {shouldDisplayTaxMessage && (
            <TaxMessaging
              hasValidTaxAddress={isPayingCustomerWithValidTaxAddress}
              isInTaxableLocation={isInTaxableLocation}
              onUpdateAddressClick={onUpdateAddressClick}
            />
          )}
          <BalanceAndDiscount balance={balance} discount={discount} />
        </CopyWrapper>
      </Notice>
    </Container>
  )
}

export default PlanBillingNotice
