import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@bufferapp/ui/Tooltip'
import Tag from '@bufferapp/ui/Tag'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'

import { MIGRATION_TABLE_ROW_CONTENT, PLANS_FOR_COLUMNS } from './tableData'

import { renderFeatureContent } from './utils'

import {
  TableRow,
  TableRowHeader,
  TextContainer,
  TextHeading,
  TagWrapper,
  TableColumnData,
  CellConatiner,
  ComparisonTableWrapper,
  TableSection,
  TableColumnHeader,
  HeaderCellConatiner,
  CTALink,
} from './style'

// @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
function renderWithTooltip(text) {
  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: any; position: s... Remove this comment to see the full error message
    <Tooltip label={text} position="right">
      <InfoIcon size="medium" />
    </Tooltip>
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'tableColumnData' implicitly has an 'any... Remove this comment to see the full error message
function renderCategoryRows(tableColumnData, features, category) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'feature' implicitly has an 'any' type.
  return features.map((feature) => {
    return (
      <TableRow key={`row-${category}-${feature.name}`}>
        <TableRowHeader>
          <TextContainer>
            <TextHeading>
              <span>{feature.name}</span>
              {feature.tooltip && renderWithTooltip(feature.tooltip)}
              {feature.comingSoon && (
                <TagWrapper>
                  <Tag color="green">Coming Soon</Tag>
                </TagWrapper>
              )}
            </TextHeading>
          </TextContainer>
        </TableRowHeader>
        {/* @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type. */}
        {tableColumnData.map((plan) => {
          return (
            <TableColumnData key={`plan-${plan.planId}`}>
              <CellConatiner>
                <div>{renderFeatureContent(feature[plan.planId])}</div>
              </CellConatiner>
            </TableColumnData>
          )
        })}
      </TableRow>
    )
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
function ComparisonTable(props) {
  const { tableRowData, tableColumnData } = props

  return (
    <>
      <ComparisonTableWrapper>
        {/* @ts-expect-error TS(7006) FIXME: Parameter 'row' implicitly has an 'any' type. */}
        {tableRowData.map((row) => {
          return (
            <TableSection key={`section-${row.category}`}>
              <TableRow>
                <TableColumnHeader isLeftColumn>
                  <h2>{row.category}</h2>
                </TableColumnHeader>
                {/* @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type. */}
                {tableColumnData.map((plan) => {
                  return (
                    <TableColumnHeader key={`column-${plan.planId}`}>
                      <HeaderCellConatiner hasBorder={plan.hasCTA}>
                        <TextContainer center>
                          <h2>{plan.planName}</h2>
                          <p>{plan.description}</p>
                        </TextContainer>
                        {plan.hasCTA && (
                          <CTALink href="#plan-selection">
                            <span>Update Now</span>
                          </CTALink>
                        )}
                      </HeaderCellConatiner>
                    </TableColumnHeader>
                  )
                })}
              </TableRow>
              {renderCategoryRows(tableColumnData, row.features, row.category)}
            </TableSection>
          )
        })}
      </ComparisonTableWrapper>
    </>
  )
}

const PlanFeatureValue = PropTypes.oneOfType([PropTypes.bool, PropTypes.string])

// @ts-expect-error TS(2339) FIXME: Property 'PropTypes' does not exist on type 'typeo... Remove this comment to see the full error message
const FeatureType = PropTypes.PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  YOUR_PLAN: PlanFeatureValue.isRequired,
  LATEST_VERSION: PlanFeatureValue.isRequired,
  tooltip: PropTypes.string,
  comingSoon: PropTypes.bool,
})

ComparisonTable.propTypes = {
  tableRowData: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(FeatureType).isRequired,
    }),
  ),
  tableColumnData: PropTypes.arrayOf(
    PropTypes.shape({
      planId: PropTypes.string.isRequired,
      planName: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      hasCTA: PropTypes.bool.isRequired,
    }),
  ),
}

ComparisonTable.defaultProps = {
  tableRowData: MIGRATION_TABLE_ROW_CONTENT,
  tableColumnData: PLANS_FOR_COLUMNS,
}

export default ComparisonTable
