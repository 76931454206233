import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import AddIcon from '@bufferapp/ui/Icon/Icons/Add'
import SubtractIcon from '@bufferapp/ui/Icon/Icons/Subtract'
import Input from '@bufferapp/ui/Input'

import { useNumberInput } from '../../hooks/useNumberInput'

import {
  NumberInputContainer,
  NumberInputButton,
  NumberInputCountDisplay,
} from './style'

// @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
function handleOnBlur(value, onValueChange, setNumberValue, lastUpdatedValue) {
  const parsedValue = parseInt(value, 10)

  // Checks when a user has left the input feild empty
  // we set the value to the last value before deleted
  if (!parsedValue) {
    onValueChange(lastUpdatedValue)
    return setNumberValue(lastUpdatedValue)
  }

  onValueChange(parsedValue)
  return setNumberValue(parsedValue)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
function handleOnChange(value, onValueChange, setNumberValue) {
  const parsedValue = parseInt(value, 10)
  if (!parsedValue) {
    onValueChange('')
    return
  }

  onValueChange(parsedValue)
  setNumberValue(parsedValue)
}

const NumberInput = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'initialValue' implicitly has an '... Remove this comment to see the full error message
  initialValue,
  // @ts-expect-error TS(7031) FIXME: Binding element 'minimumValue' implicitly has an '... Remove this comment to see the full error message
  minimumValue,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onValueChange' implicitly has an ... Remove this comment to see the full error message
  onValueChange,
}) => {
  const {
    value,
    lastUpdatedValue,
    increaseValue,
    decreaseValue,
    setNumberValue,
  } = useNumberInput(initialValue, minimumValue)

  const [displayValue, setDisplayValue] = useState(value)

  useEffect(() => {
    onValueChange(value)
    setDisplayValue(value)
  }, [value])

  return (
    <NumberInputContainer>
      <NumberInputButton
        id="channel_counter_decrease_btn"
        onClick={() => decreaseValue()}
      >
        <SubtractIcon size="medium" />
      </NumberInputButton>
      <NumberInputCountDisplay>
        <Input
          // @ts-expect-error TS(2322) FIXME: Type '{ id: string; type: string; value: string; o... Remove this comment to see the full error message
          id="channel_counter_number_input"
          type="number"
          value={`${displayValue}`}
          // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
          onChange={(event) =>
            handleOnChange(event.target.value, setDisplayValue, setNumberValue)
          }
          // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
          onBlur={(event) =>
            handleOnBlur(
              event.target.value,
              setDisplayValue,
              setNumberValue,
              lastUpdatedValue,
            )
          }
          name="counter"
          size="small"
        />
      </NumberInputCountDisplay>
      <NumberInputButton
        id="channel_counter_increase_btn"
        onClick={() => increaseValue()}
      >
        <AddIcon size="medium" />
      </NumberInputButton>
    </NumberInputContainer>
  )
}

NumberInput.propTypes = {
  initialValue: PropTypes.string.isRequired,
  minimumValue: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
}

NumberInput.defaultProps = {
  minimumValue: 0,
}

export default NumberInput
