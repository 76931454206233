import styled, { css } from 'styled-components'
import { green, white } from '@bufferapp/ui/style/colors'
import { fontWeightMedium, fontFamily } from '@bufferapp/ui/style/fonts'

export const ServicesGrid = styled.ol<{ product: string }>`
  display: grid;
  grid-template-columns: repeat(
    4,
    1fr
  ); /* default value for product publishing */
  ${(props): any =>
    props.product === 'engagement' &&
    css`
      grid-template-columns: repeat(2, 1fr);
    `}
  ${(props): any =>
    props.product === 'analytics' &&
    css`
      grid-template-columns: repeat(3, 1fr);
    `}


  list-style: none;
  grid-gap: 16px;
  margin-bottom: 32px;

  /* Adjust grid columns based on screen size */
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);

    ${(props): any =>
      props.product === 'engagement' &&
      css`
        grid-template-columns: repeat(2, 1fr);
      `}
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 375px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 515px;
`

export const NewTag = styled.span`
  color: ${white};
  font-family: ${fontFamily};
  font-weight: ${fontWeightMedium};
  font-size: 12px;
  background: ${green};
  border-radius: 12px;
  padding: 0px 4px;
  position: absolute;
  top: 10px;
  right: 12px;
  border: 3px solid ${white};
`
