import { Link, Text } from '@bufferapp/ui'
import { Button } from '@buffer-mono/popcorn'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import { red } from '@bufferapp/ui/style/colors'
import React from 'react'
import {
  NoChannelsErrorBodyCopies,
  ServiceHeaderErrorMessage,
} from './NoChannelsErrorCopies'

import type { ChannelThirdPartyService } from '../../../../../../channel-connections/interfaces'
import { OAuthFlowErrorType } from '../types'
import * as Styled from './styles'
import { HC_UTM_PARAMS } from '../../../../../../shared-utils/constants'

const defaultBodyMessage = 'We couldn’t connect your account. Please try again.'

const stealingSupportLink = `https://support.buffer.com/article/571-unavailable-channels-are-likely-connected-to-another-buffer-organization?${HC_UTM_PARAMS}`
const defaultSupportLink = `https://support.buffer.com/category/500-buffer-social-network-guides?${HC_UTM_PARAMS}`
const notEnoughPermissionsLink = `https://support.buffer.com/article/671-changing-user-permissions-in-your-organization#h_01EJGJ7PWG9MKCMJWHZJQ0A8FS?${HC_UTM_PARAMS}`
const serviceSupportLinks: { [key: string]: string } = {
  facebook: `https://support.buffer.com/article/555-using-facebook-with-buffer?${HC_UTM_PARAMS}`,
  instagram: `https://support.buffer.com/article/554-using-instagram-with-buffer?${HC_UTM_PARAMS}`,
  linkedin: `https://support.buffer.com/article/560-using-linkedin-with-buffer?${HC_UTM_PARAMS}`,
  twitter: `https://support.buffer.com/article/561-using-twitter-with-buffer?${HC_UTM_PARAMS}`,
  pinterest: `https://support.buffer.com/article/558-using-pinterest-with-buffer?${HC_UTM_PARAMS}`,
  googlebusiness: `https://support.buffer.com/article/557-using-google-business-profiles-with-buffer?${HC_UTM_PARAMS}`,
  tiktok: `https://support.buffer.com/article/559-using-tiktok-with-buffer?${HC_UTM_PARAMS}`,
  mastodon: `https://support.buffer.com/article/563-using-mastodon-with-buffer?${HC_UTM_PARAMS}`,
  youtube: `https://support.buffer.com/article/562-using-youtube-shorts-with-buffer?${HC_UTM_PARAMS}`,
  shopify: `https://support.buffer.com/article/556-using-shopify-with-buffer?${HC_UTM_PARAMS}`,
}

const renderHeader = (
  service: ChannelThirdPartyService,
  errorType: OAuthFlowErrorType,
): React.ReactNode => {
  if (errorType === OAuthFlowErrorType.noChannelsError) {
    return ServiceHeaderErrorMessage(service)
  }
  if (errorType === OAuthFlowErrorType.lifetimeChannelConnectionError) {
    return 'You’ve reached the limit of connected channels on your free plan'
  }
  if (errorType === OAuthFlowErrorType.stealingNotAllowed) {
    return 'Sorry, the channel you are trying to connect is unavailable'
  }
  if (errorType === OAuthFlowErrorType.notEnoughPermissions) {
    return "You currently don't have the permissions required to make the connection"
  }
  return 'Looks like we’ve hit a snag. Would you mind trying again?'
}

const renderBody = (
  service: ChannelThirdPartyService,
  errorType: OAuthFlowErrorType,
): React.ReactNode => {
  if (errorType === OAuthFlowErrorType.noChannelsError) {
    return NoChannelsErrorBodyCopies[service] || defaultBodyMessage
  }

  if (errorType === OAuthFlowErrorType.lifetimeChannelConnectionError) {
    return (
      <Text type="p">
        You have reached the limit on the number of times you can connect a
        channel on Buffer&apos;s free plan. To add more, please consider a paid
        plan which also unlocks additional features.
      </Text>
    )
  }

  if (errorType === OAuthFlowErrorType.stealingNotAllowed) {
    return (
      <>
        <Text type="p">
          It looks like that channel is already connected to another Buffer
          organization. For security reasons, each unique channel may only be
          connected to one organization at a time.
        </Text>
        <Text type="p">
          Removing the channel from the other organization will make it
          available to connect here.{' '}
          <Link href={stealingSupportLink}>Learn more</Link> about resolving
          this issue in our Help Center.
        </Text>
      </>
    )
  }
  if (errorType === OAuthFlowErrorType.notEnoughPermissions) {
    return (
      <Text type="p">
        You will need to be an Admin of the Organization in order to add the
        channel(s). Find more information on how to set up an Admin{' '}
        <Link href={notEnoughPermissionsLink}>here</Link>.
      </Text>
    )
  }
  const errorLink = serviceSupportLinks[service] || defaultSupportLink
  return (
    <Text type="p">
      Something went wrong while trying to connect your channel. Check out our{' '}
      <Link href={errorLink} newTab>
        troubleshooting guide
      </Link>
      , and if the problem persists, please get in touch.
    </Text>
  )
}

interface ErrorMessageProps {
  errorType?: OAuthFlowErrorType
  service: ChannelThirdPartyService
}

const ErrorMessage = ({
  service,
  errorType = OAuthFlowErrorType.unexpectedError,
}: ErrorMessageProps): JSX.Element => {
  const showUpgradeAction =
    errorType === OAuthFlowErrorType.lifetimeChannelConnectionError
  const showTryAgain =
    errorType !== OAuthFlowErrorType.notAllowedService && !showUpgradeAction

  const onGoBack = (): void => {
    window.location.href = '/channels/connect'
  }

  const onTryAgain = (): void => {
    if (window.appshell?.actions) {
      const { actions } = window.appshell || {}
      actions.connectChannel({ selectedService: service })
    }
  }

  const onUpgrade = (): void => {
    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.planSelector, {
      cta: 'channelAuthorization-paywall-upgradePlan-1',
      upgradePathName: 'channelAuthorizationPaywall-upgrade',
    })
  }

  return (
    <Styled.ContentWrapper>
      <Styled.Heading data-testid="errorMessage-header">
        <Styled.IconWrapper>
          <WarningIcon size="medium" color={red} />
        </Styled.IconWrapper>
        <Text type="h3">{renderHeader(service, errorType)}</Text>
      </Styled.Heading>
      <Styled.Body data-testid="errorMessage-body">
        {renderBody(service, errorType)}
      </Styled.Body>
      <Styled.Actions>
        <Button variant="secondary" size="large" onClick={onGoBack}>
          Go Back
        </Button>
        {showTryAgain && (
          <Button variant="primary" size="large" onClick={onTryAgain}>
            Try Again
          </Button>
        )}
        {showUpgradeAction && (
          <Button variant="primary" size="large" onClick={onUpgrade}>
            Upgrade Plan
          </Button>
        )}
      </Styled.Actions>
    </Styled.ContentWrapper>
  )
}

export default ErrorMessage
