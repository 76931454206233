import React from 'react'
import { Modal } from '@bufferapp/ui'
import { ModalTitle } from '../../../../../../../account-components'
import { Cross } from '@bufferapp/ui/Icon'

import { IconWrapper } from '../Modal/styles'
import {
  Body,
  ModalHeader,
  LargeIllustrationWrapper,
  StyledText,
} from './styles'
import { FormattedServiceNames } from '../../../../../../../channel-list/constants'

const SigningIn = ({
  serviceName,
  onClose,
}: {
  serviceName: string
  onClose?: () => void
}): JSX.Element => {
  const shouldShowInstructions = serviceName === FormattedServiceNames.YOUTUBE

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; action: null; seconda... Remove this comment to see the full error message
    <Modal action={null} secondaryAction={null} dismissible={false}>
      <ModalHeader
        data-testid={`oauth-redirect-modal-${serviceName.toLowerCase()}`}
      >
        <ModalTitle text={`Whoosh! Sliding over to ${serviceName}...`} />
        <IconWrapper>
          <Cross onClick={onClose} />
        </IconWrapper>
      </ModalHeader>
      <Body>
        <StyledText type="p">
          {shouldShowInstructions ? (
            <>
              {'✔ Check '}
              <strong>{'all'}</strong>
              {` the boxes on the next screen to connect ${serviceName} smoothly.`}
            </>
          ) : (
            `Just a moment while we securely transfer you to ${serviceName} to sign in.`
          )}
        </StyledText>
        <LargeIllustrationWrapper />
      </Body>
    </Modal>
  )
}

export default SigningIn
