import React from 'react'
import { SimpleModal, Text, Button } from '@bufferapp/ui'
import { Checkmark } from '@bufferapp/ui/Icon'

import * as Styles from './styles'
import { UpgradeIllustrationMastodon } from './UpgradeIllustrationMastodon'
import { UpgradeIllustrationYoutube } from './UpgradeIllustrationYoutube'
import {
  FormattedServiceNames,
  Services,
} from '../../../../../../channel-list/constants'

type MigrationModalProps = {
  channelType: string
  onClose: () => void
}

export const MultiProductMigrationModal = ({
  onClose,
  channelType,
}: MigrationModalProps) => {
  const isOnboarding = window.location.pathname.includes('onboarding')
  const ctaRedirectUrl = `/new-plans?cta=account-${
    isOnboarding ? 'onboarding' : 'channelsConnect'
  }-${channelType}ChannelMigrationModal-findOutMore-1 `

  const capitalizeChannel = (channel: string) => {
    if (channel === Services.YOUTUBE) return FormattedServiceNames.YOUTUBE

    return channel.charAt(0).toUpperCase() + channel.slice(1)
  }

  return (
    <SimpleModal closeAction={onClose}>
      <Styles.Wrapper>
        <Styles.CopyBody>
          <Styles.CopyGroup>
            <Text type="h2">Unlock {capitalizeChannel(channelType)}</Text>
          </Styles.CopyGroup>

          <Styles.CopyGroup>
            <Text type="p">
              You’re on a <strong>legacy Buffer plan</strong>. Switch over to
              the newest version to unlock {capitalizeChannel(channelType)}.
              Plus you’ll receive:
            </Text>
          </Styles.CopyGroup>

          <Styles.List>
            <Styles.ListItem>
              <Checkmark />
              <Text type="p">No charge for channels you don’t use</Text>
            </Styles.ListItem>

            <Styles.ListItem>
              <Checkmark />
              <Text type="p">
                Unlimited team members (available our team plan)
              </Text>
            </Styles.ListItem>

            <Styles.ListItem>
              <Checkmark />
              <Text type="p">Access to Analytics included on all plans</Text>
            </Styles.ListItem>

            <Styles.ListItem>
              <Checkmark />
              <Text type="p">Access to all new features guaranteed</Text>
            </Styles.ListItem>

            <Styles.ListItem>
              <Checkmark />
              <Text type="p">Access to Twitter Threads</Text>
            </Styles.ListItem>

            <Styles.ListItem>
              <Checkmark />
              <Text type="p">Access to Ideas</Text>
            </Styles.ListItem>
          </Styles.List>

          <div>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
            <Button
              type="primary"
              label="Find Out More"
              onClick={() => {
                ;(window as Window).location = ctaRedirectUrl
              }}
            />
          </div>
        </Styles.CopyBody>

        {channelType === 'youtube' ? (
          <UpgradeIllustrationYoutube />
        ) : (
          <UpgradeIllustrationMastodon />
        )}
      </Styles.Wrapper>
    </SimpleModal>
  )
}
