import React, { type Reducer, useReducer } from 'react'

import {
  ChannelConnectionsContext,
  ChannelConnectionsDispatchContext,
} from '../../../context/ChannelConnections/ChannelConnections'

import ChannelConnectionsReducer, {
  type IReducerState as ChannelConnectionsState,
  type ReducerAction as ChannelConenctionsActions,
} from './channelConnectionsReducer'

function ChannelConnectionsProvider({
  children,
}: {
  children: JSX.Element
}): JSX.Element {
  const initialState = {
    selectedService: null,
    isRefreshingConnection: false,
    isNewConnection: false,
    connectionCompleted: false,
    connectedChannelIds: null,
    connectionErrorType: null,
    loading: false,
    availableChannelsForService: null,
  }

  const [state, stateDispatch] = useReducer<
    Reducer<ChannelConnectionsState, ChannelConenctionsActions>
  >(ChannelConnectionsReducer, initialState)

  return (
    <ChannelConnectionsContext.Provider value={state}>
      <ChannelConnectionsDispatchContext.Provider value={stateDispatch}>
        {children}
      </ChannelConnectionsDispatchContext.Provider>
    </ChannelConnectionsContext.Provider>
  )
}

export default ChannelConnectionsProvider
