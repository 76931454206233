import React from 'react'
import PropTypes from 'prop-types'
import { Checkmark } from '@bufferapp/ui/Icon'

import { PasswordFieldChecksStyled, Check, CheckText } from './style'

const PasswordFieldChecks = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'longitudeChecked' implicitly has ... Remove this comment to see the full error message
  longitudeChecked,
  // @ts-expect-error TS(7031) FIXME: Binding element 'uppercaseChecked' implicitly has ... Remove this comment to see the full error message
  uppercaseChecked,
  // @ts-expect-error TS(7031) FIXME: Binding element 'numOrSymbChecked' implicitly has ... Remove this comment to see the full error message
  numOrSymbChecked,
}) => (
  <PasswordFieldChecksStyled>
    <Check checked={longitudeChecked}>
      <CheckText>At least 8 characters long</CheckText>
      <Checkmark size="medium" color="green" />
    </Check>
    <Check checked={uppercaseChecked}>
      <CheckText>Contains 1 uppercase character</CheckText>
      <Checkmark size="medium" color="green" />
    </Check>
    <Check checked={numOrSymbChecked}>
      <CheckText>Contains 1 number or symbol</CheckText>
      <Checkmark size="medium" color="green" />
    </Check>
  </PasswordFieldChecksStyled>
)

PasswordFieldChecks.propTypes = {
  longitudeChecked: PropTypes.bool,
  uppercaseChecked: PropTypes.bool,
  numOrSymbChecked: PropTypes.bool,
}

PasswordFieldChecks.defaultProps = {
  longitudeChecked: false,
  uppercaseChecked: false,
  numOrSymbChecked: false,
}

export default PasswordFieldChecks
