import { ChannelThirdPartyService } from '../../../../channel-connections/interfaces'
import { getRedirectURLCookie } from '../../pages/Channels/utils/cookies'
import type { IsAuthenticationRedirectReturn } from './types'

export function getIsAuthenticationRedirect(): IsAuthenticationRedirectReturn {
  const pathname = window.location.pathname
  const endOfURLPath = pathname.split('/').pop() || ''

  const isCorrectLocationPath = pathname.startsWith('/channels/authorization')
  // If the current path ends with a valid service name
  // Then the user has come back from being redirected from oauth flow
  const hasConnectedService =
    Object.values<string>(ChannelThirdPartyService).includes(endOfURLPath) &&
    isCorrectLocationPath
  const serviceName = hasConnectedService
    ? ChannelThirdPartyService[
        endOfURLPath as keyof typeof ChannelThirdPartyService
      ]
    : null

  const isAuthenticationRedirect = hasConnectedService

  return {
    isAuthenticationRedirect,
    serviceNameFromRedirect: serviceName,
  }
}

export function setConnectionSuccessQueryParams(
  url: URL,
  channelIds: string[],
  isReconnectingChannels: boolean,
): URL {
  channelIds.forEach((channelId) => {
    url.searchParams.append('channelId', channelId)
  })

  isReconnectingChannels
    ? url.searchParams.set('channelRefreshSuccess', 'true')
    : url.searchParams.set('channelConnectionSuccess', 'true')

  return url
}

export function handleSuccessRedirect(
  connectedChannelIds: string[],
  newConnection: boolean,
  isReconnectingChannels: boolean,
): void {
  const redirectURL = getRedirectURLCookie()
  // If no redirect URL is found, redirect to the channels page
  if (!redirectURL) {
    window.location.href = '/channels'
    return
  }

  const originalRedirectURL = new URL(redirectURL)
  const isAuthorizationPage = originalRedirectURL
    .toString()
    .includes('channels/authorization')

  // If redirect URL is the authorization page, redirect to the channels page
  const url = isAuthorizationPage
    ? new URL('/channels', window.location.origin)
    : new URL(redirectURL)

  // Before we redirect the customer we need to attach query params
  // which will be used to trigger the connection success celebration modal
  const newURLWithSuccessParams = setConnectionSuccessQueryParams(
    url,
    connectedChannelIds,
    isReconnectingChannels ? true : !newConnection,
  )

  window.location.href = newURLWithSuccessParams.toString()
}
