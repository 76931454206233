import styled, { css } from 'styled-components'
import {
  white,
  yellowLighter,
  yellowLightest,
  grayDark,
  grayDarker,
} from '@bufferapp/ui/style/colors'

export const BackgroundWrapper = styled.div<{
  withGradient?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
  background: ${white};

  ${(props) =>
    props.withGradient &&
    css`
      background: linear-gradient(
        180deg,
        rgba(213, 219, 255, 0.5) 0%,
        rgba(242, 242, 248, 0.5) 100%
      );
    `}
`

export const PageWrapper = styled.div`
  padding-bottom: 4rem;
  background: ${white};
  color: ${grayDarker};
`

export const ContentWrapper = styled.div<{
  inline?: boolean
}>`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.inline ? `row` : `column`)};
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  max-width: 1170px;
`

export const CenterLayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    margin: 0.8rem 0;
  }
`

export const Notice = styled.div`
  margin-bottom: 1rem;
  padding: 4px 8px;
  align-items: center;
  text-align: center;
  background-color: ${yellowLightest};
  border: 1px solid;
  border-radius: 5px;
  border-color: ${yellowLighter};
  font-size: 14px;
`

export const InputSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: center;
`

export const BodySectionWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
`

export const PlanCard = styled.div`
  display: flex;
  flex: 1 1 0;
  margin: 0 1rem;
  padding: 2rem;
  background-color: lightgrey;
  border-radius: 5px;
`

export const FeatureImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3rem;
`

export const FeatureImageText = styled.div`
  position: absolute;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
  }
`

export const FeatureImage = styled.div`
  display: flex;
  width: 100%;
  height: 566px;
  background: #f3f5ff;
  background-image: url('https://static.buffer.com/images/account/migration-hub-missing-features-02.png');
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
`

export const PlansContactWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  padding-top: 20px;

  font-size: 14px;
  font-weight: 400;
  color: ${grayDark};
`
export const ContactWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  padding: 0 10rem;

  font-size: 14px;
  font-weight: 400;
  color: ${grayDarker};
`

export const SupportLink = styled.a`
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
  color: ${grayDark};
  cursor: pointer;
`
