import React from 'react'
import {
  Redirect,
  Route,
  type RouteChildrenProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom'

import { onboardingPage } from '../routes'
import { isTrialUser, getUrlEnvModifier } from '../shared-utils'
import { useAccount } from '../web/src/context/Account'
import { OnboardingSurveyPage } from '../web/src/pages/OnboardingSurvey/OnboardingSurveyPage'
import { SelectChannelPage } from './components/SelectChannel'
import { SelectProductPage } from './components/SelectProduct'
import { TrialPrompt } from './components/TrialPrompt'
import { getOnboardingConfig } from './config/getOnboardingConfig'
import useShouldShowOnboardingSurvey from './hooks/useShouldShowOnboardingSurvey'
import useShouldShowTrialPrompt from './hooks/useShouldShowTrialPrompt'
import useShouldSkipOnboarding from './hooks/useShouldSkipOnboarding'

const envModifier = getUrlEnvModifier()

const START_PAGE_URL = 'https://start-page.buffer.com/'
const PUBLISH_URL = `https://publish.${
  envModifier || ''
}buffer.com/calendar/week`

export const ProductOnboarding = (): React.JSX.Element | null => {
  const { path } = useRouteMatch()

  const { shouldShowOnboardingSurvey, isReady: isSurveySplitReady } =
    useShouldShowOnboardingSurvey()
  const { shouldShowTrialPrompt, isReady: isTrialPromptReady } =
    useShouldShowTrialPrompt()
  // This is a temporary feature flag to skip onboarding
  const { shouldSkipOnboarding, isReady: isSkipOnboardingSpliReady } =
    useShouldSkipOnboarding()

  const account = useAccount()

  if (
    !isSurveySplitReady ||
    !isTrialPromptReady ||
    !isSkipOnboardingSpliReady
  ) {
    return null
  }

  return (
    <Switch>
      {/* /onboarding page */}
      <Route exact path={path}>
        {(): JSX.Element | null => {
          if (shouldSkipOnboarding) {
            /* only trial users */
            /* Go to Calendar page for Publishing if split is enabled*/
            window.location.replace(PUBLISH_URL)
            return null
          }
          if (shouldShowTrialPrompt) {
            /* currently off */
            return <TrialPrompt />
          }
          if (shouldShowOnboardingSurvey) {
            /* currently off */
            return <Redirect to={onboardingPage.getRoute('survey')} />
          }
          if (isTrialUser(account)) {
            /* Go to Channels Connection page for Publishing */
            return <Redirect to={onboardingPage.getRoute('publishing')} />
          }
          return <SelectProductPage />
        }}
      </Route>

      {/* /onboarding/startpage redirects directly to the Start Page app */}
      <Route path={`${path}/startpage`}>
        {(): React.ReactNode => {
          window.location.replace(START_PAGE_URL)
          return null
        }}
      </Route>
      {/* this is the old analyze onboarding, redirect to the new one */}
      <Route path={`${path}/analyze`}>
        <Redirect to={onboardingPage.getRoute('analytics')} />
      </Route>
      {shouldShowOnboardingSurvey && (
        <Route path={`${path}/survey`}>
          <OnboardingSurveyPage />
        </Route>
      )}
      <Route
        path={`${path}/:product`}
        // eslint-disable-next-line react/no-children-prop
        children={({
          match,
        }: RouteChildrenProps<{ product: string }>): React.JSX.Element => {
          const { product } = match?.params as { product: string }
          const onboardingConfig = getOnboardingConfig(product)
          return onboardingConfig ? (
            <SelectChannelPage onboardingConfig={onboardingConfig} />
          ) : (
            <Redirect to={onboardingPage.route} />
          )
        }}
      />
    </Switch>
  )
}
