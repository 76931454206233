import { type ApolloError, useQuery } from '@apollo/client'
import { GET_BETA_FEATURES } from '../../../apollo/queries/betaFeatures'
import type { BetaFeature } from '../types'

type BetaFeaturesReturn = {
  data: { betaFeatures: BetaFeature[] }
  loading: boolean
  error: ApolloError | undefined
}

const useBetaFeatures = (): BetaFeaturesReturn => {
  const { data, loading, error } = useQuery(GET_BETA_FEATURES)

  return {
    data,
    loading,
    error,
  }
}

export default useBetaFeatures
