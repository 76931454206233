import styled from 'styled-components'
import { white, grayLighter, grayLight } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background: ${white};
  border: 1px solid ${grayLight};
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
`

export const Heading = styled.div`
  display: flex;
  padding: 16px;
  > h3 {
    margin-bottom: 0 !important;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  padding: 6px 7px 0 0;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 16px;
  background: ${grayLighter};

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    padding: 0 16px 20px;
    color: rgb(61, 61, 61);
    font-family: ${fontFamily};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  gap: 8px;
`
