import React from 'react'
import { grayDarker } from '../../styles'

import FacebookIcon from '@bufferapp/ui/Icon/Icons/Facebook'
import GoogleBusinessIcon from '@bufferapp/ui/Icon/Icons/Gmb'
import InstagramIcon from '@bufferapp/ui/Icon/Icons/Instagram'
import LinkedInIcon from '@bufferapp/ui/Icon/Icons/LinkedIn'
import MastodonIcon from '@bufferapp/ui/Icon/Icons/Mastodon'
import PinterestIcon from '@bufferapp/ui/Icon/Icons/Pinterest'
import YoutubeIcon from '@bufferapp/ui/Icon/Icons/Youtube'
import XIcon from '@bufferapp/ui/Icon/Icons/X'
import ThreadsIcon from '@bufferapp/ui/Icon/Icons/Threads'
import TiktokIcon from '@bufferapp/ui/Icon/Icons/Tiktok'
import BlueskyIcon from '@bufferapp/ui/Icon/Icons/Bluesky'

export const getIcon = (icon: string): JSX.Element | null => {
  switch (icon) {
    case 'Instagram':
      return (
        <InstagramIcon
          size="medium"
          color={grayDarker}
          data-testid="instagram-icon"
        />
      )
    case 'YouTube':
      return (
        <YoutubeIcon
          size="medium"
          color={grayDarker}
          data-testid="youtube-icon"
        />
      )
    case 'Facebook':
      return (
        <FacebookIcon
          size="medium"
          color={grayDarker}
          data-testid="facebook-icon"
        />
      )
    case 'Pinterest':
      return (
        <PinterestIcon
          size="medium"
          color={grayDarker}
          data-testid="pinterest-icon"
        />
      )
    case 'Twitter':
      return <XIcon size="medium" color={grayDarker} data-testid="x-icon" />
    case 'Linkedin':
      return (
        <LinkedInIcon
          size="medium"
          color={grayDarker}
          data-testid="linkedin-icon"
        />
      )
    case 'GoogleBusiness':
      return (
        <GoogleBusinessIcon
          size="medium"
          color={grayDarker}
          data-testid="googlebusiness-icon"
        />
      )
    case 'Mastodon':
      return (
        <MastodonIcon
          size="medium"
          color={grayDarker}
          data-testid="mastodon-icon"
        />
      )
    case 'Threads':
      return (
        <ThreadsIcon
          size="medium"
          color={grayDarker}
          data-testid="threads-icon"
        />
      )
    case 'TikTok':
      return (
        <TiktokIcon
          size="medium"
          color={grayDarker}
          data-testid="tiktok-icon"
        />
      )
    case 'Bluesky':
      return (
        <BlueskyIcon
          size="medium"
          color={grayDarker}
          data-testid="bluesky-icon"
        />
      )
    default:
      return null
  }
}
