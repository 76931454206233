import { onboardingPage } from '../../routes'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { setCookie } from '../../web/src/pages/Channels/utils/cookies'
import type { CommonTrackingProperties } from '../../web/src/pages/Channels/auth-flows/shared/SelectChannelList/utils'
import type { CallHistoryMethodAction } from 'connected-react-router'

export function goToProductOnboarding(
  product: string,
  organizationId: string,
  commonTrackingProperties: CommonTrackingProperties | null,
): CallHistoryMethodAction {
  setCookie('OnboardingProduct', product)

  BufferTracker.productSolutionSelected({
    clientName: 'core',
    organizationId,
    product: 'account',
    productSolutionSelected: product,
    ...commonTrackingProperties,
  })

  return onboardingPage.goTo(product)
}
