import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'

import {
  PlanItem,
  IconWrapper,
  ValueWrapper,
  Warning,
  TaxAmountContainer,
} from './styles'
import type { BillingCardProps } from './types'

function renderValueWithTax(
  valueExcludingTax: string,
  taxValue: string,
): JSX.Element {
  return (
    <TaxAmountContainer>
      <Text type="h3">{valueExcludingTax}</Text>
      <Text type="p" color={'grayDark'}>
        Plus Tax ({taxValue})
      </Text>
    </TaxAmountContainer>
  )
}

const BillingCard = ({
  icon,
  title,
  description,
  value,
  isOnTrial,
  isTwoColumn,
  hasCreditCard,
  valueExcludingTax,
  valueOfTax,
}: BillingCardProps): JSX.Element => {
  const shouldDisplayTaxMessaging = valueOfTax !== '$0.00'

  const billingValue: JSX.Element = shouldDisplayTaxMessaging ? (
    renderValueWithTax(valueExcludingTax, valueOfTax)
  ) : (
    <Text type="h3">{value}</Text>
  )

  return (
    <PlanItem isTwoColumn={!!isTwoColumn}>
      <IconWrapper>{icon}</IconWrapper>
      <Text type="p">
        <strong>{title}</strong>
      </Text>
      <Text type="p" color="grayDark">
        {description}
      </Text>
      <ValueWrapper>
        {!hasCreditCard && isOnTrial ? (
          <Warning>
            You won&apos;t be charged unless you choose to subscribe
          </Warning>
        ) : (
          billingValue
        )}
      </ValueWrapper>
    </PlanItem>
  )
}

BillingCard.propTypes = {
  icon: PropTypes.node,
  isTwoColumn: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalItems: PropTypes.number,
  isOnTrial: PropTypes.bool,
  hasCreditCard: PropTypes.bool,
  valueExcludingTax: PropTypes.string,
  valueOfTax: PropTypes.string,
}

export default BillingCard
