// @ts-expect-error TS(7006) FIXME: Parameter 'currentOrganization' implicitly has an ... Remove this comment to see the full error message
export const getCustomerDiscountId = (currentOrganization) => {
  return currentOrganization?.billing?.discount?.gatewayId || null
}

// @ts-expect-error TS(7006) FIXME: Parameter 'currentOrganization' implicitly has an ... Remove this comment to see the full error message
export const getCustomerDiscount = (currentOrganization) => {
  const discount = currentOrganization?.billing?.discount

  if (!discount) {
    return null
  }

  return {
    type: discount?.type,
    value: discount?.value,
    duration: discount?.duration,
    durationInMonths: discount?.durationInMonths,
  }
}
