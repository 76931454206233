import styled from 'styled-components'

export const FeatureWrapper = styled.div`
  width: 305px;
  padding: 12px 16px 16px 16px;
  margin: 12px 0px 14px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

export const Image = styled.img`
  width: 100%;
`

export const Title = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`
