import React from 'react'
import { SimpleModal, Text, Button } from '@bufferapp/ui'

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
} from '../../../../../../account-components'

export default function RemoveSubscriptionScheduleModal({
  onCloseClick,
  onConfirmClick,
  currentPlanName,
  currentChannelCount,
}: {
  onCloseClick: () => void
  onConfirmClick: () => void
  currentPlanName: string
  currentChannelCount: number
}): React.ReactElement {
  return (
    <SimpleModal closeAction={onCloseClick}>
      <ModalContent>
        <ModalHeader padding>
          <h2>Revert to your previous Buffer plan</h2>
        </ModalHeader>
        <ModalBody>
          <Text type="p">
            Are you sure you want to revert to your previous subscription of the{' '}
            <b>{currentPlanName} Plan</b> with <b>{currentChannelCount}</b>{' '}
            channels?
          </Text>
        </ModalBody>
        <ModalFooter padding>
          {/* @ts-expect-error */}
          <Button type="text" label="Cancel" onClick={onCloseClick} />
          {/* @ts-expect-error */}
          <Button type="primary" label="Confirm" onClick={onConfirmClick} />
        </ModalFooter>
      </ModalContent>
    </SimpleModal>
  )
}
