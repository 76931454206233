import React from 'react'
import { Button } from '@bufferapp/ui'

import * as Styled from './styles'
import { SupportHelpDropdown } from '../SupportHelpDropdown/SupportHelpDropdown'
import { useChannelConnections } from '../../../../../context/ChannelConnections/ChannelConnections'
import { ChannelThirdPartyService } from '../../../../../../../channel-connections/interfaces'

export default function Footer({
  onSubmit,
  onClose,
  isDisabled,
}: {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  onClose: (event: React.FormEvent<HTMLFormElement>) => void
  isDisabled: boolean
}): JSX.Element {
  const { selectedService } = useChannelConnections()

  return (
    <Styled.Wrapper>
      <Styled.SupportWrapper>
        <SupportHelpDropdown
          serviceName={selectedService || ChannelThirdPartyService.facebook}
        />
      </Styled.SupportWrapper>
      <Styled.CTAWrapper>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message */}
        <Button
          type="text"
          label={'Cancel'}
          onClick={(event: React.FormEvent<HTMLFormElement>): void =>
            onClose(event)
          }
        />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message */}
        <Button
          type="primary"
          label={'Start Connecting'}
          onClick={(event: React.FormEvent<HTMLFormElement>): void =>
            onSubmit(event)
          }
          disabled={isDisabled}
        />
      </Styled.CTAWrapper>
    </Styled.Wrapper>
  )
}
