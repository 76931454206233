import React, { useState } from 'react'

import { AccordionHeader } from './components/AccordionHeader'
import { AccordionBody } from './components/AccordionBody'
import * as Styles from './styles'
import type { ConnectableService } from '../../../../interfaces'

export const AccordionItem = ({
  service,
  handleChannelSelection,
}: {
  service: ConnectableService
  handleChannelSelection: (arg: { service: string }) => void
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Styles.Wrapper isOpen={isOpen}>
      <AccordionHeader
        service={service}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConnect={handleChannelSelection}
      />
      {isOpen && service.serviceStatus.label !== 'coming_soon' && (
        <AccordionBody service={service} />
      )}
    </Styles.Wrapper>
  )
}
