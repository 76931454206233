import { useSplitEnabled } from '@bufferapp/features'
import { useSelector } from 'react-redux'
import { getSignUpDateInEpochsMs, isTrialUser } from '../../shared-utils'

export default function useShouldSkipOnboarding(): {
  shouldSkipOnboarding?: boolean
  isReady: boolean
} {
  const account = useSelector((state) => state.account)
  const epochsMs = getSignUpDateInEpochsMs(account)
  const isTrialActive = isTrialUser(account)

  const { isEnabled: isSplitEnaled, isReady: isSplitReady } = useSplitEnabled(
    'geid-skip-onboarding',
    {
      signUpDate: epochsMs,
      email: account.email,
    },
  )

  const isReady = isSplitReady && !account.isLoading
  const shouldSkipOnboarding = isSplitEnaled && isTrialActive

  return { shouldSkipOnboarding, isReady }
}
