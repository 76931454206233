import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { middleware as asyncDataFetchMiddleware } from '@bufferapp/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import { middleware as notificationsMiddleware } from '@bufferapp/notifications'
import { middleware as environmentMiddleware } from '../environment'
import accountMiddleware from '../web/src/middleware'
import { middleware as settingsMiddleware } from '../settings'
import createRootReducer from './reducers'
import { middleware as billing } from '../billing-details'
import { middleware as cancelPlan } from '../cancel-plan'
import { middleware as centralizedBilling } from '../centralized-billing'
import { middleware as channelList } from '../channel-list'
import { middleware as analyticsMiddleware } from '@bufferapp/analytics-middleware'
import { middleware as deleteAccountMiddleware } from '../delete-account'

const composeEnhancers =
  // @ts-expect-error TS(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // @ts-expect-error TS(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

// @ts-expect-error TS(7006) FIXME: Parameter 'history' implicitly has an 'any' type.
export const composedMiddlewares = (history) =>
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      asyncDataFetchMiddleware,
      notificationsMiddleware,
      environmentMiddleware,
      accountMiddleware,
      settingsMiddleware,
      billing,
      channelList,
      analyticsMiddleware,
      deleteAccountMiddleware,
      cancelPlan,
      centralizedBilling,
    ),
  )

export const history = createBrowserHistory()

// @ts-expect-error TS(7006) FIXME: Parameter 'initialstate' implicitly has an 'any' t... Remove this comment to see the full error message
export const configureStore = (initialstate) =>
  createStore(
    createRootReducer(history),
    initialstate,
    composedMiddlewares(history),
  )

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
const store = configureStore()
export default store
