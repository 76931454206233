import { connect } from 'react-redux'
import { actions } from '../../reducer'

import App from './App'

export default connect(null, (dispatch) => ({
  openCreditCardFormModal: () => {
    dispatch(actions.openCreditCardFormModal())
  },
}))(App)
