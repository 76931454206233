import styled from 'styled-components'

import { blue, grayLight } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${grayLight};
  border-radius: 4px;
`

export const Section = styled.div`
  padding: 24px;
  span {
    font-weight: 700;
  }
  a {
    text-decoration: none;
    color: ${blue};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
  }
`
