import styled, { css } from 'styled-components'
import { blue, grayLighter } from '@bufferapp/ui/style/colors'

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 2rem;
`

export const SectionContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  margin-top: 2rem;
  padding: 1rem;

  border: 3px solid ${grayLighter};
  border-radius: 8px;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2 1 0;
  margin: 0 1rem;
`

export const InputWrapper = styled.div<{
  isSmall?: boolean
  isLarge?: boolean
}>`
  display: flex;
  flex: 2 1.3 0;
  flex-direction: column;
  margin: 0 1rem;
  ${(props) =>
    props.isSmall &&
    css`
      max-width: 175px;
    `}

  ${(props) =>
    props.isLarge &&
    css`
      min-width: 280px;
    `}
`

export const InputTitle = styled.div`
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: baseline;
`

export const InputText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  span {
    color: ${blue};
  }
`
