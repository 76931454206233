import React from 'react'
import { Loader } from '@bufferapp/ui'
import { Overlay, LoaderContainer } from './styles'

const LoaderWithOverlay = () => {
  return (
    <Overlay>
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    </Overlay>
  )
}

export default LoaderWithOverlay
