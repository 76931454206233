import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useSplitEnabled } from '@bufferapp/features'
import { Button, Input, Text } from '@bufferapp/ui'
import { Notice } from '@buffer-mono/popcorn'
import SearchIcon from '@bufferapp/ui/Icon/Icons/Search'

import { useAccount } from '../../../../context/Account'
import { getRedirectURLCookie } from '../../../../pages/Channels/utils/cookies'
import {
  trackChannelConnectionAborted,
  trackProfileSelectorViewed,
} from '../../tracking'
import Footer from '../components/Footer/Footer'
import InputField from '../components/InputField/InputField'

import { getServiceName } from '../../../../pages/Channels/utils/channels'
import {
  ChannelType,
  type ConnectableChannelData,
} from '../../../../hooks/channel-connections/types'
import * as Styled from './styles'
import styles from './ChannelSelection.module.css'
import type { IChannelSelection } from './types'
import {
  getOnlyAvailableChannels,
  isChannelSelected,
  shouldAutoSelectChannel,
} from './utils'
import { Service } from '../../../../../../channel-connections/interfaces'
import { NoChannelsErrorBodyCopies } from '../../../../pages/Channels/OAuthFlow/ErrorMessage/NoChannelsErrorCopies'

export default function ChannelSelection({
  service,
  connectableChannels,
  onSubmit,
}: IChannelSelection): JSX.Element {
  const account = useAccount()
  const defaultSelectedChannel = shouldAutoSelectChannel(connectableChannels)
    ? [connectableChannels[0]]
    : []
  const [selectedChannels, setSelectedChannels] = useState<
    ConnectableChannelData[]
  >(defaultSelectedChannel)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectAll, setSelectAll] = useState<boolean>(false)

  const redirectURL = getRedirectURLCookie() || '/channels/connect'
  const isDisabled = !selectedChannels || selectedChannels.length < 1

  const { isEnabled: isNewChannelConnectionUIEnabled } = useSplitEnabled(
    'GROWTH-new-channel-connections-ui',
  )

  const serviceName = getServiceName(service)

  const shouldDisplaySearchField =
    connectableChannels.length >= 9 && isNewChannelConnectionUIEnabled

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    onSubmit(selectedChannels)
  }

  function handleSelection({
    channel,
    isSelected,
  }: {
    channel: ConnectableChannelData
    isSelected: boolean
  }): void {
    const selectedChannelsSet = new Set<ConnectableChannelData>(
      selectedChannels,
    )

    if (isSelected) {
      selectedChannelsSet.add(channel)
    } else {
      selectedChannelsSet.delete(channel)
    }

    setSelectedChannels([...selectedChannelsSet])
  }

  function handleToggleSelectAllChannels(newValue: boolean): void {
    setSelectAll(newValue)

    if (!newValue) return setSelectedChannels([])

    const avilableFilteredChannels = getOnlyAvailableChannels(filteredChannels)

    const allSelectedChannelsSet = new Set<ConnectableChannelData>(
      avilableFilteredChannels,
    )

    setSelectedChannels([...allSelectedChannelsSet])
  }

  function handleOnCancel(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    window.location.href = redirectURL
    trackChannelConnectionAborted({
      account,
      serviceName: service,
    })
  }

  const filterChannels = useCallback((): ConnectableChannelData[] => {
    if (searchValue.length < 1) {
      return connectableChannels
    }

    const regex = new RegExp(`${searchValue}`, 'i')
    const newlyFilteredChannels: ConnectableChannelData[] =
      connectableChannels.filter(
        (channel) =>
          channel.name.match(regex) || channel.displayName?.match(regex),
      )

    return newlyFilteredChannels
  }, [connectableChannels, searchValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value)
  }

  const debouncedFilteredChannels = useMemo(() => {
    return debounce(handleChange, 250)
  }, [])

  useEffect(() => {
    trackProfileSelectorViewed({ account, serviceName: service })

    return () => {
      debouncedFilteredChannels.cancel()
    }
  }, [])

  const filteredChannels = useMemo(() => filterChannels(), [filterChannels])
  const showLinkedInWarning =
    service === Service.linkedin &&
    filteredChannels.filter((channel) => channel.type === ChannelType.page)
      .length === 0

  return (
    <Styled.ContentWrapper role="dialog">
      <form onSubmit={(event): void => handleSubmit(event)}>
        <Styled.FieldSet>
          <legend>
            <Text type="h3">Confirm your {serviceName} account</Text>
          </legend>
          {shouldDisplaySearchField && (
            <Styled.FilteringWrapper>
              <Styled.SearchWrapper>
                <label htmlFor="search-channels">Search Channels</label>
                <Input
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error TS(2322) FIXME: Type '{ id: string; label: string; value: string; ... Remove this comment to see the full error message
                  onChange={debouncedFilteredChannels}
                  name="search-channels"
                  id="search-channels"
                  placeholder="Search channels"
                  icon={<SearchIcon size="large" />}
                />
              </Styled.SearchWrapper>
              <Styled.SelectAllWrapper>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message */}
                <Button
                  type="text"
                  label={selectAll ? 'Deselect All' : 'Select All'}
                  onClick={(event: React.FormEvent<HTMLFormElement>): void => {
                    event.preventDefault()
                    handleToggleSelectAllChannels(!selectAll)
                  }}
                  fullWidth
                />
              </Styled.SelectAllWrapper>
            </Styled.FilteringWrapper>
          )}
          <Styled.ListWrapper isSearchable={shouldDisplaySearchField}>
            {filteredChannels.map(
              (channel: ConnectableChannelData, index: number) => {
                return (
                  <InputField
                    key={`${channel.displayName}--0${index}`}
                    name={channel.displayName || channel.name}
                    value={channel}
                    hanleOnChange={handleSelection}
                    service={service}
                    serviceType={channel.type}
                    location={channel.locationData?.location}
                    isSelected={isChannelSelected(channel, selectedChannels)}
                  />
                )
              },
            )}
          </Styled.ListWrapper>
          {showLinkedInWarning && (
            <Notice className={styles.noticeWithoutBorderRadius} variant="info">
              {NoChannelsErrorBodyCopies[service]}
            </Notice>
          )}
        </Styled.FieldSet>
        <Footer
          onSubmit={handleSubmit}
          onClose={handleOnCancel}
          isDisabled={isDisabled}
        />
      </form>
    </Styled.ContentWrapper>
  )
}
