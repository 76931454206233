import styled from 'styled-components'

export const PageWrapper = styled.div`
  width: 100%;
  padding: 16px;
  position: relative;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BlockWrapper = styled.div`
  width: 512px;
  margin-bottom: 24px;
`

export const TitleWrapper = styled.div`
  margin-bottom: 8px;
`
