import React from 'react'

import InstagramIcon from '@bufferapp/ui/Icon/Icons/Instagram'
import FacebookIcon from '@bufferapp/ui/Icon/Icons/Facebook'
import LinkedInIcon from '@bufferapp/ui/Icon/Icons/LinkedIn'
import PinterestIcon from '@bufferapp/ui/Icon/Icons/Pinterest'
import ShopifyIcon from '@bufferapp/ui/Icon/Icons/Shopify'
import XIcon from '@bufferapp/ui/Icon/Icons/X'
import TiktokIcon from '@bufferapp/ui/Icon/Icons/Tiktok'
import GbpIcon from '@bufferapp/ui/Icon/Icons/Gbp'
import MastodonIcon from '@bufferapp/ui/Icon/Icons/Mastodon'
import BufferIcon from '@bufferapp/ui/Icon/Icons/Buffer'
import YoutubeIcon from '@bufferapp/ui/Icon/Icons/Youtube'
import ThreadsIcon from '@bufferapp/ui/Icon/Icons/Threads'

import {
  blue,
  facebook,
  googlebusiness,
  instagram,
  linkedin,
  mastodon,
  pinterest,
  shopify,
  threads,
  tiktok,
  twitter,
  youtube,
} from '@bufferapp/ui/style/colors'

interface SocialIconProps {
  serviceName: string
  size: string
  useColor: boolean
}

interface ChannelIcons {
  [key: string]: {
    Icon: (props: any) => React.FunctionComponentElement<any>
    color: string
  }
}

export const SocialIcon = ({
  serviceName,
  size,
  useColor,
}: SocialIconProps): JSX.Element | null => {
  const channelIcons: ChannelIcons = {
    instagram: { Icon: InstagramIcon, color: instagram },
    facebook: { Icon: FacebookIcon, color: facebook },
    linkedin: { Icon: LinkedInIcon, color: linkedin },
    pinterest: { Icon: PinterestIcon, color: pinterest },
    shopify: { Icon: ShopifyIcon, color: shopify },
    twitter: { Icon: XIcon, color: twitter },
    tiktok: { Icon: TiktokIcon, color: tiktok },
    googlebusiness: { Icon: GbpIcon, color: googlebusiness },
    mastodon: { Icon: MastodonIcon, color: mastodon },
    buffer: { Icon: BufferIcon, color: blue },
    youtube: { Icon: YoutubeIcon, color: youtube },
    threads: { Icon: ThreadsIcon, color: threads },
    bluesky: { Icon: ThreadsIcon, color: threads },
  }
  const channel = serviceName.toLowerCase()
  const { Icon, color } = channelIcons[channel] || {}

  if (!Icon) return null

  return (
    // Use the imported Icon component
    <Icon
      data-testid="social-icon"
      size={size}
      color={useColor ? color : null}
    />
  )
}
