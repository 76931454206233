import styled, { css } from 'styled-components'
import { grayDark } from '../../styles'

/**
 *
 */
const checked = css`
  text-decoration: line-through;

  svg {
    display: inline-block;
  }
`

/**
 *
 */
export const PasswordFieldChecksStyled = styled.div`
  list-style-type: none;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  margin: 8px 0px;
  padding: 0px;
  width: 100%;
`

export const Check = styled.li<{
  checked: boolean
}>`
  margin-bottom: 8px;

  svg {
    display: none;
  }
  ${(props) => props.checked && checked};
`

export const CheckText = styled.span`
  text-decoration: none;
  color: ${grayDark};
  margin-right: 8px;
`
