import styled from 'styled-components'

export const List = styled.ol`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  width: 80%;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  li {
    flex-shrink: 1;
    margin: 0 8px;
    width: 33%;
    min-width: 250px;
    max-width: 332px;
    height: 296px;
    text-align: center;
    @media only screen and (max-width: 800px) {
      width: 250px;
      margin-bottom: var(--space-200);
    }
  }
`

export const SkipButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem;
`
