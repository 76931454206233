import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_CONNECTABLE_SERVICES } from '../../../web/src/apollo/queries/channels'
import { AccordionItem } from './components/AccordionItem'
import { NavFilters } from './components/NavFilters/NavFilters'
import { RequestChannel } from './components/RequestChannel'
import type { ConnectableService } from '../../interfaces'
import { Loader } from '@bufferapp/ui'
import styled from 'styled-components'
import { useAccount } from '../../../web/src/context/Account'
import { ChannelConnectFooter } from '../../../web/src/pages/Channels/ConnectChannelsPage/components'

export const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
`

export const ChannelStorefront = ({
  handleChannelSelection,
  organizationId,
}: {
  handleChannelSelection: (arg: { service: string }) => void
  organizationId: string
}): JSX.Element => {
  const account = useAccount()
  const [filteredServices, setFilteredServices] = useState<
    ConnectableService[]
  >([])

  const { data, loading } = useQuery(GET_CONNECTABLE_SERVICES, {
    variables: {
      organization: organizationId,
      client: 'web',
    },
  })
  const hasConnectedChannels = !!account.channels?.length
  const allServices = data?.connectableServices?.services

  const isLoading = Boolean(loading && filteredServices.length === 0)

  useEffect(() => {
    if (data?.connectableServices?.services) {
      setFilteredServices(data?.connectableServices?.services)
    }
  }, [data])

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loader />
      </LoadingWrapper>
    )
  }

  return (
    <>
      <NavFilters
        allServices={allServices}
        setFilteredServices={setFilteredServices}
      />

      {filteredServices.map((service: ConnectableService) => (
        <AccordionItem
          service={service}
          key={service.title}
          handleChannelSelection={handleChannelSelection}
        />
      ))}
      <RequestChannel />
      {hasConnectedChannels && <ChannelConnectFooter />}
    </>
  )
}
