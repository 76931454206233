import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100vw;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
`

export const Title = styled.div`
  width: 750px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 8px;
`

export const SubTitle = styled.div`
  width: 575px;
  margin-top: 8px;
  text-align: center;
  color: #636363;
`

export const Pricing = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 606px;
`

export const Features = styled.div`
  width: 980px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 16px 0px;
`

export const ButtonWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`
