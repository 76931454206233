import React from 'react'

export const UpgradeIllustrationMastodon = () => (
  <svg
    width="344"
    height="490"
    viewBox="0 0 344 490"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31 0H336C340.418 0 344 3.58172 344 8V482C344 486.418 340.418 490 336 490H31V0Z"
      fill="#EBD5FF"
    />
    <g clipPath="url(#clip0_1289_53492)">
      <path
        d="M162.922 311.828H390.04C392.769 311.828 394.981 314.04 394.981 316.769V463.887C394.981 466.616 392.769 468.828 390.04 468.828H162.922C160.193 468.828 157.981 466.616 157.981 463.887V316.769C157.981 314.04 160.193 311.828 162.922 311.828Z"
        fill="white"
      />
      <path
        d="M333.393 359.828H346.569C350.662 359.828 353.981 363.147 353.981 367.24V380.416C353.981 384.51 350.662 387.828 346.569 387.828H333.393C329.299 387.828 325.981 384.51 325.981 380.416V367.24C325.981 363.147 329.299 359.828 333.393 359.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M302.393 359.828H315.569C319.662 359.828 322.981 363.147 322.981 367.24V380.416C322.981 384.51 319.662 387.828 315.569 387.828H302.393C298.299 387.828 294.981 384.51 294.981 380.416V367.24C294.981 363.147 298.299 359.828 302.393 359.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M271.393 359.828H284.569C288.662 359.828 291.981 363.147 291.981 367.24V380.416C291.981 384.51 288.662 387.828 284.569 387.828H271.393C267.299 387.828 263.981 384.51 263.981 380.416V367.24C263.981 363.147 267.299 359.828 271.393 359.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M240.393 359.828H253.569C257.662 359.828 260.981 363.147 260.981 367.24V380.416C260.981 384.51 257.662 387.828 253.569 387.828H240.393C236.299 387.828 232.981 384.51 232.981 380.416V367.24C232.981 363.147 236.299 359.828 240.393 359.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M209.393 359.828H222.569C226.662 359.828 229.981 363.147 229.981 367.24V380.416C229.981 384.51 226.662 387.828 222.569 387.828H209.393C205.299 387.828 201.981 384.51 201.981 380.416V367.24C201.981 363.147 205.299 359.828 209.393 359.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M178.393 359.828H191.569C195.662 359.828 198.981 363.147 198.981 367.24V380.416C198.981 384.51 195.662 387.828 191.569 387.828H178.393C174.299 387.828 170.981 384.51 170.981 380.416V367.24C170.981 363.147 174.299 359.828 178.393 359.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M333.393 325.828H346.569C350.662 325.828 353.981 329.147 353.981 333.24V345.416C353.981 349.51 350.662 352.828 346.569 352.828H333.393C329.299 352.828 325.981 349.51 325.981 345.416V333.24C325.981 329.147 329.299 325.828 333.393 325.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M302.393 325.828H315.569C319.662 325.828 322.981 329.147 322.981 333.24V345.416C322.981 349.51 319.662 352.828 315.569 352.828H302.393C298.299 352.828 294.981 349.51 294.981 345.416V333.24C294.981 329.147 298.299 325.828 302.393 325.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M271.393 325.828H284.569C288.662 325.828 291.981 329.147 291.981 333.24V345.416C291.981 349.51 288.662 352.828 284.569 352.828H271.393C267.299 352.828 263.981 349.51 263.981 345.416V333.24C263.981 329.147 267.299 325.828 271.393 325.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M240.393 325.828H253.569C257.662 325.828 260.981 329.147 260.981 333.24V345.416C260.981 349.51 257.662 352.828 253.569 352.828H240.393C236.299 352.828 232.981 349.51 232.981 345.416V333.24C232.981 329.147 236.299 325.828 240.393 325.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M209.393 325.828H222.569C226.662 325.828 229.981 329.147 229.981 333.24V345.416C229.981 349.51 226.662 352.828 222.569 352.828H209.393C205.299 352.828 201.981 349.51 201.981 345.416V333.24C201.981 329.147 205.299 325.828 209.393 325.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M178.393 325.828H191.569C195.662 325.828 198.981 329.147 198.981 333.24V345.416C198.981 349.51 195.662 352.828 191.569 352.828H178.393C174.299 352.828 170.981 349.51 170.981 345.416V333.24C170.981 329.147 174.299 325.828 178.393 325.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M333.393 393.828H346.569C350.662 393.828 353.981 397.147 353.981 401.24V414.416C353.981 418.51 350.662 421.828 346.569 421.828H333.393C329.299 421.828 325.981 418.51 325.981 414.416V401.24C325.981 397.147 329.299 393.828 333.393 393.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M333.393 428H346.569C350.662 428 353.981 431.318 353.981 435.412V448.588C353.981 452.682 350.662 456 346.569 456H333.393C329.299 456 325.981 452.682 325.981 448.588V435.412C325.981 431.318 329.299 428 333.393 428Z"
        fill="#9C2BFF"
      />
      <path
        d="M302.393 393.828H315.569C319.662 393.828 322.981 397.147 322.981 401.24V414.416C322.981 418.51 319.662 421.828 315.569 421.828H302.393C298.299 421.828 294.981 418.51 294.981 414.416V401.24C294.981 397.147 298.299 393.828 302.393 393.828Z"
        fill="#9C2BFF"
      />
      <path
        d="M302.393 428H315.569C319.662 428 322.981 431.318 322.981 435.412V448.588C322.981 452.682 319.662 456 315.569 456H302.393C298.299 456 294.981 452.682 294.981 448.588V435.412C294.981 431.318 298.299 428 302.393 428Z"
        fill="#9C2BFF"
      />
      <path
        d="M271.393 393.828H284.569C288.662 393.828 291.981 397.147 291.981 401.24V414.416C291.981 418.51 288.662 421.828 284.569 421.828H271.393C267.299 421.828 263.981 418.51 263.981 414.416V401.24C263.981 397.147 267.299 393.828 271.393 393.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M271.393 427.828H284.569C288.662 427.828 291.981 431.147 291.981 435.24V448.416C291.981 452.51 288.662 455.828 284.569 455.828H271.393C267.299 455.828 263.981 452.51 263.981 448.416V435.24C263.981 431.147 267.299 427.828 271.393 427.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M240.393 393.828H253.569C257.662 393.828 260.981 397.147 260.981 401.24V414.416C260.981 418.51 257.662 421.828 253.569 421.828H240.393C236.299 421.828 232.981 418.51 232.981 414.416V401.24C232.981 397.147 236.299 393.828 240.393 393.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M240.393 427.828H253.569C257.662 427.828 260.981 431.147 260.981 435.24V448.416C260.981 452.51 257.662 455.828 253.569 455.828H240.393C236.299 455.828 232.981 452.51 232.981 448.416V435.24C232.981 431.147 236.299 427.828 240.393 427.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M209.393 393.828H222.569C226.662 393.828 229.981 397.147 229.981 401.24V414.416C229.981 418.51 226.662 421.828 222.569 421.828H209.393C205.299 421.828 201.981 418.51 201.981 414.416V401.24C201.981 397.147 205.299 393.828 209.393 393.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M209.393 427.828H222.569C226.662 427.828 229.981 431.147 229.981 435.24V448.416C229.981 452.51 226.662 455.828 222.569 455.828H209.393C205.299 455.828 201.981 452.51 201.981 448.416V435.24C201.981 431.147 205.299 427.828 209.393 427.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M178.393 393.828H191.569C195.662 393.828 198.981 397.147 198.981 401.24V414.416C198.981 418.51 195.662 421.828 191.569 421.828H178.393C174.299 421.828 170.981 418.51 170.981 414.416V401.24C170.981 397.147 174.299 393.828 178.393 393.828Z"
        fill="#EBD5FF"
      />
      <path
        d="M178.393 427.828H191.569C195.662 427.828 198.981 431.147 198.981 435.24V448.416C198.981 452.51 195.662 455.828 191.569 455.828H178.393C174.299 455.828 170.981 452.51 170.981 448.416V435.24C170.981 431.147 174.299 427.828 178.393 427.828Z"
        fill="#EBD5FF"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.383 170.256C103.246 170.504 102.934 170.606 102.677 170.484C102.402 170.355 102.283 170.028 102.412 169.753C102.457 169.649 102.549 169.528 102.668 169.415C102.742 169.349 102.852 169.262 102.943 169.192C103.026 168.987 103.227 168.841 103.457 168.841C103.759 168.841 103.887 168.988 103.952 169.075C104.071 169.228 104.126 169.425 104.016 169.666C103.979 169.736 103.878 169.866 103.713 169.991C103.622 170.064 103.42 170.231 103.383 170.256Z"
      fill="#FFC6AB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.622 167.601C102.448 167.734 102.191 167.749 101.999 167.619C101.669 167.406 101.641 167.165 101.641 167.065C101.641 166.84 101.733 166.59 102.091 166.494C102.164 166.473 102.301 166.46 102.466 166.472C102.641 166.485 102.879 166.528 102.943 166.528C103.246 166.528 103.493 166.775 103.493 167.078C103.493 167.383 103.246 167.628 102.943 167.628C102.906 167.628 102.76 167.616 102.622 167.601Z"
      fill="#FFC6AB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M252.504 251.774C252.504 251.774 266.869 303.748 237.203 312.099C224.645 315.635 192.666 319.104 175.074 312.099C157.304 305.02 125.058 263.053 121.965 262.038C105.526 256.644 121.11 284.315 115.072 306.793C106.44 338.937 85.2798 350.367 72.9448 360.828L52.9813 348.019L107.615 233.157C128.196 208.152 164.13 239.016 195.261 260.191L200.934 252.121L252.504 251.774Z"
      fill="#3D3D3D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M306.46 311.897H344C344 311.897 309.486 287.38 308.236 286.054C307.843 285.638 302.752 287.682 297.832 289.796C297.81 289.79 297.788 289.786 297.767 289.784L283 295.076C284.942 300.666 286.668 305.553 288.523 311.897C288.523 311.897 291.716 319.924 296.354 330.968C299.805 339.185 308.073 350.326 307.893 327.241C307.85 321.663 307.294 316.498 306.46 311.897Z"
      fill="#B76E4C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M316.26 285.577L329.05 294.439L281.383 303.946L279.75 297.205L316.26 285.577Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.06 172.271L140.534 162.426C140.534 162.426 137.585 137.153 119.563 124.21C101.541 111.267 120.681 137.902 119.563 139.916C118.445 141.929 93.6146 106.67 88.6266 111.843C83.6497 117.016 108.776 145.253 108.776 145.253C108.776 145.253 82.9152 122.136 79.8018 129.997C78.7713 132.602 93.8777 144.941 99.0849 151.773C100.96 154.231 78.9687 136.116 79.1112 136.543C85.0529 153.791 94.1408 161.616 95.5221 163.476C103.941 174.874 109.96 182.071 109.96 182.071L155.06 172.271Z"
      fill="#B76E4C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.112 271.767C267.352 269.41 207.163 309.225 207.485 314.62C207.743 319.146 138.767 293.077 139.221 298.118C141.536 324.065 142.227 380.241 146.578 405.869C146.578 405.869 130.436 417.248 128.881 412.065C122.735 391.562 107.028 345.205 99.1798 322.031C96.3071 313.567 94.4839 308.194 94.5512 308.297C79.0455 269.017 103.511 254.582 120.506 260.438C161.906 274.707 199.112 271.767 199.112 271.767Z"
      fill="#3D3D3D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.75 358.435L118.57 384.811L132.71 421.842L152.258 405.565L145.75 358.435Z"
      fill="white"
    />
    <path
      d="M145.902 358.652L116.753 385.645"
      stroke="#9C2BFF"
      strokeWidth="2.05928"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.4717 365.342L48.9523 351.961"
      stroke="#9C2BFF"
      strokeWidth="2.05928"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.181 376.49L159.486 446.472C159.486 446.472 146.68 454.001 136.296 430.939C133.174 423.989 128.371 411.504 128.929 410.995C148.163 393.421 142.532 376.908 142.532 376.908L160.181 376.49Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.7639 386.549L15.1512 413.42C15.1512 413.42 3.19419 404.604 20.313 385.99C25.4754 380.386 35.0656 371.062 35.7527 371.375C59.4725 382.153 72.4394 370.484 72.4394 370.484L79.7639 386.549Z"
      fill="black"
    />
    <path
      d="M122.145 260.9C122.145 260.9 90.9169 260.638 89.6225 281.665C88.4114 301.266 100.027 322.693 104.774 340.758"
      stroke="white"
      strokeWidth="1.02757"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.981 411.877C124.981 411.877 84.8995 400.498 89.3223 415.891C93.7571 431.286 124.981 411.877 124.981 411.877Z"
      stroke="black"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.4199 368.604C33.4199 368.604 28.3216 326.907 15.5146 337.225C2.7112 347.556 33.4199 368.604 33.4199 368.604Z"
      stroke="black"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.415 368.646C37.415 368.646 -0.464431 350.492 1.33457 366.84C3.1453 383.191 37.415 368.646 37.415 368.646Z"
      stroke="black"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M163.981 306.828C163.981 306.828 131.472 277.495 114.981 248.828"
      stroke="white"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.4814 384.828C34.5484 384.828 32.9814 383.261 32.9814 381.328C32.9814 379.395 34.5484 377.828 36.4814 377.828C38.4144 377.828 39.9814 379.395 39.9814 381.328C39.9814 383.261 38.4144 384.828 36.4814 384.828Z"
      fill="white"
      stroke="white"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M140.481 416.828C138.548 416.828 136.981 415.261 136.981 413.328C136.981 411.395 138.548 409.828 140.481 409.828C142.414 409.828 143.981 411.395 143.981 413.328C143.981 415.261 142.414 416.828 140.481 416.828Z"
      fill="white"
      stroke="white"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M204.981 264.828L190.981 256.828"
      stroke="white"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M240.76 188.581L239.79 183.458L226.094 186.578C215.391 191.371 207.077 197.074 191.32 199.942C182.933 201.04 161.528 185.758 151.589 162.828L87.9813 170.32C107.071 196.623 152.923 227.43 172.655 228.945C188.169 230.135 201.138 219.224 217.816 202.973L200.665 251.786L252.638 251.366L247.147 222.311L276.697 301.828C276.697 301.828 317.2 286.247 318.981 285.69C318.983 285.681 256.553 211.507 240.844 188.496C240.808 188.448 240.784 188.481 240.76 188.581Z"
      fill="#9C2BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M225.589 150.986C223.802 152.39 226.738 160.758 218.105 166.306C206.671 169.227 203.159 177.32 202.981 181.909C202.981 181.909 224.711 183.599 243.618 184.998C257.572 186.03 269.978 186.904 270.981 186.823C268.713 173.568 259.212 174.952 259.202 163.299C259.192 149.936 253.276 140.851 241.287 139.886C230.146 138.989 228.369 148.798 225.589 150.986Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M243.981 162.828L240.728 188.569C240.728 188.569 231.846 194.636 225.981 186.885L228.63 167.203L243.981 162.828Z"
      fill="#B76E4C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M242.841 164.212L226.577 169.064C226.577 169.064 220.924 171.185 227.086 174.102C230.884 175.898 240.626 172.304 242.841 164.212Z"
      fill="black"
    />
    <path
      d="M232.981 170.828C226.354 170.828 220.981 165.008 220.981 157.828C220.981 150.648 226.354 144.828 232.981 144.828C239.609 144.828 244.981 150.648 244.981 157.828C244.981 165.008 239.609 170.828 232.981 170.828Z"
      fill="#B76E4C"
    />
    <path
      d="M237.981 163.828C237.981 163.828 235.548 168.552 230.981 166.144"
      stroke="black"
      strokeWidth="1.02944"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M244.481 160.828C242.548 160.828 240.981 159.037 240.981 156.828C240.981 154.619 242.548 152.828 244.481 152.828C246.414 152.828 247.981 154.619 247.981 156.828C247.981 159.037 246.414 160.828 244.481 160.828Z"
      fill="#B76E4C"
    />
    <path
      d="M225.981 158.828L218.981 160.899L225.981 162.828"
      stroke="black"
      strokeWidth="1.02944"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M231.981 158.828C231.429 158.828 230.981 158.38 230.981 157.828C230.981 157.276 231.429 156.828 231.981 156.828C232.534 156.828 232.981 157.276 232.981 157.828C232.981 158.38 232.534 158.828 231.981 158.828Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M242.441 157.828L238.575 157.22L238.792 151.129L234.698 156.496L220.981 153.989C220.981 153.989 223.597 139.418 238.048 139.837C250.441 140.198 242.441 157.828 242.441 157.828Z"
      fill="black"
    />
    <path
      d="M250.981 137.828C247.115 137.828 243.981 134.694 243.981 130.828C243.981 126.962 247.115 123.828 250.981 123.828C254.847 123.828 257.981 126.962 257.981 130.828C257.981 134.694 254.847 137.828 250.981 137.828Z"
      fill="black"
    />
    <path
      d="M273.977 186.828C273.977 186.828 274.244 184.413 270.55 177.898C268.508 174.301 261.679 171.489 261.436 166.312C260.682 150.184 258.847 149.17 257.231 147.021C251.288 139.067 237.981 139.849 237.981 139.849"
      stroke="black"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="83.3598" cy="100.359" r="58.3589" fill="#9C2BFF" />
    <g clipPath="url(#clip1_1289_53492)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.9988 115.659C102.348 114.786 108.747 110.281 109.551 106.164C110.818 99.6793 110.714 90.3388 110.714 90.3388C110.714 77.679 102.377 73.9682 102.377 73.9682C98.1741 72.0475 90.9564 71.2397 83.4578 71.1787H83.2736C75.7749 71.2397 68.562 72.0475 64.3584 73.9682C64.3584 73.9682 56.0217 77.679 56.0217 90.3388C56.0217 91.1063 56.0177 91.9136 56.0136 92.7539C56.0021 95.0878 55.9893 97.6771 56.0571 100.38C56.3599 112.76 58.3384 124.962 69.8431 127.992C75.1477 129.389 79.702 129.681 83.3699 129.481C90.0217 129.114 93.7556 127.119 93.7556 127.119L93.5363 122.317C93.5363 122.317 88.7829 123.808 83.4445 123.627C78.1554 123.446 72.5719 123.059 71.7164 116.598C71.6374 116.03 71.5979 115.424 71.5979 114.786C71.5979 114.786 76.79 116.049 83.3699 116.349C87.3934 116.533 91.1666 116.114 94.9988 115.659ZM100.88 106.65V91.321C100.88 88.1881 100.079 85.6985 98.4686 83.8566C96.8077 82.0148 94.6329 81.0706 91.9335 81.0706C88.8097 81.0706 86.4444 82.265 84.8808 84.6543L83.3601 87.1901L81.8398 84.6543C80.2759 82.265 77.9105 81.0706 74.7871 81.0706C72.0874 81.0706 69.9126 82.0148 68.252 83.8566C66.6416 85.6985 65.8399 88.1881 65.8399 91.321V106.65H71.9436V91.7715C71.9436 88.6352 73.2699 87.0433 75.9228 87.0433C78.856 87.0433 80.3264 88.9317 80.3264 92.6657V100.81H86.3942V92.6657C86.3942 88.9317 87.8642 87.0433 90.7975 87.0433C93.4504 87.0433 94.7767 88.6352 94.7767 91.7715V106.65H100.88Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.538 115.975C139.19 109.648 134.882 105.209 131.591 112.926C125.128 128.131 128.972 167.488 102.764 162.197C96.0353 160.838 130.501 165.329 130.501 165.329L151.501 162.829L140.538 115.975Z"
      fill="#B76E4C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.796 111.307C135.796 111.307 132.932 110.934 131.964 115.962C131.482 118.464 132.785 118.856 132.785 118.856C133.484 119.347 136.621 119.585 138.839 119.699"
      fill="#B76E4C"
    />
    <path
      d="M135.796 111.307C135.796 111.307 132.932 110.934 131.964 115.962C131.482 118.464 132.785 118.856 132.785 118.856C133.484 119.347 136.621 119.585 138.839 119.699"
      stroke="black"
      strokeWidth="1.022"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M131.562 135.828C131.562 135.828 121.496 141.808 120.002 150.983"
      stroke="black"
      strokeWidth="1.02902"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_1289_53492">
        <rect
          width="186"
          height="157"
          fill="white"
          transform="translate(158 312)"
        />
      </clipPath>
      <clipPath id="clip1_1289_53492">
        <rect
          width="58.3588"
          height="58.3588"
          fill="white"
          transform="translate(54.1807 71.1787)"
        />
      </clipPath>
    </defs>
  </svg>
)
