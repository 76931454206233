import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { useAccount, useEntitlements } from '../../../../context/Account'
import { ChannelConnectionContext } from '../../../../../../channel-connections/context/ChannelConnection'
import { Service } from '../../auth-flows/shared/SelectChannelList/utils'

import { trackConnectionAttempted } from '../../../../tracking'
import {
  getCurrentPlanNameFromAccount,
  isAtPlanLimit,
} from '../../../../../../shared-utils'

import ChannelLimitWarning from './components/ChannelLimitWarning'
import PaywallModal from './components/Paywall'
import RedirectModal from '../../auth-flows/shared/SigningIn'
import goToBufferChannel from '../../hooks/useGoToBufferChannel'
import {
  getCurrentPlanFromAccount,
  isFreePlan,
  isRevenueCatBillingGateway,
  shouldShowUpgradePaths,
} from '../../../Billing/utils/planHelpers'
import { MultiProductMigrationModal } from '../../components/MultiProductMigrationModal'

import { ChannelStorefront } from '../../../../../../channel-connections/components/channel-storefront'
import { VOTE_FOR_FEATURE_ON_FEATURE_OS } from '../../../../apollo/mutations/authorization'
import { useMutation } from '@apollo/client'
import { getStartConnectionServiceFromUrl } from '../../utils/queryParams'
import { ChannelTeaserModal } from './components/ChannelTeaserModal/ChannelTeaserModal'

type ChannelListProps = {
  atChannelLimit: boolean
  channelLimit: number
  setServiceNameCallback: (serviceName: string) => void
  showAuthFlowCallback: (showAuthFlow: boolean) => void
}

const ChannelList = ({
  atChannelLimit,
  channelLimit,
  setServiceNameCallback,
  showAuthFlowCallback,
}: ChannelListProps): JSX.Element => {
  const [showPaywall, setShowPaywall] = useState(false)
  const [legacyPlanUpgradeModalChannel, setLegacyPlanUpgradeModalChannel] =
    useState<string | null>(null)
  const [showRedirectModal, setShowRedirectModal] = useState(false)
  const [paywallMessageType, setPaywallMessageType] = useState('limits')
  const [redirectModalChannel, setRedirectModalChannel] = useState('')
  const [channelTeaserModal, setChannelTeaserModal] = useState({
    show: false,
    comingSoonMessage: '',
  })

  const account = useAccount()
  const currentPlan = getCurrentPlanFromAccount(account)
  const isOneBufferOrg = account?.currentOrganization?.isOneBufferOrganization
  const isRevenueCat = isRevenueCatBillingGateway(account?.currentOrganization)
  const shouldShowBillingActions = shouldShowUpgradePaths(
    account?.currentOrganization,
    currentPlan,
  )
  const entitlements = useEntitlements()
  const showStorefront =
    account.currentOrganization?.privileges?.canManageChannels

  const connectionContext = useContext(ChannelConnectionContext)
  const {
    startRedirect,
    setStartRedirect,
    setServiceToRedirect,
    serviceToRedirect,
  } = connectionContext

  const redirectToBufferChannel = goToBufferChannel()

  const organizationId = useSelector(
    (state) =>
      state.account.currentOrganization && state.account.currentOrganization.id,
  )

  const [voteForFeatureOnFeatureOs] = useMutation(
    VOTE_FOR_FEATURE_ON_FEATURE_OS,
    {
      onCompleted() {
        console.log('voteForFeatureOnFeatureOs')
      },
    },
  )

  const getServiceNameForRedirectModal = (): string => {
    if (redirectModalChannel === 'Google Business Profile') {
      return 'Google'
    }

    return redirectModalChannel
  }

  const allowChannelConnection = (): boolean => {
    if (!atChannelLimit) {
      return true
    }

    if (!isOneBufferOrg) {
      return false
    }

    if (isFreePlan(currentPlan)) {
      return false
    }

    return true
  }

  const shouldShowLegacyUpgradeModal = (channelName: string): boolean => {
    const oneBufferOnlyChannels = ['mastodon', 'youtube']
    if (oneBufferOnlyChannels.includes(channelName)) {
      return !entitlements.find((el) => el === channelName)
    }
    return false
  }

  const handleChannelSelection = (channel: {
    service: string
    comingSoon?: boolean
    comingSoonMessage?: string
    featureId?: number
  }): void => {
    if (channel.comingSoon) {
      setChannelTeaserModal({
        show: true,
        comingSoonMessage: channel.comingSoonMessage ?? '',
      })
      voteForFeatureOnFeatureOs({
        variables: {
          accountId: account.id,
          featureId: channel.featureId,
        },
      })
      return
    }

    const channelLowerCase =
      channel.service === 'Twitter / X'
        ? 'twitter'
        : channel.service.toLowerCase()
    if (!allowChannelConnection()) {
      setPaywallMessageType('limits')
      return setShowPaywall(true)
    } else if (shouldShowLegacyUpgradeModal(channelLowerCase)) {
      return setLegacyPlanUpgradeModalChannel(channelLowerCase)
    } else {
      const channelsForNewRedirect = ['youtube']

      if (channelsForNewRedirect.includes(channelLowerCase)) {
        // New redirect uses a channel connection context for managing
        // channel connection state. It feels like a better approach to
        // handling channel connections globally, but will require incremental
        // updating to get all channels transitioned over.

        trackConnectionAttempted({
          organizationId,
          serviceName: Service.youtube,
          cta: 'channel-connect-page',
          planNameAtTimeOfAttempt: getCurrentPlanNameFromAccount(account),
          isAtPlanLimit: isAtPlanLimit(account),
        })
        setServiceToRedirect(channel.service)
        setStartRedirect(true)
        return
      }

      const validChannels = [
        'twitter',
        'instagram',
        'facebook',
        'linkedin',
        'pinterest',
        'shopify',
        'mastodon',
        'threads',
        'bluesky',
      ]
      const validChannelsWithRedirect = [
        'tiktok',
        'google business profile',
        'googlebusiness',
      ]
      const validBufferChannels = ['start page', 'startpage']
      if (validChannelsWithRedirect.includes(channelLowerCase)) {
        setRedirectModalChannel(channel.service)
        setShowRedirectModal(true)
        // delay the auth callback to display modal indicating that the user is being redirected
        setTimeout(() => {
          setShowPaywall(false)
          setServiceNameCallback(channelLowerCase)
          showAuthFlowCallback(true)
        }, 2000)
      }

      if (validChannels.includes(channelLowerCase)) {
        setShowPaywall(false)
        setServiceNameCallback(channelLowerCase)
        showAuthFlowCallback(true)
      }

      if (validBufferChannels.includes(channelLowerCase)) {
        setShowPaywall(false)
        setServiceNameCallback(channelLowerCase)
        redirectToBufferChannel(channelLowerCase)
      }
    }
  }

  // Experiemnt: Auto-start the connection flow for a specific channel
  // when there is a startConnectionService query param in the URL
  useEffect(() => {
    const startConnectionService = getStartConnectionServiceFromUrl()
    if (startConnectionService) {
      console.log('startConnectionService', startConnectionService)
      handleChannelSelection({ service: startConnectionService })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      {!allowChannelConnection() ? (
        <ChannelLimitWarning
          shouldShowBillingActions={shouldShowBillingActions}
          channelLimit={channelLimit}
        />
      ) : null}

      {showStorefront && (
        <ChannelStorefront
          organizationId={organizationId}
          handleChannelSelection={handleChannelSelection}
        />
      )}

      {showPaywall ? (
        <PaywallModal
          type={paywallMessageType}
          isOneBufferOrg={isOneBufferOrg}
          isFreePlan={isFreePlan(currentPlan)}
          isRevenueCat={isRevenueCat}
          shouldShowBillingActions={shouldShowBillingActions}
          onClose={(): void => {
            setShowPaywall(false)
            setPaywallMessageType('')
          }}
        />
      ) : null}
      {showRedirectModal && (
        <RedirectModal
          serviceName={getServiceNameForRedirectModal()}
          onClose={(): void => {
            setShowRedirectModal(false)
          }}
        />
      )}

      {channelTeaserModal.show && (
        <ChannelTeaserModal
          onClose={(): void => {
            setChannelTeaserModal({ show: false, comingSoonMessage: '' })
          }}
          message={channelTeaserModal.comingSoonMessage}
        />
      )}
      {!!legacyPlanUpgradeModalChannel && (
        <MultiProductMigrationModal
          onClose={(): void => {
            setLegacyPlanUpgradeModalChannel(null)
          }}
          channelType={legacyPlanUpgradeModalChannel}
        />
      )}
    </React.Fragment>
  )
}

ChannelList.propTypes = {
  atChannelLimit: PropTypes.bool,
  channelLimit: PropTypes.number,
  setServiceNameCallback: PropTypes.func.isRequired,
  showAuthFlowCallback: PropTypes.func.isRequired,
}

ChannelList.defaultProps = {
  atChannelLimit: false,
}

export default ChannelList
