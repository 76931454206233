import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Text } from '@bufferapp/ui'
import Button from '@bufferapp/ui/Button'
import SimpleModal from '@bufferapp/ui/SimpleModal'
import PercentageUpIcon from '@bufferapp/ui/Icon/Icons/PercentageUp'
import PercentageDownIcon from '@bufferapp/ui/Icon/Icons/PercentageDown'

import { capitalizeFirstLetter } from '../../../../../../shared-utils'
import PlanCardDetails from './PlanCardDetails'

import { getPlanCycleAbbreviation } from '../../utils/plans'
import { formatCurrency } from '../../../../utils/numbers'

import {
  Card,
  CardSection,
  Title,
  PriceWrapper,
  Price,
  TitleWrapper,
  Tag,
  PricingNote,
  BilledAtList,
} from './style'
import { calculateDiscountedPlanPrice } from './../../utils/getCustomerBillingBreakdown'
import ConfirmAndPay from '../ConfirmAndPay/ConfirmAndPay'

// @ts-expect-error TS(7006) FIXME: Parameter 'discount' implicitly has an 'any' type.
function getDiscountNote(discount) {
  const discountedPriceValueText =
    discount?.type === 'percent' ? `${discount?.value}%` : `$${discount?.value}`
  const discountedPriceNoteText =
    discount?.duration === 'forever'
      ? 'forever.'
      : `for ${discount?.durationInMonths} months`

  return (
    <Text type="p">
      You have a discount of{' '}
      <strong>
        {discountedPriceValueText} off {discountedPriceNoteText}
      </strong>
    </Text>
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'planPricing' implicitly has an 'any' ty... Remove this comment to see the full error message
function getPriceNote(planPricing, legacyPlanPrice, discount, planInterval) {
  const discountedPrice = calculateDiscountedPlanPrice(planPricing, discount)
  const priceDifference =
    discountedPrice - calculateDiscountedPlanPrice(legacyPlanPrice, discount)

  const isSavings = priceDifference <= 0

  const legacyPriceNote = !isSavings
    ? `You'll pay $${formatCurrency(
        priceDifference,
      )}/${getPlanCycleAbbreviation(planInterval)} more`
    : `You'll save $${formatCurrency(
        Math.abs(priceDifference),
      )}/${getPlanCycleAbbreviation(planInterval)}`

  const priceIcon = !isSavings ? (
    <PercentageUpIcon size="medium" />
  ) : (
    <PercentageDownIcon size="medium" />
  )

  return (
    <PricingNote isSavings={isSavings}>
      {priceIcon}
      {legacyPriceNote}
    </PricingNote>
  )
}

const PlanCard = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'planName' implicitly has an 'any'... Remove this comment to see the full error message
  planName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'description' implicitly has an 'a... Remove this comment to see the full error message
  description,
  // @ts-expect-error TS(7031) FIXME: Binding element 'details' implicitly has an 'any' ... Remove this comment to see the full error message
  details,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planPricing' implicitly has an 'a... Remove this comment to see the full error message
  planPricing,
  // @ts-expect-error TS(7031) FIXME: Binding element 'discount' implicitly has an 'any'... Remove this comment to see the full error message
  discount,
  // @ts-expect-error TS(7031) FIXME: Binding element 'legacyPlanPrice' implicitly has a... Remove this comment to see the full error message
  legacyPlanPrice,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planPricingBreakdown' implicitly ... Remove this comment to see the full error message
  planPricingBreakdown,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planInterval' implicitly has an '... Remove this comment to see the full error message
  planInterval,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isHighlighted' implicitly has an ... Remove this comment to see the full error message
  isHighlighted,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDisabled' implicitly has an 'an... Remove this comment to see the full error message
  isDisabled,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedChannelQuantity' implicit... Remove this comment to see the full error message
  selectedChannelQuantity,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasCTA' implicitly has an 'any' t... Remove this comment to see the full error message
  hasCTA,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isNormal' implicitly has an 'any'... Remove this comment to see the full error message
  isNormal,
}) => {
  const [modalOpen, openModal] = useState(false)
  const [showingModal, setShowingModal] = useState(false)

  const shouldShowDiscountNote = !hasCTA && discount != null
  const shouldShowPriceNote = hasCTA && legacyPlanPrice != null
  const shouldShowPricingBreakout = planPricingBreakdown.length > 0

  // @ts-expect-error TS(7006) FIXME: Parameter 'feature' implicitly has an 'any' type.
  const keyFeature = details?.find((feature) => feature?.isKeyFeature === true)

  return (
    <Card
      isHighlighted={isHighlighted}
      isDisabled={isDisabled}
      isNormal={isNormal}
    >
      <CardSection>
        <TitleWrapper>
          <Title>{capitalizeFirstLetter(planName)}</Title>
          {isHighlighted && (
            <Tag>
              <span>Recommended</span>
            </Tag>
          )}
        </TitleWrapper>
        <Text type="p">{description}</Text>
      </CardSection>
      <CardSection>
        <PriceWrapper>
          {discount ? (
            <>
              <Price>
                ${calculateDiscountedPlanPrice(planPricing, discount)}
              </Price>
              <Price isRedacted>${formatCurrency(planPricing)}</Price>
            </>
          ) : (
            <Price>${formatCurrency(planPricing)}</Price>
          )}
          {shouldShowPriceNote &&
            getPriceNote(planPricing, legacyPlanPrice, discount, planInterval)}
          {shouldShowPricingBreakout && (
            <>
              <Text type="p">Billed as:</Text>
              <BilledAtList>
                {/* @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type. */}
                {planPricingBreakdown.map((item) => {
                  return (
                    <li key={item.planName + item.price}>
                      {' '}
                      <Text type="p">
                        {item.planName} plan: ${item.price} per {item.cycle}.
                      </Text>
                    </li>
                  )
                })}
              </BilledAtList>
            </>
          )}
          {shouldShowDiscountNote && getDiscountNote(discount)}
        </PriceWrapper>
      </CardSection>
      <CardSection>
        <PlanCardDetails details={details} />
      </CardSection>
      {hasCTA && (
        // @ts-expect-error TS(2740) FIXME: Type '{ fullWidth: true; disabled: any; type: stri... Remove this comment to see the full error message
        <Button
          fullWidth
          disabled={showingModal || isDisabled}
          type={isHighlighted ? 'primary' : 'secondary'}
          onClick={() => {
            openModal(true)
          }}
          label="Choose plan"
        />
      )}
      {modalOpen && (
        <SimpleModal
          closeAction={() => {
            openModal(false)
            setShowingModal(false)
          }}
        >
          <ConfirmAndPay
            planName={planName}
            planInterval={planInterval}
            selectedChannelQuantity={selectedChannelQuantity}
            isHighlighted={isHighlighted}
            isDisabled={isDisabled}
            legacyPlanPrice={calculateDiscountedPlanPrice(
              legacyPlanPrice,
              discount,
            )}
            keyFeature={keyFeature.text}
            openModal={openModal}
            setShowingModal={setShowingModal}
          ></ConfirmAndPay>
        </SimpleModal>
      )}
    </Card>
  )
}

PlanCard.propTypes = {
  planName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      isHighlighted: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  planPricing: PropTypes.number,
  legacyPlanPrice: PropTypes.number,
  planPricingBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      planName: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      cycle: PropTypes.string.isRequired,
    }),
  ),
  discount: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    duration: PropTypes.string.isRequired,
    durationInMonths: PropTypes.string,
  }),
  planInterval: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  selectedChannelQuantity: PropTypes.number.isRequired,
  hasCTA: PropTypes.bool,
  isNormal: PropTypes.bool,
}

PlanCard.defaultProps = {
  details: [],
  hasCTA: true,
  isNormal: false,
  planPricingBreakdown: [],
  planPricing: 0,
}

export default PlanCard
