import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Card, Form, FormBlock } from '../../../../account-components'
import { Button, Input, Tooltip } from '@bufferapp/ui'
import { validEmail } from '../../utils/validators'
import { ButtonWrapper, EmailInputWrapper, TooltipWrapper } from './style'
import { ACCOUNT_INITIATE_EMAIL_VERIFICATION } from '../../apollo/mutations/account'
import { CircleCheckmarkIconStyled } from '../../pages/Channels/components/StatusSection/styles'
import { green } from '@bufferapp/ui/style/colors'

const UpdateEmailForm = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSubmit' implicitly has an 'any'... Remove this comment to see the full error message
  onSubmit,
  // @ts-expect-error TS(7031) FIXME: Binding element 'email' implicitly has an 'any' ty... Remove this comment to see the full error message
  email,
  // @ts-expect-error TS(7031) FIXME: Binding element 'updatesDisabled' implicitly has a... Remove this comment to see the full error message
  updatesDisabled,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasVerifiedEmail' implicitly has ... Remove this comment to see the full error message
  hasVerifiedEmail,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setEmailVerificationNotificationT... Remove this comment to see the full error message
  setEmailVerificationNotificationText,
}) => {
  const [currEmail, setCurrEmail] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [error, setError] = useState('')
  const [shouldShowVerifyEmailButton, setShouldShowVerifyEmailButton] =
    useState(false)

  const [
    initiateEmailVerification,
    { data: emailVerificationSuccess, error: emailVerificationError },
  ] = useMutation(ACCOUNT_INITIATE_EMAIL_VERIFICATION)

  useEffect(() => {
    if (email && currEmail !== email) {
      setCurrEmail(email)
      setNewEmail(email)
    }
  }, [email])

  useEffect(() => {
    if (currEmail && !hasVerifiedEmail) {
      setShouldShowVerifyEmailButton(true)
    }

    if (hasVerifiedEmail === true) {
      setShouldShowVerifyEmailButton(false)
    }
  }, [currEmail, hasVerifiedEmail])

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onChange = (e) => {
    setNewEmail(e.target.value)
    setError('')
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onCancel = (e) => {
    e.preventDefault()
    setNewEmail(currEmail)
    setError('')
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onEmailSubmit = (e) => {
    e.preventDefault()
    const newEmail = e.target[0].value.trim()

    if (validEmail(newEmail)) {
      if (onSubmit) onSubmit(e)
      setNewEmail('')
    } else {
      setError('The email doesn’t look quite right... Mind trying again?')
    }
  }

  useEffect(() => {
    if (emailVerificationSuccess) {
      setEmailVerificationNotificationText(
        'We just sent you an email! Please check your inbox to complete verification steps.',
      )
    }

    if (emailVerificationError) {
      setEmailVerificationNotificationText(
        // @ts-expect-error TS(2339) FIXME: Property 'accountInitiateEmailVerificationError' d... Remove this comment to see the full error message
        emailVerificationError.accountInitiateEmailVerificationError
          ?.userFriendlyMessage,
      )
    }
  }, [emailVerificationSuccess, emailVerificationError])

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onVerifyEmail = (e) => {
    e.preventDefault()
    initiateEmailVerification()
  }

  const shouldShowVerifiedCheckmark = hasVerifiedEmail && newEmail === currEmail

  return (
    <>
      <Card>
        <Form onSubmit={onEmailSubmit}>
          <FormBlock inline align="left" condensed withBorder>
            <EmailInputWrapper>
              <Input
                // @ts-expect-error TS(2322) FIXME: Type '{ id: string; label: string; value: string; ... Remove this comment to see the full error message
                id="emailAddress"
                label="Email Address"
                value={newEmail}
                onChange={onChange}
                name="new-email"
                hasError={!!error}
                help={error}
                autoComplete="off"
                disabled={updatesDisabled}
              />
              {shouldShowVerifiedCheckmark && (
                <TooltipWrapper>
                  {/* @ts-expect-error TS(2322) */}
                  <Tooltip
                    position="right"
                    label={
                      <span>
                        <strong>Email verified</strong>
                      </span>
                    }
                  >
                    <CircleCheckmarkIconStyled color={green} />
                  </Tooltip>
                </TooltipWrapper>
              )}
            </EmailInputWrapper>
          </FormBlock>
          <FormBlock align="right" inline condensed>
            {shouldShowVerifyEmailButton && newEmail === currEmail && (
              <ButtonWrapper>
                {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: (e: ... Remove this comment to see the full error message */}
                <Button
                  type="primary"
                  label="Verify Email"
                  onClick={onVerifyEmail}
                />
              </ButtonWrapper>
            )}
            {newEmail !== currEmail && (
              // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: (e: ... Remove this comment to see the full error message
              <Button type="text" label="Cancel" onClick={onCancel} />
            )}
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
            <Button
              type="primary"
              label="Save Changes"
              onClick={() => {}}
              disabled={newEmail === currEmail}
            />
          </FormBlock>
        </Form>
      </Card>
    </>
  )
}

UpdateEmailForm.propTypes = {
  onSubmit: PropTypes.func,
  email: PropTypes.string,
  updatesDisabled: PropTypes.bool,
  hasVerifiedEmail: PropTypes.bool,
  setEmailVerificationNotificationText: PropTypes.func,
}

UpdateEmailForm.defaultProps = {
  onSubmit: null,
  email: null,
  updatesDisabled: false,
  hasVerifiedEmail: false,
  setEmailVerificationNotificationText: () => {},
}

export default UpdateEmailForm
