import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, FormBlock } from '../../../../account-components'

import { Button } from '@bufferapp/ui'
import PasswordField from '../PasswordField'
import PasswordFieldChecks from '../PasswordFieldChecks'
import {
  validPasswordLongitude,
  containsUppercase,
  containsNumberOrSymbol,
} from '../../utils/validators'

class UpdatePasswordForm extends Component {
  state = {
    password: '',
    longitudeChecked: false,
    uppercaseChecked: false,
    numOrSymbChecked: false,
    passwordChecked: false,
    redraw: false,
    error: '',
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onChange = (e) => {
    const password = e.target.value
    const longitudeChecked = validPasswordLongitude(password)
    const uppercaseChecked = containsUppercase(password)
    const numOrSymbChecked = containsNumberOrSymbol(password)
    this.setState({
      password,
      longitudeChecked,
      uppercaseChecked,
      numOrSymbChecked,
      passwordChecked: longitudeChecked && uppercaseChecked && numOrSymbChecked,
      error: '',
    })
  }

  onCancel = () => {
    this.clearAll()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onPasswordSubmit = (e) => {
    e.preventDefault()

    // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
    const { onSubmit } = this.props
    if (onSubmit) onSubmit(e)
    this.clearAll()
  }

  clearAll() {
    const { redraw } = this.state
    this.setState({
      password: '',
      longitudeChecked: false,
      uppercaseChecked: false,
      numOrSymbChecked: false,
      passwordChecked: false,
      redraw: !redraw,
      error: '',
    })
  }

  render() {
    const {
      password,
      redraw,
      longitudeChecked,
      uppercaseChecked,
      numOrSymbChecked,
      passwordChecked,
      error,
    } = this.state

    return (
      <Card>
        <Form onSubmit={this.onPasswordSubmit}>
          <FormBlock withBorder>
            <PasswordField
              onChange={this.onChange}
              showToggle={!!password}
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              key={redraw}
              hasError={!!error}
              help={
                error ||
                'If you’d like to change your password, enter a new password here'
              }
            />
            {password && (
              <PasswordFieldChecks
                longitudeChecked={longitudeChecked}
                uppercaseChecked={uppercaseChecked}
                numOrSymbChecked={numOrSymbChecked}
              />
            )}
          </FormBlock>
          <FormBlock align="right" inline condensed>
            {password && (
              // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message
              <Button type="text" label="Cancel" onClick={this.onCancel} />
            )}
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; disabled: boo... Remove this comment to see the full error message */}
            <Button
              type="primary"
              label="Change Password"
              disabled={!passwordChecked}
              onClick={() => {}}
            />
          </FormBlock>
        </Form>
      </Card>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
UpdatePasswordForm.propTypes = {
  onSubmit: PropTypes.func,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
UpdatePasswordForm.defaultProps = {
  onSubmit: null,
}

export default UpdatePasswordForm
