import styled from 'styled-components'

import {
  white,
  grayLighter,
  grayLight,
  yellowLightest,
  yellowDark,
  yellowDarker,
} from '@bufferapp/ui/style/colors'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 0;
  border-radius: 8px;
  background: ${white};
  border: 1px solid ${grayLight};
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
`

export const FieldSet = styled.fieldset`
  border: none;

  legend {
    padding: 16px;
  }

  h3 {
    margin-bottom: 0 !important;
  }
`

export const ListWrapper = styled.ul<{ isSearchable: boolean }>`
  padding: 16px;
  overflow-y: auto;
  max-height: 350px;
  background: ${grayLighter};
  ${(props): string => (props.isSearchable ? `height: 350px;` : '')}
`

export const NoticeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 16px;
  padding: 8px;

  border: 1px solid ${yellowDark};
  color: ${yellowDarker};
  background: ${yellowLightest};
  border-radius: 4px;
  font-size: 14px;

  span {
    padding-left: 8px;
  }
`

export const FilteringWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 10px 10px;
`

export const SearchWrapper = styled.div`
  flex: 3;

  label {
    display: none;
  }
`

export const SelectAllWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 85px;

  label {
    display: block;
    width: 80px;
  }

  div[class*='ButtonWrapperStyled'] {
    button {
      justify-content: start;
      padding: 0;

      div[class*='ButtonLabel'] {
        text-align: left;
      }
    }
  }
`
