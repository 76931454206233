import React from 'react'
import PropTypes from 'prop-types'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

const APIGatewayProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const client = new ApolloClient({
    uri: window.API_GATEWAY_URL,
    credentials: 'include',
    cache: new InMemoryCache({
      typePolicies: {
        OBBilling: {
          merge: true,
        },
      },
    }),
    headers: {
      'x-buffer-client-id': window.xBufferClientId,
    },
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

APIGatewayProvider.propTypes = {
  children: PropTypes.any,
}

export default APIGatewayProvider
