import styled from 'styled-components'

export const NoticeWrapper = styled.section`
  margin-bottom: 1rem;

  /* Adds styles to warning notice, but ideally this should
  be updated from within the UI library to match designs. */
  div[type='warning'] {
    padding: 8px;
    align-items: center;
  }
`

export const NoticeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
