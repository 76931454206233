import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import {
  TextWrapper,
  LoaderWrapper,
  ActionButtonWrapper,
  AlphaFeature,
  AlphaFeaturesWrapper,
  NoAlphaFeaturesText,
  FooterTextWrapper,
  AlphaInfoWrapper,
  AlphaFeatureTextWrapper,
  AlphaFeatureButtonWrapper,
  CardFooter,
} from './style'
import {
  BlockWrapper,
  Card,
  CardBody,
  PageLayout,
  TitleWrapper,
} from '../../../../account-components'
import { Loader, Text, Button } from '@bufferapp/ui'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'
import useAlphaFeatures from './hooks/useAlphaFeatures'

dayjs.extend(advancedFormat)

// @ts-expect-error TS(7031) FIXME: Binding element 'refetch' implicitly has an 'any' ... Remove this comment to see the full error message
const AlphaFeaturesPage = ({ refetch }) => {
  const account = useSelector((state) => state.account)

  // Get Alpha features query
  const { data, loading, error } = useAlphaFeatures()
  const alphaFeatures = data?.alphaFeatures || []

  const renderBody = () => {
    return (
      <React.Fragment>
        <TitleWrapper>
          <Text type="h1">Hey there, Bufferoo 👋</Text>
        </TitleWrapper>
        <BlockWrapper>
          <Card title="Alpha Testing">
            <React.Fragment>
              <CardBody>
                <TextWrapper>
                  <Text type="p">
                    A key step in our development process at Buffer is Alpha
                    testing. This is where we invite you, ask you, to help us
                    test out the features we&apos;re building and will soon roll
                    out to customers.
                  </Text>
                  <Text type="p">
                    Alpha testing capitalizes on the diversity of our team -
                    different use cases, geographical locations,
                    application/browser settings, different devices.
                  </Text>
                  <ActionButtonWrapper>
                    {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
                    <Button
                      type="secondary"
                      label="Learn about our Alpha testing approach"
                      onClick={() =>
                        window.open(
                          `https://paper.dropbox.com/doc/Early-Access-Program-WIP--B7_m9QDZN8IIA0WoGhQ0_QR7Ag-0NUupKwibORFLTKK1LzVN`,
                          '_blank',
                        )
                      }
                    />
                  </ActionButtonWrapper>
                </TextWrapper>
              </CardBody>
              <CardFooter>
                <FooterTextWrapper>
                  <Text type="p">You&apos;re awesome!</Text>
                </FooterTextWrapper>
              </CardFooter>
            </React.Fragment>
          </Card>
        </BlockWrapper>
        <BlockWrapper>
          <Card title="Current Alpha features">
            <CardBody>
              <AlphaInfoWrapper>
                <InfoIcon />
                <Text type="p">
                  These features are in alpha, please help test them out as you
                  use Buffer.
                </Text>
              </AlphaInfoWrapper>
            </CardBody>
            <CardFooter>
              <AlphaFeaturesWrapper>
                {alphaFeatures.length > 0 ? (
                  alphaFeatures.map((feature, index) => {
                    return (
                      <AlphaFeature key={index}>
                        <AlphaFeatureTextWrapper>
                          <Text type="p" className="featureTitle">
                            {feature.name}
                          </Text>
                          <Text type="p" className="featureDescription">
                            {feature.description}
                          </Text>
                        </AlphaFeatureTextWrapper>
                        <AlphaFeatureButtonWrapper>
                          {feature.feedbackUrl !== null && (
                            /* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */
                            <Button
                              type="secondary"
                              label="Share Feedback"
                              onClick={() =>
                                window.open(`${feature.feedbackUrl}`, '_blank')
                              }
                              size="small"
                            />
                          )}
                        </AlphaFeatureButtonWrapper>
                      </AlphaFeature>
                    )
                  })
                ) : (
                  <NoAlphaFeaturesText>
                    <Text type="p">
                      There are currently no alpha features - check back soon,
                      or ask us what&apos;s coming up!
                    </Text>
                  </NoAlphaFeaturesText>
                )}
              </AlphaFeaturesWrapper>
            </CardFooter>
          </Card>
        </BlockWrapper>
      </React.Fragment>
    )
  }

  return (
    <>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; withSidebar: true; with... Remove this comment to see the full error message */}
      <PageLayout withSidebar withAppShell withOrgSwitcher refetch={refetch}>
        {account.isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          renderBody()
        )}
      </PageLayout>
    </>
  )
}

AlphaFeaturesPage.propTypes = {
  refetch: PropTypes.func,
}

export default AlphaFeaturesPage
