/*
 * Gets the AWS S3 URL for a resource in the account bucket.
 *
 * @param {string} image
 * @returns {string} url
 */

export function getResourceUrl(resource: string): string {
  // Split the resource string by slashes to handle each segment individually
  const segments = resource.split('/')

  // Encode each segment individually, ignoring empty segments resulting from leading slashes
  const encodedSegments = segments
    .map((segment) => encodeURIComponent(segment))
    .filter((segment) => segment !== '')

  // Join the segments back with slashes and construct the URL
  const encodedResource = encodedSegments.join('/')

  // Construct the URL
  return `https://buffer-account.s3.amazonaws.com/${encodedResource}`
}
