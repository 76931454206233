import styled from 'styled-components'

import { blue, grayLight } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${grayLight};
  border-radius: 4px;
  margin-bottom: 8px;
  ${(props): string =>
    props.isOpen ? `box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);` : ''}
  a {
    color: ${blue};
    text-decoration: none;
  }
`
