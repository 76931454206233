import { useQuery } from '@apollo/client'
import { MIGRATION_SUMMARY } from '../../../../apollo/queries/billing'
import { useAccount } from '../../../../context/Account'

// @ts-expect-error TS(7031) FIXME: Binding element 'plan' implicitly has an 'any' typ... Remove this comment to see the full error message
const usePlanMigrationSummary = ({ plan, interval, quantity }) => {
  const account = useAccount()

  const { data, loading, error } = useQuery(MIGRATION_SUMMARY, {
    variables: {
      organizationId: account.currentOrganization.id,
      plan,
      interval,
      quantity,
    },
  })

  return {
    data,
    loading,
    error,
  }
}

export default usePlanMigrationSummary
