import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CrossSell from '@bufferapp/ui/CrossSell'
import { getMigrationHubUrl } from '../../../shared-utils'
import analyzeData from '../../data/analyze.json'

/**
 *
 */
class CrossSelling extends Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)

    this.state = {
      environment: null,
      texts: analyzeData,
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  static getDerivedStateFromProps(props, state) {
    const { environment, product } = props
    const { texts } = state
    if (environment !== state.environment) {
      return {
        environment,
        texts: {
          ...texts,
          button: {
            ...texts.button,
            url: getMigrationHubUrl('analyze-migration-cta'),
          },
        },
      }
    }

    return null
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'texts' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { texts } = this.state

    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    return <CrossSell texts={texts} />
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state) => ({
  environment: state.environment.environment,
})

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
CrossSelling.propTypes = {
  product: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(CrossSelling)
