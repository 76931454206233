import styled from 'styled-components'

import { blue, grayLight } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${grayLight};
  width: 100%;
  margin-bottom: 16px;
`

export const FilterOption = styled.button<{ currentSelection: boolean }>`
  display: flex;
  padding: 24px;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  font-family: ${fontFamily};
  font-size: 14px;
  ${({ currentSelection }): string =>
    currentSelection
      ? `
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
      background: ${blue};
    }
  `
      : ''}
`
