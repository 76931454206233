import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useLazyQuery } from '@apollo/client'
import { push } from 'connected-react-router'

import { actions } from '../../../../../../../channel-list/reducer'
import ChannelListModal from '../../../../../../../channel-list/components/ChannelList/ChannelListModal'

import {
  CHANNELS_CONNECT,
  CHANNELS_CONNECT_WITH_RESPONSE,
} from '../../../../../apollo/mutations/channels'
import {
  CAN_CONNECT_CHANNELS,
  GET_CHANNELS_FOR_ORGANIZATION,
} from '../../../../../apollo/queries/channels'

import { formatPagesWithConnectionStatus, mapRawChannel } from './utils'
import { handleChannelConnectionSuccessModalFlow } from '../../../utils/modals'

const SelectChannelList = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'channelToReconnect' implicitly ha... Remove this comment to see the full error message
  channelToReconnect,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
  onClose,
  // @ts-expect-error TS(7031) FIXME: Binding element 'pages' implicitly has an 'any' ty... Remove this comment to see the full error message
  pages,
  // @ts-expect-error TS(7031) FIXME: Binding element 'service' implicitly has an 'any' ... Remove this comment to see the full error message
  service,
  // @ts-expect-error TS(7031) FIXME: Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
  type,
}) => {
  const account = useSelector((state) => state.account)
  const dispatch = useDispatch()
  const [canConnectPages, { data }] = useLazyQuery(CAN_CONNECT_CHANNELS, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (pages && account.currentOrganization) {
      canConnectPages({
        variables: {
          // @ts-expect-error TS(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
          channels: pages.map((page) => ({
            serviceId: page.id,
            service: service.toLowerCase(),
            organizationId: account.currentOrganization.id,
          })),
        },
      })
    }
  }, [account, pages])

  function onReconnectError(missingForAccount = false) {
    dispatch(push(`/channels?reconnect=false&missing=${missingForAccount}`))
  }

  function onReconnectPermissionsError() {
    dispatch(push(`/channels?reconnect=false&permissions=true`))
  }

  const isReconnecting = !!channelToReconnect
  const [channelsConnect] = useMutation(CHANNELS_CONNECT, {
    onCompleted() {
      dispatch(actions.close())
      dispatch(push('/channels'))
      dispatch(actions.showChannelConnectSuccessNotification(isReconnecting))

      const timeToAutoHideNotification = 6000
      setTimeout(() => {
        dispatch(actions.hideChannelConnectSuccessNotification())
      }, timeToAutoHideNotification)
    },
    onError() {
      if (isReconnecting) {
        onReconnectError()
      } else {
        dispatch(actions.showChannelConnectErrorNotification())
      }
    },
    refetchQueries: [{ query: GET_CHANNELS_FOR_ORGANIZATION }],
    awaitRefetchQueries: true,
  })

  const [channelsConnectWithResponse] = useMutation(
    CHANNELS_CONNECT_WITH_RESPONSE,
    {
      onCompleted(data) {
        // success
        if (data.channelsConnectWithResponse.channels) {
          dispatch(actions.close())
          dispatch(push('/channels'))
          dispatch(
            actions.showChannelConnectSuccessNotification(isReconnecting),
          )

          const timeToAutoHideNotification = 6000
          setTimeout(() => {
            dispatch(actions.hideChannelConnectSuccessNotification())
          }, timeToAutoHideNotification)

          const channelIds = data.channelsConnectWithResponse.channels.map(
            // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
            (channel) => channel.id,
          )

          handleChannelConnectionSuccessModalFlow(channelIds)
        } else {
          // error
          if (isReconnecting) {
            onReconnectError()
          } else {
            dispatch(actions.showChannelConnectErrorNotification())
          }
        }
      },
      refetchQueries: [{ query: GET_CHANNELS_FOR_ORGANIZATION }],
      awaitRefetchQueries: true,
    },
  )

  useEffect(() => {
    // We need to make sure the currentOrganization is available to properly reconnect a channel
    if (account.currentOrganization && account.currentOrganization.id) {
      if (channelToReconnect) {
        let channel
        // TODO: Remove this once we have migrated all the personal profiles to the new
        // app. This is a temporary fix to avoid the error when reconnecting a personal profile
        if (pages.length >= 1 && service === 'linkedin' && type === 'profile') {
          channel = pages[0]

          channelsConnect({
            variables: {
              channels: [mapRawChannel({ channel, account, service })],
            },
          })
        } else {
          // @ts-expect-error TS(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
          channel = pages.find((page) =>
            // we are removing the Brand from the URI as LinkedIn is not returning that anymore
            // so legacy Showcase pages could not be reconnected
            channelToReconnect.serviceId
              .replace('organizationBrand', 'organization')
              .match(page.id),
          )

          if (channel) {
            // We are reusing the existing serviceId to avoid channels duplications
            channel.id = channelToReconnect.serviceId

            channelsConnect({
              variables: {
                channels: [mapRawChannel({ channel, account, service })],
              },
            })
          } else {
            service === 'instagram'
              ? // shows an error modal explaining permissions error and link to help center article
                // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
                onReconnectPermissionsError(true)
              : // shows a toast error message that user may be signed into the wrong account
                onReconnectError(true)
          }
        }
        dispatch(actions.close())
        onClose()
      }
    }
  }, [account])

  // We don't need to render the list if it's a reconnect
  if (channelToReconnect) {
    return <React.Fragment />
  }

  const defaultChannelLimit = 100

  function getChannelLimit() {
    if (
      account &&
      account.currentOrganization &&
      account.currentOrganization.channelLimit
    ) {
      return account.currentOrganization.channelLimit
    }

    return defaultChannelLimit
  }

  function getAllowedChannelsAmount() {
    if (account?.currentOrganization?.channels) {
      const connectedUnlockedChannels =
        account.currentOrganization.channels.filter(
          // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
          (channel) => !channel.isLocked,
        ).length
      return getChannelLimit() - connectedUnlockedChannels
    }

    return defaultChannelLimit
  }

  return (
    <ChannelListModal
      account={account}
      limit={getChannelLimit()}
      allowedAmount={getAllowedChannelsAmount()}
      service={service}
      type={type}
      close={() => {
        dispatch(actions.close())
        onClose()
      }}
      // @ts-expect-error TS(2349) FIXME: This expression is not callable.
      pages={formatPagesWithConnectionStatus({
        pages,
        data,
        currentOrganizationId: account.currentOrganization.id,
      })}
      addChannels={(channels: Array<any>) => {
        // channels is currently stored as a Set
        const selectedChannels = [...channels]
        channelsConnectWithResponse({
          variables: {
            channels: pages
              // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
              .map((channel) => {
                if (selectedChannels.includes(channel.id)) {
                  return mapRawChannel({ channel, account, service })
                }
                return undefined
              })
              // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
              .filter((channel) => !!channel),
          },
        })
      }}
    />
  )
}

SelectChannelList.propTypes = {
  channelToReconnect: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onReconnectError: PropTypes.func,
  pages: PropTypes.array.isRequired,
  service: PropTypes.string.isRequired,
  type: PropTypes.string,
}

SelectChannelList.defaultProps = {
  channelToReconnect: null,
  onClose: () => {
    // do nothing
  },
  onReconnectError: () => {
    // do nothing
  },
  pages: [],
  service: '',
  type: '',
}

export default SelectChannelList
