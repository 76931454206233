import styled from 'styled-components'
import { CardBase } from '../../../../../../../account-components/Card/style'

import { Text } from '@bufferapp/ui'
import { ButtonBase } from '@bufferapp/ui/Button/style'
import {
  gray,
  grayDark,
  grayDarker,
  blueLight,
  blue,
  teal,
  blueDark,
  green,
  white,
} from '@bufferapp/ui/style/colors'
import { fontWeightMedium, fontFamily } from '@bufferapp/ui/style/fonts'

export const Flex = styled.section`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
`

export const MainContent = styled.main`
  width: 100%;
  text-align: center;
`

export const ButtonStyled = styled.button`
  ${ButtonBase};
  ${CardBase};
  height: 190px;
  border: 1px solid ${gray};
  label {
    color: ${grayDark};
  }

  :hover {
    border: 1px solid ${blueDark};
    transition: 300ms;
    label {
      color: ${grayDarker};
    }
  }

  :focus {
    border-color: ${blueDark};
    box-shadow: ${blueLight};
    outline: none;
    label {
      color: ${grayDarker};
    }
  }

  :disabled {
    cursor: not-allowed;
    border: 1px solid ${gray};
    opacity: 40%;
    filter: grayscale(1);

    label {
      color: ${gray};
    }
  }
`

export const NewTag = styled.span`
  color: ${white};
  font-family: ${fontFamily};
  font-weight: ${fontWeightMedium};
  font-size: 12px;
  background: ${green};
  border-radius: 12px;
  padding: 4px 7px;
  position: absolute;
  top: 10px;
  right: 12px;
  border-left: 3px solid ${white};
  border-bottom: 3px solid ${white};
`

export const ComingSoonTag = styled(NewTag)`
  background: ${teal}!important;
`

export const TextStyled = styled(Text)`
  color: ${blue} !important;
  font-weight: 700 !important;
`
