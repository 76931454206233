import { useMutation } from '@apollo/client'
import { ADD_ORGANIZATION_TO_BETA_PROGRAM } from '../../../apollo/mutations/beta'

type BetaEnrollmentReturn = {
  addOrganizationToBetaProgram: () => void
}

export const useBetaEnrollment = ({
  organizationId,
  isBetaProgramEnabled,
  setIsBetaProgramEnabled,
  setIsLoading,
  setErrorText,
  refetch,
}: {
  organizationId: string
  isBetaProgramEnabled: boolean
  setIsBetaProgramEnabled: (isBetaProgramEnabled: boolean) => void
  setIsLoading: (isLoading: boolean) => void
  setErrorText: (errorText: any) => void
  refetch: () => void
}): BetaEnrollmentReturn => {
  const errorMessage =
    "There's been an error adding you to our Beta program. Please try again or contact us for help."
  const [addOrganizationToBetaProgram] = useMutation(
    ADD_ORGANIZATION_TO_BETA_PROGRAM,
    {
      variables: {
        organizationId,
      },
      onCompleted: (data) => {
        const isError = data?.addOrganizationToBetaProgram?.userFriendlyMessage

        setTimeout(() => {
          setIsLoading(false)
          if (isError) {
            setErrorText(errorMessage)
            return
          }
          setIsBetaProgramEnabled(!isBetaProgramEnabled)
        }, 1000)
        setTimeout(() => {
          const { actions } = window?.appshell || {}
          if (actions) {
            actions.refetchAccount()
          }
          refetch()
        }, 1500)
      },
      onError: () => {
        // This triggers on errors from the ApolloClient
        setErrorText(errorMessage)
        setIsLoading(false)
      },
    },
  )

  return {
    addOrganizationToBetaProgram,
  }
}
