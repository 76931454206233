import React from 'react'
import * as Styles from './style'

import { Button, Text } from '@bufferapp/ui'

const AccountDeleted = () => (
  <Styles.PageWrapper>
    <Styles.ContentWrapper>
      <Text type="h1">Buffer account deleted</Text>
      <Text type="p">
        {' '}
        We’ve deleted your Buffer account along with all your stored tweets,
        posts, analytics and social accounts. This includes publishing,
        engagement, and analytics.
      </Text>
      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; fullWidth: tr... Remove this comment to see the full error message */}
      <Button
        type="primary"
        label="Take Me Back to Buffer.com"
        fullWidth
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
        onClick={() => (window.location = 'https://www.buffer.com')}
      />
    </Styles.ContentWrapper>
  </Styles.PageWrapper>
)

export default AccountDeleted
