import { useMutation } from '@apollo/client'
import type {
  AuthorizeAndConnectSingleChannelArgs,
  UseAuthorizeAndConnectSingleChannelArgs,
  UseAuthorizeAndConnectSingleChannelReturn,
} from './types'
import { CHANNEL_CONNECTION_REFRESH } from '../../../apollo/mutations/channels'

import {
  checkOauthStateTokensMatch,
  getConnectedChannelsIds,
  getTokensForService,
  isNewConnection,
} from '../utils'
import { useAccount } from '../../../context/Account'
import { useState } from 'react'
import { getRedirectUri } from '../../../utils/channelConnections'
import { GET_ACCOUNT } from '../../../apollo/queries/account'
import { OAuthFlowErrorType } from '../../../pages/Channels/OAuthFlow/types'
import {
  getFollowBufferCookie,
  getMastodonServerCookie,
} from '../../../pages/Channels/utils/cookies'
import { Service } from '../../../../../channel-connections/interfaces'

export default function useAuthorizeAndConnectSingleChannel(
  args?: UseAuthorizeAndConnectSingleChannelArgs,
): UseAuthorizeAndConnectSingleChannelReturn {
  const [error, setError] = useState<string | undefined>(undefined)
  const [errorType, setErrorType] = useState<OAuthFlowErrorType | undefined>(
    undefined,
  )
  const account = useAccount()
  const [authorizeAndConnectSingleChannel, { loading, data }] = useMutation(
    CHANNEL_CONNECTION_REFRESH,
    {
      onCompleted: (data) => {
        if (args?.onSuccess && data?.channelConnectionRefresh?.channels) {
          const newConnection = isNewConnection(
            data?.channelConnectionRefresh?.channels,
          )
          const connectedChannelsIds = getConnectedChannelsIds(
            data?.channelConnectionRefresh?.channels,
          )

          args.onSuccess(connectedChannelsIds, newConnection)
        }

        // Handling errors
        if (
          data?.channelConnectionRefresh?.__typename ===
          'ChannelConnectionNoChannelsError'
        ) {
          setError(data?.channelConnectionRefresh?.message)
          setErrorType(OAuthFlowErrorType.noChannelsError)
        }

        if (
          data?.channelConnectionRefresh?.__typename ===
          'ChannelConnectionCannotStealChannelError'
        ) {
          setError(data?.channelConnectionRefresh?.message)
          setErrorType(OAuthFlowErrorType.stealingNotAllowed)
        }

        if (
          data?.channelConnectionRefresh?.__typename === 'UnauthorizedError'
        ) {
          setError(data?.channelConnectionRefresh?.message)
          setErrorType(OAuthFlowErrorType.notEnoughPermissions)
        }

        if (data?.channelConnectionRefresh?.__typename === 'UnexpectedError') {
          setError(data?.channelConnectionRefresh?.message)
          setErrorType(OAuthFlowErrorType.unexpectedError)
        }
      },
      refetchQueries: [{ query: GET_ACCOUNT }],
      context: {
        headers: {
          'x-buffer-domain': 'channel-authorization',
          'x-buffer-service': args?.service,
        },
      },
    },
  )

  function handleAuthorizeAndConnectSingleChannel(
    args: AuthorizeAndConnectSingleChannelArgs,
  ): void {
    const serviceOauthTokens = getTokensForService(args.service)
    const orgId = account?.currentOrganization?.id
    const redirectUri = getRedirectUri(args.service)
    const followBuffer = getFollowBufferCookie()
    const mastodonServer = getMastodonServerCookie()

    if (!serviceOauthTokens?.code) {
      setError('No code found in the callback URL')
      setErrorType(OAuthFlowErrorType.noCodeFoundInCallbackUrl)
    }

    // We wont call the mutation if our state tokens for oauth dont match
    // This is because something in the middle of the oauth process may have gone wrong
    const shouldCallAuthorizeAndConnectSingleChannel =
      checkOauthStateTokensMatch(args.service) &&
      orgId &&
      serviceOauthTokens?.code

    if (shouldCallAuthorizeAndConnectSingleChannel) {
      authorizeAndConnectSingleChannel({
        variables: {
          input: {
            code: serviceOauthTokens.code,
            service: args.service,
            organizationId: orgId,
            redirectUri,
            metadata: {
              mastodonMetadata:
                args.service === Service.mastodon
                  ? {
                      server: mastodonServer || null,
                      followBuffer: Boolean(followBuffer),
                    }
                  : undefined,
              twitterMetadata:
                args.service === Service.twitter
                  ? {
                      codeVerifier: serviceOauthTokens.codeVerifier,
                    }
                  : undefined,
            },
          },
        },
      })
    } else {
      setError('State tokens do not match')
      setErrorType(OAuthFlowErrorType.unexpectedError)
    }
  }

  const authorizedAndConnectedChannel = data?.channelConnectionRefresh

  return {
    connectedChannels: authorizedAndConnectedChannel?.channels,
    handleAuthorizeAndConnectSingleChannel: (
      args: AuthorizeAndConnectSingleChannelArgs,
    ) => handleAuthorizeAndConnectSingleChannel(args),
    isAuthorizeAndConnectSingleChannelLoading: loading,
    authorizeAndConnectSingleChannelError: error,
    authorizeAndConnectSingleChannelErrorType: errorType,
  }
}
