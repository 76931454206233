import { CardFooter } from '../../../../../../account-components'
import styled from 'styled-components'

import { white, grayDarker } from '@bufferapp/ui/style/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 24px 24px;
  gap: 8px;
  isolation: isolate;

  position: relative;

  background: #f5f5f5;
  border-radius: 8px;

  > h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: ${grayDarker};
  }

  > p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`

export const SummaryTitle = styled.div`
  margin-bottom: 0.1rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  color: ${grayDarker};
`

export const SummaryBox = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 16px;
  gap: 4px;

  background: ${white};

  border: 1px solid #e0e0e0;
  border-radius: 4px;

  h2 {
    margin-bottom: 1rem;
  }

  span {
    display: flex;
    gap: 0.5rem;
  }
`

export const Seperator = styled.div`
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  background: #e0e0e0;
`

export const PricingWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
`

export const SubText = styled.div`
  margin-bottom: 0.5rem;
`

export const PaymentMethodContainer = styled.div`
  width: 447px;
  ${CardFooter} {
    display: none;
  }
`

export const CreditNoteWrapper = styled.div`
  max-width: 400px;
`

export const MigrationFootNote = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
